import apiRoutes from "./restapi-route";
import axios from "axios";
import { Auth } from "aws-amplify";

// const baseURL = 'https://qnhmg8f3y7.execute-api.ap-southeast-1.amazonaws.com/dev/';
const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                Auth.signOut();
              } else {
                config.headers.Authorization =
                  "Bearer " + data.getIdToken().getJwtToken();
                resolve(config);
              }
            });
          });
        } else {
          config.headers.Authorization =
            "Bearer " + session.getIdToken().getJwtToken();
          resolve(config);
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

// const apiDomain = environment.restApiUrl;
// const apiDomain =
//   "https://qnhmg8f3y7.execute-api.ap-southeast-1.amazonaws.com/dev";

function requestApi(service, action, body, options) {
  return new Promise((resolve, reject) => {
    call(service, action, body, options).then(
      (data) => {
        resolve(data);
      }
    ).catch(function (error) {
      reject(error)
      if (error.response) {
        // reject(error.response)
      }
    });
    // resolve(call(service, action, body, options));
  });
}

function getApiPath(req) {
  const apiRoutesVal = apiRoutes;
  if (req.service && req.action && apiRoutesVal[req.service]) {
    // req.url =
    //   this.apiDomain +
    //   '/' +
    //   apiRoutesVal[req.service][req.action].path +
    //   '?' +
    //   'companyId' +
    //   '=' +
    //   this.getDefaultCompany(req);
    let finalUrl = baseURL + apiRoutesVal[req.service][req.action].path;
    req.url = finalUrl;
    req.method = apiRoutesVal[req.service][req.action].method;
  }
  if (
    req.body &&
    req.body.queryParams &&
    Object.getOwnPropertyNames(req.body.queryParams).length > 0
  ) {
    //   req.body.queryParams['companyId'] = this.getDefaultCompany(req);
    //   req.url = req.url.replace(
    //     '?companyId=' + this.getDefaultCompany(req),
    //     ''
    //   );
    let i = 0;
    Object.keys(req.body.queryParams).forEach((key) => {
      if (this.isArray(req.body.queryParams[key])) {
        if (i === 0) {
          req.url =
            req.url +
            "?" +
            key +
            "=" +
            JSON.stringify(req.body.queryParams[key]);
        } else {
          req.url =
            req.url +
            "&" +
            key +
            "=" +
            JSON.stringify(req.body.queryParams[key]);
        }
      } else {
        if (i === 0) {
          req.url = req.url + "?" + key + "=" + req.body.queryParams[key];
        } else {
          req.url = req.url + "&" + key + "=" + req.body.queryParams[key];
        }
      }
      i++;
    });
  }
  return req;
}

function setPathParams(req) {
  let str = req.url.split("?");
  str = str[0].split("/");
  for (const key in str) {
    if (str[key].match(/^:/)) {
      const matchingParameter = str[key].replace(":", "");
      req.url = req.url.replace(str[key], req.body.path[matchingParameter]);
    }
  }
  return req;
}

function call(service, action, body) {
  return new Promise((resolve, reject) => {
    const getApiPathSet = getApiPath({
      service,
      action,
      body,
    });
    const method = getApiPathSet.method;
    let url = getApiPathSet.url;

    const setPathParamsGet = setPathParams({
      body,
      url,
    });

    url = setPathParamsGet.url;
    // resolve(apiCall(url, method, body))
    apiCall(url, method, body).then(
      (data) => {
        resolve(data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

const apiCall = async (url, urlMethod, body) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      body,
    };
    // const instance = axios.create({
    //   baseURL: "https://qnhmg8f3y7.execute-api.ap-southeast-1.amazonaws.com/dev/",
    // });
    
    instance[urlMethod](url, body?.body, config)
      .then((response) => {
        resolve(response);
        // dispatch(loaderChange(false));
        // userList = response.data.data;
        // rows = userList;
        // setRows(userList);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response)
        }
      });
  })
  
};

export default requestApi;
