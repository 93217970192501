import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Slide, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "./InviteMemberModal.scss";
import Button from "@mui/material/Button";
import apiService from "../../../../Services/api-service";
import { loaderChange } from "../../../../Redux/actions";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

const InviteMemberModal = ({ editValue, onCloseModal }) => {
  const [account, setAccount] = React.useState({
    first_name: editValue?.first_name ? editValue.first_name : '',
    last_name: editValue?.last_name ? editValue.last_name : '',
    phone: editValue?.phone ? editValue.phone.toString() : '',
    email: editValue?.email ? editValue.email : '',
    role: editValue?.role ? editValue.role : '',
  });
  const [submitted, setSubmitted] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handelAccount = (property, event) => {
    //   this.setState({ value: event });
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const handelOnlyAlphabetsAccount = (property, event) => {
    const re = /^[A-Za-z]+$/;
    if (event === "" || re.test(event)){
      const accountCopy = { ...account };
      accountCopy[property] = event;
      setAccount(accountCopy);
    }
  };

  const validateEmail = (email) => {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      !account.first_name ||
      !account.last_name ||
      !account.phone ||
      !account.email || account.phone.length < 8
    ) {
      return;
    }
    if (!validateEmail(account.email)) {
      enqueueSnackbar("Invalid Email", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    const apiData = {
      first_name: account.first_name,
      last_name: account.last_name,
      phone: `+${account.phone}`,
      email: account.email,
      role: account.role,
    };
    submitInviteMember(apiData);
    // testApiFunction(apiData)
  };

  // const testApiFunction = (apiData) => {
  //   console.log('userDetails.px_unique_id', userDetails)
  //   const formValue = {body: apiData, path: {platform_id: userDetails.platform_id}};
  //   // formValue.platform_id = userDetails.platform_id;
  //   const apiResult = requestApi('inviteMember', 'create', formValue);
  //   apiResult.then(
  //     (resolveOutput) => {
  //       console.log('resolveOutput', resolveOutput);

  //     },
  //     (rejectOutput) => {
  //       console.log('rejectOutput', rejectOutput);
  //     }
  //   );
  // }

  const submitInviteMember = async (bodyParameters) => {
    dispatch(loaderChange(true));
    apiService
      .post(`members/invite`, bodyParameters)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        // navigate("invite-member");
        onCloseModal();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
        dispatch(loaderChange(false));
      });
  };

  const phoneFieldChange = (value, data, event, formattedValue) => {
    const accountCopy = { ...account };
    accountCopy.phone = value;
    accountCopy.calling_code = data.dialCode;
    setAccount(accountCopy);
  };

  return (
    <div>
      <h3 className="text-center modal-header-custom">Invite member</h3>
      <div className="mt-3">
        <TextField
          sx={{
            width: "100%",
          }}
          id="outlined-basic"
          label="First name"
          value={account.first_name}
          className="mt-1"
          variant="outlined"
          onChange={(event) => handelOnlyAlphabetsAccount("first_name", event.target.value)}
          helperText={
            !account.first_name && submitted ? "First name is required" : ""
          }
          error={!account.first_name && submitted}
        />

        <TextField
          sx={{
            width: "100%",
          }}
          id="outlined-basic"
          label="Last name"
          className="mt-3"
          variant="outlined"
          value={account.last_name}
          onChange={(event) => handelOnlyAlphabetsAccount("last_name", event.target.value)}
          helperText={
            !account.last_name && submitted ? "Last name is required" : ""
          }
          error={!account.last_name && submitted}
        />

        <TextField
          sx={{
            width: "100%",
          }}
          className="mt-3"
          id="outlined-basic"
          label="Email"
          value={account.email}
          variant="outlined"
          onChange={(event) => handelAccount("email", event.target.value)}
          helperText={!account.email && submitted ? "Email is required" : ""}
          error={!account.email && submitted}
        />

        <div className="mt-3 phone-field-div">
          <PhoneInput
            country={"us"}
            className=" ml-1"
            value={account.phone}
            isValid={!account.phone && submitted ? false : true}
            // onChange={(event) => handelAccount("phone", event)}
            onChange={phoneFieldChange}
            countryCodeEditable={false}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            // disabled={true}
          />
          {!account.phone && submitted ? (
            <span className="phone-error-msg">Phone number is required</span>
          ) : (
            ""
          )}
          {account.phone && account.phone.length < 8 && submitted ? (
            <span className="phone-error-msg">Phone number is not valid</span>
          ) : (
            ""
          )}
        </div>

        <FormControl className="mt-3">
          <FormLabel id="Role-row-radio-buttons-group-label">Role</FormLabel>
          <RadioGroup
            row
            aria-labelledby="Role-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={account.role}
            onChange={(event) => handelAccount("role", event.target.value)}
          >
            <FormControlLabel value="ORG_ADMIN" control={<Radio />} label="Admin" />
            <FormControlLabel
              value="ORG_USER"
              control={<Radio />}
              label="Platform user"
            />
          </RadioGroup>
        </FormControl>

        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: "100%" }}
          className='mt-3'
          onChange={(event) => handelAccount("role", event.target.value)}
          renderInput={(params) => (
            <TextField
              {...params}              
              label="role"
            />
          )}
        /> */}

        <div className="d-flex w-100 mt-5 mb-4">
          <Button
            variant="contained"
            color="error"
            sx={{ width: "100%" }}
            className="mr-2"
            onClick={onCloseModal}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ width: "100%" }}
            className="ml-2"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InviteMemberModal;
