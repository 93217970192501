import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  FormHelperText,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import apiService from "../../../../Services/api-service";
import { loaderChange } from "../../../../Redux/actions";
import FormControl from "@mui/material/FormControl";
import "./CatalogAddModal.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import { passwordMaxLenth } from "../../../../Utility/CommonJson";

const CatalogAddModal = ({ onCloseModal, checkApiData }) => {
  const [account, setAccount] = React.useState({
    api_url: checkApiData?.api_url ? checkApiData?.api_url : "",
    method: checkApiData?.method ? checkApiData?.method : "",
    auth_type: checkApiData?.auth_type ? checkApiData?.auth_type : "",
    username: checkApiData?.username ? checkApiData?.username : "",
    password: checkApiData?.password ? checkApiData?.password : "",
  });
  const [submitted, setSubmitted] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loaderValueChange = useSelector((state) => state.loaderOpenChange);
  const [returnResponse, setReturnResponse] = React.useState({});
  const [customMappingPage, setCustomMappingPage] = React.useState(false);
  const [customMappingValue, setCustomMappingValue] = React.useState({
    keyValue: [],
    dropdownValue: [],
  });

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!account.api_url || !account.method || !account.auth_type) {
      return;
    }
    if (account.auth_type !== "None") {
      if (!account.username || !account.password) {
        return;
      }
    }
    const apiData = {
      api_url: account.api_url,
      method: account.method,
      auth_type: account.auth_type,
      // username: account.username,
      // password: account.password,
      category: "catalog",
    };
    if (apiData?.auth_type !== 'None') {
      apiData.username = account?.username;
      apiData.password = account?.password;
      // apiData.id = checkApiData?._id;
    }
    sendRequest(apiData);

    // testApiFunction(apiData)
  };

  // const testApiFunction = (apiData) => {
  //   console.log('userDetails.px_unique_id', userDetails)
  //   const formValue = {body: apiData, path: {platform_id: userDetails.platform_id}};
  //   // formValue.platform_id = userDetails.platform_id;
  //   const apiResult = requestApi('inviteMember', 'create', formValue);
  //   apiResult.then(
  //     (resolveOutput) => {
  //       console.log('resolveOutput', resolveOutput);

  //     },
  //     (rejectOutput) => {
  //       console.log('rejectOutput', rejectOutput);
  //     }
  //   );
  // }

  const sendRequest = async (bodyParameters) => {
    dispatch(loaderChange(true));
    setReturnResponse({});
    apiService
      .post(`custom_api/test_api`, bodyParameters)
      .then((res) => {
        setCustomMappingPage(true);
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        setSubmitted(false);
        setReturnResponse(res?.data?.data);
        // navigate("invite-member");
        // onCloseModal();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handleClickShowPassword = () => {
    setAccount({
      ...account,
      showPassword: !account.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handelAccountCustomMappingKey = (index, event) => {
    const accountCopy = { ...customMappingValue };
    accountCopy.keyValue[index] = event;
    setCustomMappingValue(accountCopy);
  };

  const handelAccountCustomMappingDropDown = (index, event) => {
    const accountCopy = { ...customMappingValue };
    accountCopy.dropdownValue[index] = event;
    setCustomMappingValue(accountCopy);
  };

  const handleSubmitCustomMapping = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let finalValue = {};
    returnResponse?.our_params?.filter((val, key) => {
      finalValue[val] = customMappingValue.dropdownValue[key];
    });
    let checkValue = Object.values(finalValue)
    if(checkValue.includes(undefined)){
      return;
    }
    dispatch(loaderChange(true));
    apiService
      .put(`custom_api/catalog_mapping`, finalValue)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        setSubmitted(false);
        // navigate("invite-member");
        onCloseModal();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  return (
    <>
      {customMappingPage ? (
        <div>
          <h3 className="text-center modal-header-custom">Custom mapping</h3>
          <div className="mt-3 custom-mapping">
            {returnResponse?.our_params?.map((val, index) => {
              return (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{ alignItems: "center" }}
                    className="mt-2"
                    key={index}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ wordBreak: "break-word" }}
                        gutterBottom
                        component="div"
                      >
                        {val} is mapped to
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl fullWidth>
                        <InputLabel sx={{background: 'white', width: '6.5rem'}} id="method-select-label" error={!customMappingValue?.dropdownValue[index] && submitted ? true : false}>
                          Select param
                        </InputLabel>
                        <Select
                          labelId="method-select-label"
                          id="method-select"
                          value={customMappingValue?.dropdownValue[index]}
                          label="Method"
                          onChange={(event) =>
                            handelAccountCustomMappingDropDown(
                              index,
                              event.target.value
                            )
                          }
                          error={!customMappingValue?.dropdownValue[index] && submitted}
                        >
                          {returnResponse?.your_params?.map(
                            (val, index) => {
                              return (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                        {!customMappingValue?.dropdownValue[index] && submitted ? (
                        <FormHelperText error={true}>
                          {val} is required
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              );
            })}

            <div className="d-flex justify-content-end  w-100 mt-5 mb-4">
              <Button
                variant="contained"
                color="error"
                // sx={{ width: "100%" }}
                className="mr-2"
                onClick={onCloseModal}
              >
                Close
              </Button>

              <Button
                variant="contained"
                // sx={{ width: "100%" }}
                className="ml-2"
                onClick={handleSubmitCustomMapping}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-center modal-header-custom">
            Get from API endpoint{" "}
          </h3>
          <div className="mt-3">
            <FormControl fullWidth>
              <InputLabel id="method-select-label">Method</InputLabel>
              <Select
                labelId="method-select-label"
                id="method-select"
                value={account.method}
                label="Method"
                onChange={(event) =>
                  handelAccount("method", event.target.value)
                }
                error={!account.method && submitted}
              >
                <MenuItem value={"GET"}>GET</MenuItem>
                <MenuItem value={"POST"}>POST</MenuItem>
              </Select>
              {!account.method && submitted ? (
                <FormHelperText error={true}>Method is required</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>

            <TextField
              sx={{
                width: "100%",
              }}
              label="API Url"
              value={account.api_url}
              className="mt-3"
              variant="outlined"
              onChange={(event) => handelAccount("api_url", event.target.value)}
              helperText={
                !account.api_url && submitted ? "API Url is required" : ""
              }
              error={!account.api_url && submitted}
            />

            <FormControl className="w-100 mt-3">
              <InputLabel
                id="auth_type-select-label"
                sx={{ background: "white", width: "139px" }}
              >
                Authorization type
              </InputLabel>
              <Select
                labelId="auth_type-select-label"
                id="auth_type-select"
                value={account.auth_type ? account.auth_type : ""}
                label="Method"
                onChange={(event) =>
                  handelAccount("auth_type", event.target.value)
                }
                error={!account.auth_type && submitted}
              >
                <MenuItem value={"Basic"}>Basic Auth</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
              {!account.auth_type && submitted ? (
                <FormHelperText error={true}>
                  Authorization type is required
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>

            {account.auth_type !== 'None' && <>
              <TextField
              sx={{
                width: "100%",
              }}
              label="Username"
              className="mt-3"
              variant="outlined"
              value={account.username}
              onChange={(event) =>
                handelAccount("username", event.target.value)
              }
              inputProps={{ maxLength: 100 }}
              helperText={
                !account.username && submitted && account.auth_type !== "None"
                  ? "Username is required"
                  : ""
              }
              error={
                !account.username && submitted && account.auth_type !== "None"
              }
            />

            <FormControl
              sx={{ width: "100%" }}
              className="mt-3"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={account.showPassword ? "text" : "password"}
                value={account.password}
                onChange={(event) =>
                  handelAccount("password", event.target.value)
                }
                error={
                  !account.password && submitted && account.auth_type !== "None"
                }
                inputProps={{ maxLength: passwordMaxLenth }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {account.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {!account.password &&
              submitted &&
              account.auth_type !== "None" ? (
                <FormHelperText error={true}>
                  Password is required
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
            </>}

            

            {returnResponse?.message || loaderValueChange ? (
              <div className="text-center mt-3">
                {loaderValueChange ? <CircularProgress /> : ""}
                <span
                  className={
                    returnResponse?.success
                      ? "response-span "
                      : "response-span response-error"
                  }
                >
                  {returnResponse?.message}
                </span>
              </div>
            ) : (
              ""
            )}

            <div className="d-flex w-100 mt-5 mb-4">
              <Button
                variant="contained"
                color="error"
                sx={{ width: "100%" }}
                className="mr-2"
                onClick={onCloseModal}
              >
                Close
              </Button>

              <Button
                variant="contained"
                sx={{ width: "100%" }}
                className="ml-2"
                endIcon={<SendIcon />}
                onClick={handleSubmit}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CatalogAddModal;
