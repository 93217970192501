import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { FormHelperText, Slide, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import apiService from "../../../../Services/api-service";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import * as countryList from "country-list";
import PhoneInput from "react-phone-input-2";
import './InvitePlatform.scss';
import { loaderChange } from "../../../../Redux/actions";

const InvitePlatform = ({ onCloseModal }) => {
  const [registerData, setRegisterData] = useState({
    tax_id: "",
    corporate_name: "",
    country: "",
    first_name: "",
    phone: "",
    last_name: "",
    email: "",
    designation: "",
    website: "",
    platform_type: "",
    emailOtp: "",
    mobileOtp: "",
    platform_category: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountryCode, setSelectedCountryCode] = useState("in");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const handelAccount = (property, event) => {
    const accountCopy = { ...registerData };
    accountCopy[property] = event;
    setRegisterData(accountCopy);
  };

  const validateEmail = (email) => {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const isValidHttpUrl = (s) => {
    let url;
    try {
      url = new URL(s);
    } catch (e) { return false; }
    return /https?/.test(url.protocol);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      !registerData.tax_id ||
      !registerData.corporate_name ||
      !registerData.country ||
      !registerData.first_name ||
      !registerData.phone ||
      !registerData.email ||
      !registerData.designation ||
      !registerData.website
    ) {
      return;
    }
    if (!isValidHttpUrl(registerData.website)) {
      enqueueSnackbar("Invalid Company URL", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    if (!validateEmail(registerData.email)) {
      enqueueSnackbar("Invalid Email", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }    

    const apiPassData = {
      first_name: registerData.first_name,
      last_name: registerData.last_name,
      email: registerData.email,
      phone: "+" + registerData.phone,
      designation: registerData.designation,
      tax_id: registerData.tax_id,
      corporate_name: registerData.corporate_name,
      platform_type: "both",
      platform_category: "marketplace",
      country: registerData.country,
      website: registerData.website,
      // company_info: {
      //   tax_id: registerData.tax_id,
      //   corporate_name: registerData.corporate_name,
      //   platform_type: registerData.platform_type,
      //   address: {
      //     country: registerData.country,
      //   },
      // },
      // admin: {
      //   first_name: registerData.first_name,
      //   phone: registerData.phone,
      //   last_name: registerData.first_name,
      //   email: registerData.email,
      //   designation: registerData.designation,
      //   calling_code: registerData.calling_code,
      // },
      // website: registerData.website,
    };
    postData(apiPassData);
  };

  const postData = async (bodyParameters) => {
    dispatch(loaderChange(true));
    apiService
      .post(`platform/register`, bodyParameters)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        onCloseModal()
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const focusedColor = "#182269";
  const useStyles = makeStyles({
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: focusedColor,
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: focusedColor,
        },
      },
      label: {
        color: "red",
      },
    },
  });

  const classes = useStyles();

  const handelCountryAccount = (event) => {
    handelAccount("country", event.target.value);
    setSelectedCountryCode(
      countryList.getCode(event.target.value).toLowerCase()
    );
  };

  const phoneFieldChange = (value, data, event, formattedValue) => {
    const accountCopy = { ...registerData };
    accountCopy.phone = value;
    accountCopy.calling_code = data.dialCode;
    setRegisterData(accountCopy);
  };

  return (
    <div>
      <h3 className="text-center modal-header-custom">Invite platform</h3>
      <div className="mt-3">
        <div className="mt-3">
          <TextField
            sx={{
              width: "100%",
              //
              borderRadius: "5px",
            }}
            id="outlined-basic"
            label="Company/Corporate Name"
            variant="outlined"
            onChange={(event) =>
              handelAccount("corporate_name", event.target.value)
            }
            className={`${classes.root} mt-2`}
            helperText={
              !registerData.corporate_name && submitted
                ? "Company/Corporate Name is required"
                : ""
            }
            error={!registerData.corporate_name && submitted}
          />
        </div>
        <div className="mt-4 d-flex">
          <FormControl
            className="w-50 mr-1"
            error={!registerData.country && submitted}
          >
            <InputLabel
              id="demo-simple-select-label"
              // sx={{ color: "white" }}
            >
              Country of Registration
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={registerData.country}
              label="Country of Registration"
              sx={{
                borderRadius: "5px",
              }}
              className={classes.root}
              onChange={handelCountryAccount}
            >
              {countryList.getNames().map((val, index) => {
                return (
                  <MenuItem value={val} key={index}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
            {!registerData.country && submitted ? (
              <FormHelperText>
                Country of Registration is required
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>

          <TextField
            sx={{
              width: "50%",
              borderRadius: "5px",
            }}
            id="outlined-basic"
            label="Company Tax ID"
            variant="outlined"
            className={`${classes.root} ml-1`}
            helperText={
              !registerData.tax_id && submitted
                ? "Company Tax ID is required"
                : ""
            }
            error={!registerData.tax_id && submitted}
            onChange={(event) => handelAccount("tax_id", event.target.value)}
          />
        </div>
        <div className="mt-4 ">
          <TextField
            sx={{
              width: "100%",

              borderRadius: "5px",
            }}
            id="outlined-basic"
            label="Company URL"
            variant="outlined"
            className={`${classes.root}`}
            helperText={
              !registerData.website && submitted
                ? "Company URL is required"
                : ""
            }
            error={!registerData.website && submitted}
            onChange={(event) => handelAccount("website", event.target.value)}
          />
        </div>
        <div className="mt-4 d-flex">
          <TextField
            sx={{
              width: "50%",
              borderRadius: "5px",
            }}
            InputLabelProps={
              {
                // style: { color: "white" },
              }
            }
            InputProps={
              {
                // style: { color: "white" },
              }
            }
            id="outlined-basic"
            label="First name"
            variant="outlined"
            className={`${classes.root} mr-1`}
            helperText={
              !registerData.first_name && submitted
                ? "Contact Person is required"
                : ""
            }
            error={!registerData.first_name && submitted}
            onChange={(event) =>
              handelAccount("first_name", event.target.value)
            }
          />
          <TextField
            sx={{
              width: "50%",

              borderRadius: "5px",
            }}
            id="outlined-basic"
            label="Last name"
            variant="outlined"
            className={`${classes.root} ml-1`}
            helperText={
              !registerData.last_name && submitted
                ? "Last name is required"
                : ""
            }
            error={!registerData.last_name && submitted}
            onChange={(event) => handelAccount("last_name", event.target.value)}
          />
        </div>

        <div className="mt-4">
          <TextField
            sx={{
              width: "100%",

              borderRadius: "5px",
            }}
            id="outlined-basic"
            label="Designation"
            variant="outlined"
            className={`${classes.root}`}
            helperText={
              !registerData.designation && submitted
                ? "Designation is required"
                : ""
            }
            error={!registerData.designation && submitted}
            onChange={(event) =>
              handelAccount("designation", event.target.value)
            }
          />
        </div>
        <div className="mt-4 d-flex">
          <div className="w-100 mr-1 position-relative">
            <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              InputProps={{
                style: { paddingRight: "4.5rem" },
              }}
              id="outlined-basic"
              label="Contact Email"
              variant="outlined"
              className={`${classes.root}`}
              helperText={
                !registerData.email && submitted ? "Email is required" : ""
              }
              error={!registerData.email && submitted}
              onChange={(event) => handelAccount("email", event.target.value)}
            />
          </div>

          <div className="w-100 position-relative">
            <PhoneInput
              country={selectedCountryCode}
              className={`${classes.root} w-99 ml-1`}
              value={registerData.phone}
              onChange={phoneFieldChange}
              countryCodeEditable={false}
              inputProps={{
                name: "phone",
                required: true,
              }}
            />

            {!registerData.phone && submitted ? (
              <span className="phone-error-msg">Phone number is required</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end mt-5 mb-4">
          <Button
            variant="contained"
            color="error"
            // sx={{ width: "100%" }}
            className="mr-2"
            onClick={onCloseModal}
          >
            Close
          </Button>

          <Button
            variant="contained"
            // sx={{ width: "100%" }}
            className="ml-2"
            // endIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvitePlatform;
