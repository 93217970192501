import { Box } from "@mui/material";
import React, { useEffect } from "react";
import InviteTableCustomComponent from "./InviteTableCustom";
import "./InviteMember.scss";

const InviteMember = () => {
  const headCells = [
    {
      id: "first_name",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Name",
      sort: true,
    },
    {
      id: "email",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Email",
      sort: false,
    },
    {
      id: "phone",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Phone",
      sort: false,
    },
    {
      id: "action",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Action",
      sort: false,
    },
  ];

  useEffect(() => {
    document.title = "Invite Member";
  }, []);

  return (
    <div className="invite-list">
      <Box sx={{ width: "100%", height: "100%" }}>
        <InviteTableCustomComponent
          headCells={headCells}
          title={"Members"}
        />

        {/* <UserTableCustomComponent
                  headCells={headCells}
                  rows={rows}
                  title={"User List"}
                /> */}
        {/* <Modal
          open={openInviteMember}
          onClose={onCloseModal}
          closeIcon={<div></div>}
          closeOnOverlayClick={true}
        >
          <InviteMemberModal editValue={editValue} onCloseModal={onCloseModal} />
        </Modal> */}
      </Box>
    </div>
  );
};

export default InviteMember;
