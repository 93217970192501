import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

const UATRejectModal = ({ onCloseModal, handleSubmit, row }) => {
  const [account, setAccount] = React.useState({
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const handleRejectSubmit = () => {
    setSubmitted(true);
    if(!account.message) {
      return;
    }    
    handleSubmit(row, account.message);
  };
  return (
    <div>
      <h3 className="text-center modal-header-custom">Reason for reject</h3>
      <div className="mt-3">
        <TextField
          sx={{
            width: "100%",
          }}
          id="outlined-multiline-static"
          label="Reason"
          multiline
          rows={4}
          //   value={account.first_name}
          className="mt-1"
          variant="outlined"
          onChange={(event) => handelAccount("message", event.target.value)}
          helperText={!account.message && submitted ? "Reason is required" : ""}
          error={!account.message && submitted}
        />

        <div className="d-flex justify-content-end w-100 mt-5 mb-4">
          <Button
            variant="contained"
            color="error"
            // sx={{ width: "100%" }}
            className="mr-2"
            onClick={onCloseModal}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            // sx={{ width: "100%" }}
            className="ml-2"
            onClick={handleRejectSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UATRejectModal;
