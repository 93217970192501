import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Backdrop, Fade, Modal, Slide, TextField, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import apiService from "../../../Services/api-service";
import { useSnackbar } from "notistack";
import UATRejectModal from "./UATRejectModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // height: "calc(80vh - 80px)",
  maxHeight: '80%',
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  overflow: "auto",
  width: "40%",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property, sort) => (event) => {
    if(sort){
      onRequestSort(event, property);
    }    
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={"normal"}
            size={"medium"}
            sx={{ fontSize: "18px", fontWeight: 500 }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.sort ? orderBy === headCell.id : false}
              hideSortIcon={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.sort)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, rows, handelSearchEvent } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        // display: "flex",
        // justifyContent: "space-between",
      }}
      className="pl-0 mb-2"
    >
      {/* <TextField id="outlined-basic" label={title} variant="outlined" /> */}
      <Typography
        sx={{ fontWeight: 700, fontSize: 20 }}
        variant="h6"
        id="tableTitle"
        component="div"
        display="flex"
        justifyContent="flex-start"
      >
        {title}
      </Typography>
      {rows.length > 0 && (
          <TextField
          label="Search"
          id="outlined-size-small"
          size="small"
          className="ml-3"
          onChange={(event) => handelSearchEvent(event.target.value)}
        />
        )}   
      {/* <Tooltip title="Invite Member">
        <Button variant="contained" onClick={inviteModal}>
          Invite
        </Button>
      </Tooltip> */}
      {/* {rows.length > 0 ? (
        <div>
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload">
            <IconButton>
              <UploadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton onClick={() => exportToCSV(rows, title)}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        ""
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function UATTable({
  headCells = [],
  title,
}) {
  const [openRejectModal, setOpenRejectCatalogModal] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const dispatch = useDispatch();
  let [rows, setRows] = useState([]);
  const [noDataText, setNoDataText] = useState("Loading ....");
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalData, setTotalData] = useState(0);
  let queryParams = {
    rowsPerPage: 10,
    page: 0,
  };
  let searchValue = "";

  useEffect(() => {
    getUATlist()
    
  }, []);

  const getUATlist = async () => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&search=${searchValue}`
    if(queryParams.sortBy) {
      apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${searchValue}`
    }
    apiService
      .get(`uat_progress/pendings?${apiQueryParam}`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        // userList = response.data.data;
        // rows = userList;
        setRows(response?.data?.data?.data);
        setTotalData(response.data?.data?.total);
        dataCheck();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };   

  const dataCheck = () => {
    if (rows.length === 0) {
      setNoDataText("No results found");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    queryParams.sortOrder = isAsc ? "dsc" : "asc";
    queryParams.sortBy = property;
    getUATlist()
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };  

  const modalSubmit = (row, message) => {
    uatStatusChange(row, 'Rejected', message)
  };

  const uatStatusChange = async (row, status, message) => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const apiData = { id: row._id, status: status, message: message };
    apiService
      .post(
        `uat_progress/change_status`,
        apiData,
        config
      )
      .then((response) => {
        dispatch(loaderChange(false));
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        onCloseModal();
        // getUATlist();
        // userList = response.data.data;
        // rows = userList;
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
      });
  };

  const onCloseModal = () => {
    setOpenRejectCatalogModal(false);
    getUATlist();
  };

  const onOpenRejectModal = (row) => {
    setSelectedRow(row);
    setOpenRejectCatalogModal(true);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   queryParams.page = parseInt(newPage);
  //   getUATlist();
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  //   queryParams.rowsPerPage = parseInt(event.target.value, 10);
  //   getUATlist();
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    queryParams.rowsPerPage = parseInt(event.target.value)
    // queryParams.page = parseInt(page);
    setPage(0);
    getUATlist();
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(parseInt(rowsPerPage));
    queryParams.page = parseInt(newPage);
    queryParams.rowsPerPage = parseInt(rowsPerPage)
    getUATlist();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actionIcons = (row) => {
    return (
      <div className="action-icon uat-list">
        {row.status === "Pending" ? (
          <span>
            <Tooltip title="Reject">
              <i
                className="fa fa-times-circle cursor-pointer"
                aria-hidden="true"
                onClick={() => onOpenRejectModal(row)}
              ></i>
            </Tooltip>
            <Tooltip title="Approve">
              <i
                className="fa fa-check-circle ml-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => uatStatusChange(row, "Approved")}
              ></i>
            </Tooltip>
          </span>
        ) : (
          row.status
        )}
      </div>
    );
  };

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const handelSearchEvent = (event) => {
    searchValue = event;
    getUATlist();
  };

  return (
    <>
    <Paper
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 0,
        boxShadow: "none",
      }}
    >
      <EnhancedTableToolbar numSelected={selected.length} title={title} rows={rows} handelSearchEvent={handelSearchEvent} />

      {rows.length > 0 ? (
        <TableContainer sx={{ boxShadow: 2, borderRadius: 2 }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell align="left" padding={"normal"}>
                        {row.platformDetail?.company_info?.corporate_name}
                      </TableCell>
                      <TableCell align="left" padding={"normal"}>
                        {row.category}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {/* <Link target="_blank" to={row.api_url}>{row.api_url}</Link> */}
                        <a
                          rel="noopener noreferrer"
                          href={row.api_url}
                          target="_blank"
                        >
                          {truncateString(row.api_url, 35)}
                        </a>
                        <a
                          rel="noopener noreferrer"
                          href={row.file_url}
                          target="_blank"
                        >
                          {truncateString(row.file_url, 35)}
                        </a>
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {row.status}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        <div className="action-icon">
                          {userDetails._id !== row._id ? actionIcons(row) : ""}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="center-no-data">{noDataText}</div>
      )}

      {rows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
    </Paper>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openRejectModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRejectModal}>
          <Box sx={style} id="style-1">
            <UATRejectModal
              onCloseModal={onCloseModal}
              handleSubmit={modalSubmit}
              row={selectedRow}
            />
          </Box>
        </Fade>
      </Modal>
    </>
    
  );
}
