/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, InputBase, MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import "./Landing.scss";
import ApprovalIcon from "@mui/icons-material/Approval";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleIcon from "@mui/icons-material/Article";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ProxteraLogo from "../Assets/svg/proxtera-logo.svg";

const Landing = () => {
  const drawerWidth = 240;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const menuId = "primary-search-account-menu";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openTopMenu, setOpenTopMenu] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));

  const menuRef = useRef();

  const handleClickOutside = (e) => {
    if (!menuRef.current.contains(e.target)) {
      setOpenTopMenu(false);
    }
  };

  const handleClickInside = () => setOpenTopMenu(true);

  useEffect(() => {
    // toggleCaptchaBadge(false);
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  // const toggleCaptchaBadge = (show) => {
  //   const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  //   if (badge && badge instanceof HTMLElement) {
  //     badge.style.visibility = show ? 'visible' : 'hidden';
  //   }
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleLogOut = async () => {
    // const logOut = await logoutFunction();
    // if (logOut) {
    //   localStorage.clear();
    //   navigate("/login");
    // }
    localStorage.clear();
    navigate("/sign-in");
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigatePage("profile-edit")}>Profile</MenuItem>
      <MenuItem onClick={() => navigatePage("edit-company")}>Company</MenuItem>
      <MenuItem onClick={() => navigatePage("change-password")}>
        Change password
      </MenuItem>
      {/* <MenuItem onClick={() => navigatePage("my-plan")}>My plan</MenuItem> */}
      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setOpenTopMenu(true);
    setAnchorEl(event.currentTarget);
  };

  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <MenuItem>
  //       <IconButton size="large" aria-label="show 4 new mails" color="inherit">
  //         <Badge badgeContent={4} color="error">
  //           <MailIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Messages</p>
  //     </MenuItem>
  //     <MenuItem>
  //       <IconButton
  //         size="large"
  //         aria-label="show 17 new notifications"
  //         color="inherit"
  //       >
  //         <Badge badgeContent={17} color="error">
  //           <NotificationsIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Notifications</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleProfileMenuOpen}>
  //       <IconButton
  //         size="large"
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //       >
  //         <AccountCircle />
  //       </IconButton>
  //       <p>Profile</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleLogOut}>
  //       <IconButton
  //         size="large"
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //       >
  //         <LogoutIcon />
  //       </IconButton>
  //       <p>Logout</p>
  //     </MenuItem>
  //   </Menu>
  // );

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigatePage = (routeValue) => {
    setOpenTopMenu(false);
    navigate(routeValue);
  };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const getSubPath = () => {
    const arr = window.location.pathname.split("/");
    return arr[1];
  };

  getSubPath();

  // window.onload = function(){
  //   // var divToHide = document.getElementById('divToHide');
  //   // document.onclick = function(e){
  //   //   if(e.target.id !== 'divToHide'){
  //   //     //element clicked wasn't the div; hide the div
  //   //     divToHide.style.display = 'none';
  //   //   }
  //   // };
  //   var divToHide = document.getElementById('menu-open-close-custom');
  //   document.onclick = function(e){
  //   if(e.target.id !== 'menu-open-close-custom'){
  //     //element clicked wasn't the div; hide the div
  //     setOpenTopMenu(false);
  //     // divToHide.style.display = 'none';
  //   }
  // };
  // };

  const drawerMenu = (
    <>
      {userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 && (
        <List>
          <ListItem
            className={getSubPath() === "home" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("home")}
          >
            <ListItemIcon>
              <HomeIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem
            className={getSubPath() === "platform" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("platform")}
          >
            <ListItemIcon>
              <PeopleIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Platform List" />
          </ListItem>

          {/* <ListItem
              className={
                getSubPath() === "api-integration" ? "active-menu" : ""
              }
              button
              onClick={() => navigatePage("api-integration")}
            >
              <ListItemIcon>
                <IntegrationInstructionsIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Api Integration" />
            </ListItem> */}

          <ListItem
            className={getSubPath() === "invite-member" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("invite-member")}
          >
            <ListItemIcon>
              <FolderSharedIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Invite Member" />
          </ListItem>

          {/* <ListItem
              className={getSubPath() === "uat-progress" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-progress")}
            >
              <ListItemIcon>
                <AutoGraphIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Progress" />
            </ListItem> */}

          <ListItem
            className={getSubPath() === "uat-approval" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("uat-approval")}
          >
            <ListItemIcon>
              <ApprovalIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="UAT Approval" />
          </ListItem>

          <ListItem
            className={getSubPath() === "catalog" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("catalog")}
          >
            <ListItemIcon>
              <AssessmentIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Catalog" />
          </ListItem>

          <ListItem
            className={getSubPath() === "sme-list" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("sme-list")}
          >
            <ListItemIcon>
              <ArticleIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="SME" />
          </ListItem>
        </List>
      )}
      {userDetails?.roles?.indexOf('ORG_ADMIN') !== -1
       && (
        <List>
          <ListItem
            className={getSubPath() === "home" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("home")}
          >
            <ListItemIcon>
              <HomeIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {/* <ListItem
                className={getSubPath() === "platform" ? "active-menu" : ""}
                button
                onClick={() => navigatePage("platform")}
              >
                <ListItemIcon>
                  <PeopleIcon className="active-icon" />
                </ListItemIcon>
                <ListItemText primary="Platform List" />
              </ListItem> */}

          <ListItem
            className={getSubPath() === "api-integration" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("api-integration")}
          >
            <ListItemIcon>
              <IntegrationInstructionsIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="API Integration" />
          </ListItem>

          <ListItem
            className={getSubPath() === "invite-member" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("invite-member")}
          >
            <ListItemIcon>
              <FolderSharedIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Invite Member" />
          </ListItem>

          <ListItem
            className={getSubPath() === "uat-progress" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("uat-progress")}
          >
            <ListItemIcon>
              <AutoGraphIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="UAT Progress" />
          </ListItem>

          {/* <ListItem
              className={getSubPath() === "uat-approval" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-approval")}
            >
              <ListItemIcon>
                <ApprovalIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Approval" />
            </ListItem> */}

          <ListItem
            className={getSubPath() === "catalog" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("catalog")}
          >
            <ListItemIcon>
              <AssessmentIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Catalog" />
          </ListItem>

          <ListItem
            className={getSubPath() === "sme-list" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("sme-list")}
          >
            <ListItemIcon>
              <ArticleIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="SME" />
          </ListItem>
        </List>
      )}
      {userDetails?.roles?.indexOf('ORG_USER') !== -1 && (
        <List>
          <ListItem
            className={getSubPath() === "home" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("home")}
          >
            <ListItemIcon>
              <HomeIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {/* <ListItem
                className={getSubPath() === "platform" ? "active-menu" : ""}
                button
                onClick={() => navigatePage("platform")}
              >
                <ListItemIcon>
                  <PeopleIcon className="active-icon" />
                </ListItemIcon>
                <ListItemText primary="Platform List" />
              </ListItem> */}

          <ListItem
            className={getSubPath() === "api-integration" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("api-integration")}
          >
            <ListItemIcon>
              <IntegrationInstructionsIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="API Integration" />
          </ListItem>

          {/* <ListItem
              className={getSubPath() === "invite-member" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("invite-member")}
            >
              <ListItemIcon>
                <FolderSharedIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Invite Member" />
            </ListItem> */}

          {/* <ListItem
              className={getSubPath() === "uat-progress" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-progress")}
            >
              <ListItemIcon>
                <AutoGraphIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Progress" />
            </ListItem> */}

          {/* <ListItem
              className={getSubPath() === "uat-approval" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-approval")}
            >
              <ListItemIcon>
                <ApprovalIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Approval" />
            </ListItem> */}

          <ListItem
            className={getSubPath() === "catalog" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("catalog")}
          >
            <ListItemIcon>
              <AssessmentIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="Catalog" />
          </ListItem>

          <ListItem
            className={getSubPath() === "sme-list" ? "active-menu" : ""}
            button
            onClick={() => navigatePage("sme-list")}
          >
            <ListItemIcon>
              <ArticleIcon className="active-icon" />
            </ListItemIcon>
            <ListItemText primary="SME" />
          </ListItem>
        </List>
      )}
    </>
  );

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    // <div>
    //   <CssBaseline />
    //   <NavAppBar />
    //   <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    //   <Outlet />
    //   </Box>
    // </div>

    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* <AppBar2 /> */}
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
            <Box sx={{ flexGrow: 1 }} />
            <span>
              {userDetails?.platformDetail?.company_info?.corporate_name
                ? truncateString(
                    userDetails?.platformDetail?.company_info?.corporate_name,
                    30
                  )
                : ""}
            </span>

            {/* {userDetails?.platformDetail?.company_info?.corporate_name} */}
            <Box sx={{ display: { xs: "none", md: "flex" } }} ref={menuRef}>
              <IconButton
                size="medium"
                edge="end"
                aria-label="account of current user"
                // aria-controls={menuId}
                // aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                // onClick={handleClick}
                color="inherit"
                id="basic-button"
                aria-controls={isMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
                // onClick={handleProfileMenuOpen}
                // ref={menuRef}
                onClick={handleClickInside}
              >
                {/* <AccountCircle /> */}
                <Avatar
                  sizes="20"
                  sx={{ width: "2rem", height: "2rem", fontSize: "15px" }}
                >
                  {getInitials(
                    `${userDetails?.first_name} ${userDetails?.last_name}`
                  )}
                </Avatar>
              </IconButton>
              {openTopMenu ? (
                // <div className="custom-menu-div" id="menu-open-close-custom">
                //   <MenuItem onClick={() => navigatePage("profile-edit")}>
                //     Profile
                //   </MenuItem>
                //   <MenuItem onClick={() => navigatePage("edit-company")}>
                //     Company
                //   </MenuItem>
                //   <MenuItem onClick={() => navigatePage("change-password")}>
                //     Change password
                //   </MenuItem>
                //   <MenuItem onClick={() => navigatePage("my-plan")}>
                //     My plan
                //   </MenuItem>
                //   <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                // </div>
                <>
                  {userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 && (
                    <div
                      className="custom-menu-div"
                      id="menu-open-close-custom"
                    >
                      <MenuItem onClick={() => navigatePage("profile-edit")}>
                        Profile
                      </MenuItem>
                      {/* <MenuItem onClick={() => navigatePage("edit-company")}>
                                  Company
                                </MenuItem> */}
                      <MenuItem onClick={() => navigatePage("change-password")}>
                        Change password
                      </MenuItem>
                      {/* <MenuItem onClick={() => navigatePage("my-plan")}>
                                  My plan
                                </MenuItem> */}
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </div>
                  )}

                  {userDetails?.roles?.indexOf('ORG_ADMIN') !== -1 && (
                    <div
                      className="custom-menu-div"
                      id="menu-open-close-custom"
                    >
                      <MenuItem onClick={() => navigatePage("profile-edit")}>
                        Profile
                      </MenuItem>
                      <MenuItem onClick={() => navigatePage("edit-company")}>
                        Company
                      </MenuItem>
                      <MenuItem onClick={() => navigatePage("change-password")}>
                        Change password
                      </MenuItem>
                      {/* <MenuItem onClick={() => navigatePage("my-plan")}>My plan</MenuItem> */}
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </div>
                  )}

                  {userDetails?.roles?.indexOf('ORG_USER') !== -1  && (
                    <div
                      className="custom-menu-div"
                      id="menu-open-close-custom"
                    >
                      <MenuItem onClick={() => navigatePage("profile-edit")}>
                        Profile
                      </MenuItem>
                      <MenuItem onClick={() => navigatePage("edit-company")}>
                        Company
                      </MenuItem>
                      <MenuItem onClick={() => navigatePage("change-password")}>
                        Change password
                      </MenuItem>
                      {/* <MenuItem onClick={() => navigatePage("my-plan")}>My plan</MenuItem> */}
                      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              {/* {renderMenu} */}
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader className="d-flex justify-content-between">
            <img
              src={ProxteraLogo}
              alt="logo"
              className="cursor-pointer"
              onClick={() => navigatePage("home")}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {drawerMenu}
          {/* <List>
            <ListItem
              className={getSubPath() === "home" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("home")}
            >
              <ListItemIcon>
                <HomeIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>

            <ListItem
                className={getSubPath() === "platform" ? "active-menu" : ""}
                button
                onClick={() => navigatePage("platform")}
              >
                <ListItemIcon>
                  <PeopleIcon className="active-icon" />
                </ListItemIcon>
                <ListItemText primary="Platform List" />
              </ListItem>

            <ListItem
              className={
                getSubPath() === "api-integration" ? "active-menu" : ""
              }
              button
              onClick={() => navigatePage("api-integration")}
            >
              <ListItemIcon>
                <IntegrationInstructionsIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Api Integration" />
            </ListItem>

            <ListItem
              className={getSubPath() === "invite-member" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("invite-member")}
            >
              <ListItemIcon>
                <FolderSharedIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Invite Member" />
            </ListItem>

            <ListItem
              className={getSubPath() === "uat-progress" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-progress")}
            >
              <ListItemIcon>
                <AutoGraphIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Progress" />
            </ListItem>

            <ListItem
              className={getSubPath() === "uat-approval" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("uat-approval")}
            >
              <ListItemIcon>
                <ApprovalIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="UAT Approval" />
            </ListItem>

            <ListItem
              className={getSubPath() === "catalog" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("catalog")}
            >
              <ListItemIcon>
                <AssessmentIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="Catalog" />
            </ListItem>

            <ListItem
              className={getSubPath() === "sme-list" ? "active-menu" : ""}
              button
              onClick={() => navigatePage("sme-list")}
            >
              <ListItemIcon>
                <ArticleIcon className="active-icon" />
              </ListItemIcon>
              <ListItemText primary="SME" />
            </ListItem>
          </List> */}
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>

      {/* {renderMenu} */}
    </div>
  );
};

export default Landing;
