import { Box } from "@mui/material";
import React, { useEffect } from "react";
// import { FormHelperText, Slide, TextField } from "@mui/material";
import "./Catalog.scss";
import CatalogueList from "./CatalogList";

const Catalog = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const headCells = [
    {
      id: "category_type",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Category type",
      isVisible: true,
      sort: true,
    },
    {
      id: "corporate_name",
      align: "left",
      numeric: false,
      isVisible: userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 ? true : false,
      disablePadding: false,
      label: "Corporate name",
      sort: true,
    },
    {
      id: "description",
      align: "center",
      isVisible: true,
      numeric: true,
      disablePadding: false,
      label: "Description",
      sort: false,
    },
    {
      id: "name",
      align: "center",
      numeric: true,
      isVisible: true,
      disablePadding: false,
      label: "Name",
      sort: true,
    },
    {
      id: "tax_id",
      align: "center",
      numeric: true,
      isVisible: true,
      disablePadding: false,
      label: "Tax ID",
      sort: true,
    },
  ];
  // const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Catalog";
    // getCatalogList();
    // fetchApiData()
  }, []);


  return (
    <div className="invite-list">
      <Box sx={{ width: "100%", height: "100%", }}>
        <CatalogueList
          headCells={headCells}
          title={"Catalog"}
          // noDataText={noDataText}
        />
        {/* <Modal
          open={openCatalogModal}
          onClose={onCloseModal}
          closeIcon={<div></div>}
          closeOnOverlayClick={true}
        >
          <CatalogAddModal onCloseModal={onCloseModal} />
        </Modal> */}

        {/* <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openCatalogModal}
          onClose={onCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openCatalogModal}>
            <Box sx={style} id="style-1">
              <CatalogAddModal onCloseModal={onCloseModal} checkApiData={checkApiData} />
            </Box>
          </Fade>
        </Modal> */}
      </Box>
    </div>
  );
};

export default Catalog;
