import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Backdrop,
  Fade,
  Modal,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import apiService from "../../../Services/api-service";
import { useSnackbar } from "notistack";
import CatalogAddModal from "./CatalogAddModal/CatalogAddModal";
import Papa from "papaparse";
import requestApi from "../../../Services/restService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // height: "calc(100vh - 80px)",
  maxHeight: "80%",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  overflow: "auto",
  width: '50%'
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property, sort) => (event) => {
    if(sort){
      onRequestSort(event, property);
    }    
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell.isVisible && (
              <>
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  padding={"normal"}
                  size={"medium"}
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={headCell.sort ? orderBy === headCell.id : false}
                    hideSortIcon={true}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id, headCell.sort)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    title,
    onOpenModal,
    checkApiData,
    fetchApiData,
    handleCSVChange,
    rows,
    exportToCSV,
    handelSearchEvent,
    userDetails,
  } = props;
  const inputFile = useRef(null);

  const openFileUploadCsv = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
      className="pl-0 mb-2"
    >
      <div className="d-flex align-items-end">
        <Typography
          sx={{ fontWeight: 700, fontSize: 20 }}
          variant="h6"
          id="tableTitle"
          component="div"
          display="flex"
          justifyContent="flex-start"
        >
          {title}
        </Typography>
        {rows.length > 0 && (
          <TextField
            label="Search"
            id="outlined-size-small"
            size="small"
            className="ml-3"
            onChange={(event) => handelSearchEvent(event.target.value)}
          />
        )}
      </div>
      <div className="">
        {userDetails?.roles?.indexOf('ORG_ADMIN') !== -1 && (
          <>
            {checkApiData ? (
              <>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleCSVChange}
                />
                {checkApiData.api_url ? (
                  <>
                    <Tooltip title="Upload CSV">
                      <Button
                        // sx={{ width: "200px" }}
                        variant="contained"
                        onClick={openFileUploadCsv}
                      >
                        Upload CSV
                      </Button>
                    </Tooltip>

                    <Tooltip title="Update API endpoint">
                      <Button
                        // sx={{ width: "350px" }}
                        className="ml-3"
                        variant="contained"
                        onClick={onOpenModal}
                      >
                        Update API endpoint
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Update CSV">
                      <Button
                        // sx={{ width: "200px" }}
                        variant="contained"
                        onClick={openFileUploadCsv}
                      >
                        Update CSV
                      </Button>
                    </Tooltip>

                    <Tooltip title="Get from API endpoint">
                      <Button
                        // sx={{ width: "350px" }}
                        className="ml-3"
                        variant="contained"
                        onClick={onOpenModal}
                      >
                        Get from API endpoint
                      </Button>
                    </Tooltip>
                  </>
                )}

                <Tooltip title="Fetch data">
                  <Button
                    // sx={{ width: "200px" }}
                    variant="contained"
                    className="ml-3"
                    onClick={fetchApiData}
                  >
                    Fetch data
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleCSVChange}
                />
                <Tooltip title="Upload CSV">
                  <Button
                    // sx={{ width: "200px" }}
                    variant="contained"
                    onClick={openFileUploadCsv}
                  >
                    Upload CSV
                  </Button>
                </Tooltip>

                <Tooltip title="Get from API endpoint">
                  <Button
                    // sx={{ width: "350px" }}
                    className="ml-3"
                    variant="contained"
                    onClick={onOpenModal}
                  >
                    Get from API endpoint
                  </Button>
                </Tooltip>
              </>
            )}
            <Tooltip title="Get CSV sample">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.google.com/spreadsheets/d/1obOJpp8qRA3v6_qroXDmE7ed3pNt7tV14KDejl85BUQ/edit?usp=sharings"
              >
                <Button
                  // onClick={openFileUploadCsv}
                  // sx={{ width: "300px" }}
                  className="ml-3"
                  variant="contained"
                >
                  Get CSV sample
                </Button>
              </a>
            </Tooltip>
          </>
        )}
        {rows.length > 0 && (
          <>
            <Tooltip title="Download CSV sample">
              <IconButton onClick={() => exportToCSV(rows, title)}>
                <DownloadIcon className="ml-2" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
      {/* <TextField id="outlined-basic" label={title} variant="outlined" /> */}

      {/* {checkApiData ? (
        <>
          <Tooltip title="Fetch data">
            <Button sx={{ width: "200px" }} variant="contained" onClick={fetchApiData}>
              Fetch data
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
        <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleCSVChange}
      />
          <Tooltip title="Upload csv">
            <Button sx={{ width: "200px" }} variant="contained" onClick={openFileUploadCsv}>
              Upload csv
            </Button>
          </Tooltip>

          <Tooltip title="Get from API endpoint">
            <Button
              sx={{ width: "350px" }}
              className="ml-3"
              variant="contained"
              onClick={onOpenModal}
            >
              Get from API endpoint
            </Button>
          </Tooltip>
        </>
      )} */}

      {/* {rows.length > 0 ? (
          <div>
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload">
              <IconButton>
                <UploadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton onClick={() => exportToCSV(rows, title)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          ""
        )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CatalogueList = ({ headCells = [], title }) => {
  const [rows, setRows] = useState([]);
  const [openCatalogModal, setOpenCatalogModal] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const fileExtension = ".xlsx";
  const [noDataText, setNoDataText] = useState("Loading ....");
  const [totalData, setTotalData] = useState(0);
  const [checkApiData, setCheckApiData] = useState(null);
  const [showMore, setShowMore] = useState([]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const dispatch = useDispatch();
  let queryParams = {
    rowsPerPage: 10,
    page: 0,
  };
  let searchValue = "";
  const [updateApiEndPoint, setUpdateApiEndPoint] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));

  const exportToCSV = (csvData, fileName) => {
    const dataCsv = [["Category type", "Description", "Name", "Tax ID"]];
    csvData.filter((val, index) => {
      dataCsv.push([val.category_type, val.description, val.name, val.tax_id]);
    });
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    getCatalogList();
    // onOpenCustomMappingModal();
  }, []);

  const fetchApiData = async (data) => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const apiData = {
      // id: data?._id ? data._id : checkApiData._id,
      category: 'catalog',
      remove_existing: true,
    };
    apiService
      .post(`custom_api/fetch_data`, apiData, config)
      .then((response) => {
        dispatch(loaderChange(false));
        // setRows(response?.data?.data);
        getCatalogList();
        // dataCheck();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const fileTypeCheck = (image) => {
    let image_type_allowed = ["csv"];
    var new_upload_ext = image.name.split(".").pop();
    return image_type_allowed.includes(new_upload_ext);
  };

  const handleCSVChange = (event) => {
    if (!fileTypeCheck(event.target.files[0])) {
      // alert('Please upload valid csv file only.')
      enqueueSnackbar("Please upload valid CSV file only.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    const csvFile = event.target.files[0];
    // Papa.parse(csvFile, {
    //   worker: true,
    //   step: function(results) {
    //   }
    // });
    const defaultHeader = [
      "Description",
      "Item Name",
      "Unit Measure",
      "Unit Price",
      "Unit Price Currency",
      "Country of Origin",
      "Seller Name",
      "Quantity",
      "Incoterms (FOB/CIF/EXW/DDP)",
      "Incoterms (Place)",
      "Payment Terms",
      "Seller ID (SME)",
      "Tax ID",
      "Address (Optional)",
      "Packaging Size (Optional)",
      "Negotiable (Optional True/False)",
    ];

    Papa.parse(csvFile, {
      complete: function (results) {
        if (!arraysEqual(defaultHeader, results.data[0])) {
          enqueueSnackbar("Invalid CSV header", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
          return;
        }
        uploadCatalogApi(csvFile);
      },
    });

    event.target.value = null;
  };

  const arraysEqual = (a1, a2) => {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  };

  const uploadCatalogApi = (csvFile) => {
    const data = new FormData();
    data.append("file", csvFile);
    data.append("category", "catalog");
    data.append("remove_existing", true);
    if (checkApiData?._id) {
      // data.append("id", checkApiData?._id);
    }
    const formValue = {
      body: data,
      path: { platform_id: userDetails.platform_id },
    };
    dispatch(loaderChange(true));
    const apiResult = requestApi("customApi", "customApiUpload", formValue);
    apiResult.then(
      (resolveOutput) => {
        dispatch(loaderChange(false));
        // setRows(response.data.data ? response.data.data : []);
        getCatalogList();
        enqueueSnackbar(resolveOutput.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
      },
      (rejectOutput) => {
        dispatch(loaderChange(false));
        if (rejectOutput) {
          enqueueSnackbar(rejectOutput?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        // enqueueSnackbar("Something went wrong", {
        //   variant: "error",
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   TransitionComponent: Slide,
        //   autoHideDuration: 2000,
        // });
      }
    );
  };

  const onCloseModal = () => {
    setOpenCatalogModal(false);
    setUpdateApiEndPoint(false);
    getCatalogList();
  };

  const onOpenModal = () => {
    setOpenCatalogModal(true);
  };
  

  const handelSearchEvent = (event) => {
    searchValue = event;
    getCatalogList();
  };

  const dataCheck = () => {
    if (rows.length === 0) {
      setNoDataText("No results found");
    }
  };

  const getCatalogList = async () => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&search=${searchValue}`
    if(queryParams.sortBy) {
      apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${searchValue}`
    }

    apiService
      .get(
        `custom_api/catalogues?${apiQueryParam}`,
        config
      )
      .then((response) => {
        dispatch(loaderChange(false));
        setRows(response.data?.data?.data);
        // console.log('response.data?.data', response.data?.data?.data);
        setTotalData(response.data?.data?.total);
        let addExpandData = [];
        response.data?.data?.data.filter((val, index) => {
          addExpandData.push(false);
        });
        setShowMore(addExpandData);
        getCustomCategoryList();

        // // setRowsPerPage(response.data?.data?.size)
        // dataCheck();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const getCustomCategoryList = async () => {
    // dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    apiService
      .get(`custom_api?category=catalog`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        setCheckApiData(response?.data?.data);
        if (response?.data?.data) {
          // fetchApiData(response?.data?.data);
          return;
        }
        dataCheck();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    queryParams.sortOrder = isAsc ? "dsc" : "asc";
    queryParams.sortBy = property;
    getCatalogList();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   queryParams.page = newPage;
  //   getCatalogList()
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   queryParams.rowsPerPage = parseInt(event.target.value, 10);
  //   setPage(0);
  //   getCatalogList()
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    queryParams.rowsPerPage = parseInt(event.target.value);
    // queryParams.page = parseInt(page);
    setPage(0);
    getCatalogList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(parseInt(rowsPerPage));
    queryParams.page = parseInt(newPage);
    queryParams.rowsPerPage = parseInt(rowsPerPage);
    getCatalogList();
  };

  const handleTermsExpand = (index) => {
    const accountCopy = { ...showMore };
    accountCopy[index] = !accountCopy[index];
    setShowMore(accountCopy);
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          // rows={rows}
          onOpenModal={onOpenModal}
          checkApiData={checkApiData}
          fetchApiData={fetchApiData}
          handleCSVChange={handleCSVChange}
          rows={rows}
          exportToCSV={exportToCSV}
          handelSearchEvent={handelSearchEvent}
          userDetails={userDetails}
        />

        {rows.length > 0 ? (
          <TableContainer sx={{ boxShadow: 2, borderRadius: 2 }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell align="left" padding={"normal"}>
                        {row.category_type}
                      </TableCell>
                      {userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 && (
                        <TableCell align="left" padding={"normal"}>
                          {row.platformDetail?.company_info.corporate_name}
                        </TableCell>
                      )}

                      <TableCell align="center" padding={"normal"}>
                        {showMore[index]
                          ? row?.description
                          : `${row?.description.substring(0, 100)}`}
                        {/* <div className="btn" onClick={() => handleTermsExpand(index)}>
                        {showMore[index] ? 'Show less' : 'Show more'}
                        </div> */}
                        <Typography
                          variant="caption"
                          sx={{
                            color: "blue",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          gutterBottom
                          onClick={() => handleTermsExpand(index)}
                        >
                          {showMore[index] ? "...Show less" : "...Show more"}
                        </Typography>
                        {/* {row.description} */}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {row.tax_id}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="center-no-data">{noDataText}</div>
        )}

        {rows.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCatalogModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCatalogModal}>
          <Box sx={style} id="style-1">
            <CatalogAddModal
              onCloseModal={onCloseModal}
              checkApiData={checkApiData}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CatalogueList;
