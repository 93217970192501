import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/store/store";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material";
import Amplify, {Auth} from 'aws-amplify';

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_OAUTH_DOMAIN
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};
Amplify.configure(awsmobile);
Auth.configure(awsmobile)
// console.log('process.env', process.env);
// if(process.env.REACT_APP_ENV === 'dev'){
//   const awsmobile = {
//     "aws_project_region": "ap-southeast-1",
//     "aws_cognito_region": "ap-southeast-1",
//     "aws_user_pools_id": "ap-southeast-1_AYZb5Uz5i",
//     "aws_user_pools_web_client_id": "53k3iq8vcpemcb8bs3cdj5v150",
//     "oauth": {
//         "domain": "proxteradev.auth.ap-southeast-1.amazoncognito.com"
//     },
//     "aws_cognito_username_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_social_providers": [],
//     "aws_cognito_signup_attributes": [
//         "EMAIL",
//         "PHONE_NUMBER"
//     ],
//     "aws_cognito_mfa_configuration": "OPTIONAL",
//     "aws_cognito_mfa_types": [
//         "SMS",
//         "TOTP"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": [
//             "REQUIRES_LOWERCASE",
//             "REQUIRES_UPPERCASE",
//             "REQUIRES_NUMBERS",
//             "REQUIRES_SYMBOLS"
//         ]
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ]
// };
// Amplify.configure(awsmobile);
// Auth.configure(awsmobile)
// } else if (process.env.REACT_APP_ENV === 'uat') {
//   const awsmobile = {
//     "aws_project_region": "ap-southeast-1",
//     "aws_cognito_region": "ap-southeast-1",
//     "aws_user_pools_id": "ap-southeast-1_8Yf598QW1",
//     "aws_user_pools_web_client_id": "614srjbormpjva7e1ufkk3qou8",
//     "oauth": {
//         "domain": "uatauth.auth.ap-southeast-1.amazoncognito.com"
//     },
//     "aws_cognito_username_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_social_providers": [],
//     "aws_cognito_signup_attributes": [
//         "EMAIL",
//         "PHONE_NUMBER"
//     ],
//     "aws_cognito_mfa_configuration": "OPTIONAL",
//     "aws_cognito_mfa_types": [
//         "SMS",
//         "TOTP"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": [
//             "REQUIRES_LOWERCASE",
//             "REQUIRES_UPPERCASE",
//             "REQUIRES_NUMBERS",
//             "REQUIRES_SYMBOLS"
//         ]
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ]
// };
// Amplify.configure(awsmobile);
// Auth.configure(awsmobile)
// } else if (process.env.REACT_APP_ENV === 'staging') {
//   const awsmobile = {
//     "aws_project_region": "ap-southeast-1",
//     "aws_cognito_region": "ap-southeast-1",
//     "aws_user_pools_id": "ap-southeast-1_3ued63TcQ",
//     "aws_user_pools_web_client_id": "34dj0v6ppfit1gr827811ualsl",
//     "oauth": {
//         "domain": "stagingauth.auth.ap-southeast-1.amazoncognito.com"
//     },
//     "aws_cognito_username_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_social_providers": [],
//     "aws_cognito_signup_attributes": [
//         "EMAIL",
//         "PHONE_NUMBER"
//     ],
//     "aws_cognito_mfa_configuration": "OPTIONAL",
//     "aws_cognito_mfa_types": [
//         "SMS",
//         "TOTP"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": [
//             "REQUIRES_LOWERCASE",
//             "REQUIRES_UPPERCASE",
//             "REQUIRES_NUMBERS",
//             "REQUIRES_SYMBOLS"
//         ]
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ]
// };
// Amplify.configure(awsmobile);
// Auth.configure(awsmobile)
// } else if (process.env.REACT_APP_ENV === 'prod') {
//     const awsmobile = {
//       "aws_project_region": "ap-southeast-1",
//       "aws_cognito_region": "ap-southeast-1",
//       "aws_user_pools_id": "ap-southeast-1_bIC9MSX57",
//       "aws_user_pools_web_client_id": "6s7f2gu6ueqqtn7jlamvrer4c7",
//       "oauth": {
//           "domain": "proxtera.auth.ap-southeast-1.amazoncognito.com"
//       },
//       "aws_cognito_username_attributes": [
//           "EMAIL"
//       ],
//       "aws_cognito_social_providers": [],
//       "aws_cognito_signup_attributes": [
//           "EMAIL",
//           "PHONE_NUMBER"
//       ],
//       "aws_cognito_mfa_configuration": "OPTIONAL",
//       "aws_cognito_mfa_types": [
//           "SMS",
//           "TOTP"
//       ],
//       "aws_cognito_password_protection_settings": {
//           "passwordPolicyMinLength": 8,
//           "passwordPolicyCharacters": [
//               "REQUIRES_LOWERCASE",
//               "REQUIRES_UPPERCASE",
//               "REQUIRES_NUMBERS",
//               "REQUIRES_SYMBOLS"
//           ]
//       },
//       "aws_cognito_verification_mechanisms": [
//           "EMAIL"
//       ]
//   };
//   Amplify.configure(awsmobile);
//   Auth.configure(awsmobile)
//   }



const theme = createTheme({      
  typography: {
    button: {
      textTransform: 'none'
    }
  },
//   breakpoints: {
//     // Define custom breakpoint values.
//     // These will apply to Material-UI components that use responsive
//     // breakpoints, such as `Grid` and `Hidden`. You can also use the
//     // theme breakpoint functions `up`, `down`, and `between` to create
//     // media queries for these breakpoints
//     values: {
//       xs: 0,
//       sm: 450,
//       md: 600,
//       lg: 900,
//       xl: 1200
//     }
//   }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
    </ThemeProvider>
      
    </Provider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
