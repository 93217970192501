import {
  Box,
  Button,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import apiService from "../../../../Services/api-service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../../Redux/actions";
import { useSnackbar } from "notistack";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import moment from "moment";

const APIKeys = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let apiKeyList = [];
  let [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getApiKeyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApiKeyList = () => {
    dispatch(loaderChange(true));
    apiService
      .get(`platform/get_apikeys`, {})
      .then((res) => {
        apiKeyList = res.data.data;
        rows = apiKeyList;
        setRows(apiKeyList);
        dispatch(loaderChange(false));
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const disableKey = (_id) => {
    dispatch(loaderChange(true));
    apiService
      .post(`platform/disableapikey/${_id}`, {})
      .then((res) => {
        getApiKeyList();
        dispatch(loaderChange(false));
        enqueueSnackbar("Key disabled", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 1000,
        });
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const createApiKey = async () => {
    navigate("/generate-api-key");
  };

  const copyText = (containerId) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerId));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
    enqueueSnackbar("Copied !!", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Slide,
      autoHideDuration: 1000,
    });
  };

  return (
    <div>
      <div className="custom-tab-sub-header mt-1">REGISTERED PLATFORM ID</div>
      <div className="d-flex justify-content-between">
        <div className="url-div mt-1">
          <div className="url-text" id="platform_id">
            {userDetails.platform_id}
          </div>{" "}
          <div>
            <Button variant="contained" onClick={() => copyText("platform_id")}>
              Copy
            </Button>
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            className="gcs-btn"
            sx={{ background: "#00796B", color: "#FFFFFF" }}
            onClick={createApiKey}
          >
            Generate Client ID & Secret
          </Button>
        </div>
      </div>
      <div className="mt-3 api-key-table">
        {rows.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>CREATED DATE</TableCell>
                  <TableCell align="center">CLIENT ID</TableCell>
                  <TableCell align="center">Actions</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, index) => (
                  <TableRow
                    key={row.updated}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {/* {row.updated} */}
                      {/* {moment(new Date(row.updated)).format("DD/MM/YYYY")} */}
                      {moment(new Date(row.updated)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell align="center" id={"client_id" + index}>
                      {row.client_id}
                    </TableCell>
                    <TableCell align="center" id={"client_id" + index}>
                      {row.active ? (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => disableKey(row._id)}
                        >
                          Disable
                        </Button>
                      ) : (
                        <span>Disabled</span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <span
                        className="table-copy-text"
                        onClick={() => copyText("client_id" + index)}
                      >
                        Copy
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <div>No data found</div>
        )}
      </div>
    </div>
  );
};

export default APIKeys;
