import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const MyPlan = () => {
  return (
    <div>
      <h4>My plan</h4>
      <div className="mt-3">
        <Card sx={{ maxWidth: 375 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom component="div">
              Proxtera Classic
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              No platform fees
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              2% Transaction Fee (Suppliers only)
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              12 Month contract, automatic renewal
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              Rebate of all fees until 31 March 2022
            </Typography>
            <Button variant="outlined">Learn more</Button>
          </CardContent>
          
        </Card>
      </div>
    </div>
  );
};

export default MyPlan;
