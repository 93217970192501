import React, { useEffect, useState } from "react";
import "./Company.scss";
import profileImage from "../../../Assets/image/blank-profile-picture.png";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import { useNavigate } from "react-router-dom";

import apiService from "../../../Services/api-service";
import { useSnackbar } from "notistack";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import IndustryJson from "../../../Utility/IndustryJSON";
import * as countryList from "country-list";
import { State, City } from "country-state-city";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import moment from "moment";

const Company = () => {
  const [profileImg, setProfileImg] = useState(profileImage);
  const [industryData, setIndustryData] = useState([]);
  const [companyDetail, setCompanyDetail] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [listCountry, setListCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [companyApiData, setCompanyApiData] = useState({
    corporate_name: "",
    tax_id: "",
    country: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    website: "",
    platform_market_presence: "",
    most_represent_industry: "",
    total_businesses_registered: "",
    api_access_permission: false,
    document_locking: false,
    escrow_platform: false,
    financing: false,
    invoice: false,
    messaging: false,
    purchase_order: false,
    remittance: false,
    search: false,
    shipment: false,
    sme_platform: false,
    smes: false,
  });
  const navigate = useNavigate();
  let responseData = {};
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));

  useEffect(() => {
    document.title = "Company";
    setListCountry(countryList.getNames());
    getUserDetail();
    setIndustryData(IndustryJson);
  }, []);

  const findCity = (state, country) => {
    const stateDetail = State.getStatesOfCountry(countryList.getCode(country));
    stateDetail.forEach((val, index) => {
      if (val.name === state) {
        setCityList(City.getCitiesOfState(val.countryCode, val.isoCode));
      }
    });
  };

  const handelAccount = (property, event) => {
    const accountCopy = { ...companyApiData };
    accountCopy[property] = event;
    setCompanyApiData(accountCopy);
  };

  const handelCheckAccount = (event) => {};

  const getUserDetail = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loaderChange(true));
    apiService
      .get(`platform`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        responseData = response.data.data;
        setCompanyDetail(response.data.data);
        setCompanyData();
        setStateList(
          State.getStatesOfCountry(
            countryList.getCode(
              responseData?.platformDetail?.company_info?.address?.country
            )
          )
        );
        findCity(
          responseData?.platformDetail?.company_info?.address?.state,
          responseData?.platformDetail?.company_info?.address?.country
        );
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const setCompanyData = () => {
    // const accountCopy = { ...companyApiData };
    // accountCopy['corporate_name'] = responseData?.platformDetail?.company_info?.corporate_name;
    // accountCopy['tax_id'] = responseData?.platformDetail?.company_info?.tax_id;
    // accountCopy['country'] = responseData?.platformDetail?.company_info?.address?.country;
    // accountCopy['phone'] = responseData?.platformDetail?.company_info?.contact_info?.phone;
    // accountCopy['address'] = responseData?.platformDetail?.company_info?.address?.address;
    // accountCopy['city'] = responseData?.platformDetail?.company_info?.address?.city;
    // accountCopy['state'] = responseData?.platformDetail?.company_info?.address?.state;
    // accountCopy['postal_code'] = responseData?.platformDetail?.company_info?.address?.postal_code;
    // accountCopy['website'] = responseData?.platformDetail?.website;
    // accountCopy['platform_market_presence'] = responseData?.platformDetail?.platform_market_presence;
    // accountCopy['most_represent_industry'] = responseData?.platformDetail?.most_represent_industry;
    // accountCopy['total_businesses_registered'] = responseData?.platformDetail?.total_businesses_registered;
    // setCompanyApiData(accountCopy);
    // accountCopy['corporate_name'] = responseData?.platformDetail?.company_info?.corporate_name;

    setCompanyApiData({
      corporate_name:
        responseData?.platformDetail?.company_info?.corporate_name,
      tax_id: responseData?.platformDetail?.company_info?.tax_id,
      country: responseData?.platformDetail?.company_info?.address?.country,
      phone: responseData?.platformDetail?.company_info?.contact_info?.phone,
      address: responseData?.platformDetail?.company_info?.address?.address,
      city: responseData?.platformDetail?.company_info?.address?.city,
      state: responseData?.platformDetail?.company_info?.address?.state,
      postal_code:
        responseData?.platformDetail?.company_info?.address?.postal_code,
      website: responseData?.platformDetail?.website,
      platform_market_presence:
        responseData?.platformDetail?.platform_market_presence,
      most_represent_industry:
        responseData?.platformDetail?.most_represent_industry,
      total_businesses_registered:
        responseData?.platformDetail?.total_businesses_registered,
      api_access_permission:
        responseData?.allowed_permissions?.api_access_permission,
      document_locking:
        responseData?.platformDetail?.allowed_permissions?.document_locking,
      escrow_platform:
        responseData?.platformDetail?.allowed_permissions?.escrow_platform,
      financing: responseData?.allowed_permissions?.financing,
      invoice: responseData?.platformDetail?.allowed_permissions?.invoice,
      messaging: responseData?.platformDetail?.allowed_permissions?.messaging,
      purchase_order:
        responseData?.platformDetail?.allowed_permissions?.purchase_order,
      remittance: responseData?.platformDetail?.allowed_permissions?.remittance,
      search: responseData?.platformDetail?.allowed_permissions?.search,
      shipment: responseData?.platformDetail?.allowed_permissions?.shipment,
      sme_platform:
        responseData?.platformDetail?.allowed_permissions?.sme_platform,
      smes: responseData?.platformDetail?.allowed_permissions?.smes,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      !companyApiData.corporate_name ||
      !companyApiData.country ||
      !companyApiData.tax_id ||
      !companyApiData.corporate_name
    ) {
      return;
    }
    const apiData = {
      corporate_name: companyApiData.corporate_name
        ? companyApiData.corporate_name
        : "",
      tax_id: companyApiData.tax_id ? companyApiData.tax_id : "",
      country: companyApiData.country ? companyApiData.country : "",
      // phone: companyApiData.phone,
      address: companyApiData.address ? companyApiData.address : "",
      city: companyApiData.city ? companyApiData.city : "",
      state: companyApiData.state ? companyApiData.state : "",
      postal_code: companyApiData.postal_code ? companyApiData.postal_code : "",
      website: companyApiData.website ? companyApiData.website : "",
      platform_market_presence: companyApiData.platform_market_presence
        ? companyApiData.platform_market_presence
        : "",
      most_represent_industry: companyApiData.most_represent_industry
        ? companyApiData.most_represent_industry
        : "",
      total_businesses_registered: companyApiData.total_businesses_registered
        ? companyApiData.total_businesses_registered
        : "",
    };
    if (companyApiData.phone) {
      apiData.phone = companyApiData.phone;
    }
    saveCompanyDetails(apiData);
    // testApiFunction(apiData)
  };

  const saveCompanyDetails = async (bodyParameters) => {
    dispatch(loaderChange(true));
    apiService
      .post(
        `profile/${userDetails.platform_id}/update_company_info`,
        bodyParameters
      )
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        // navigate("invite-member");
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handelCountryAccount = (event) => {
    handelAccount("country", event.target.value);
    setStateList(
      State.getStatesOfCountry(countryList.getCode(event.target.value))
    );
  };

  const handelStateAccount = (event) => {
    handelAccount("state", event.target.value);
    findCity(event.target.value, companyApiData.country);
  };

  const handelCityAccount = (event) => {
    handelAccount("city", event.target.value);
  };

  return (
    <div className="company-edit-page">
      <div className="row row-custom-company">
        <div className="col-3 first-div">
          <div className="text-center">
            <img className="profile-img" src={profileImg} alt={"profileImg"} />
          </div>
          <div className="text-center mt-3 company-title">
            {companyDetail?.platformDetail?.company_info?.corporate_name}
          </div>

          <div className="text-center mt-2 country-title">
            {companyDetail?.platformDetail?.company_info?.address.country}
          </div>

          <div className="text-center access-chips">
            <Typography variant="h6" gutterBottom component="div">
              Access Detail API
            </Typography>

            <div className="permission-check-box-grid">
              {/* {companyApiData?.api_access_permission && <Chip label="Api Integration"  color="success" variant="outlined" size="small" />} */}
              {companyApiData?.api_access_permission && (
                <Chip
                  label="API Integration"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.search && (
                <Chip
                  label="Search"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.smes && (
                <Chip
                  label="SME"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.purchase_order && (
                <Chip
                  label="Purchase order"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.invoice && (
                <Chip
                  label="Invoice"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.remittance && (
                <Chip
                  label="Remittance"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.shipment && (
                <Chip
                  label="Shipment"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.sme_platform && (
                <Chip
                  label="SME platform"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.messaging && (
                <Chip
                  label="Messaging"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.escrow_platform && (
                <Chip
                  label="Escrow platform"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.financing && (
                <Chip
                  label="Financing"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
              {companyApiData?.document_locking && (
                <Chip
                  label="Document locking"
                  color="primary"
                  variant="outlined"
                  size="small"
                  icon={<DoneIcon />}
                />
              )}
            </div>
          </div>

          {companyDetail &&
            companyDetail?.platformDetail?.activity_logs?.length > 0 && (
              <div className="mt-3 permission-div pt-3">
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>

                {companyDetail &&
                  companyDetail?.platformDetail?.activity_logs?.length > 0 &&
                  companyDetail?.platformDetail?.activity_logs.map(
                    (item, index) => (
                      <div key={index}>
                        <Timeline className="flex-row pl-0 pt-0">
                          <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                              {item?.message}{" "}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot />
                              {companyDetail?.activity_logs?.length !==
                                index + 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              {moment(new Date(item?.created)).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                      </div>
                    )
                  )}
              </div>
            )}

          {/* <div className="text-center mt-2 role-div">Business Buyer</div>
          <div className="text-center mt-3">
            <button className="btn btn-primary btn-buyer" type="submit">
              Become a Business buyer
            </button>
          </div> */}
        </div>
        <div className="col-9 company-second-div pb-2">
          {/* <div className="text-center title-div">
            Congratulations you have signed up as an Individual Buyer!
          </div> */}
          <div className="d-flex justify-content-between mt-3">
            <div className="sub-head">Company profile</div>
          </div>

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.corporate_name}
            onChange={(event) =>
              handelAccount("corporate_name", event.target.value)
            }
            label="Company name"
            variant="outlined"
            className="mr-1 mt-3"
            helperText={
              !companyApiData.corporate_name && submitted
                ? "Company name is required"
                : ""
            }
            error={!companyApiData.corporate_name && submitted}
          />

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            label="Company Tax ID"
            variant="outlined"
            value={companyApiData.tax_id}
            onChange={(event) => handelAccount("tax_id", event.target.value)}
            className="mr-1 mt-3"
            helperText={
              !companyApiData.tax_id && submitted
                ? "Company Tax ID is required"
                : ""
            }
            error={!companyApiData.tax_id && submitted}
          />

          {/* <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.country}
            onChange={(event) => handelAccount("country", event.target.value)}
            label="Country of Registration"
            variant="outlined"
            className="mr-1 mt-3"
            helperText={
              !companyApiData.country && submitted
                ? "Country of Registration is required"
                : ""
            }
            error={!companyApiData.country && submitted}
          /> */}

          <FormControl
            className="w-100 mt-3"
            error={!companyApiData.country && submitted}
          >
            <InputLabel
              id="demo-simple-select-label"
              // sx={{ color: "white" }}
            >
              Country of Registration
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyApiData?.country}
              label="Country of Registration"
              sx={{
                borderRadius: "5px",
                // color: "white",
              }}
              onChange={handelCountryAccount}
            >
              {listCountry.map((val, index) => {
                return (
                  <MenuItem value={val} key={index}>
                    {val}
                  </MenuItem>
                );
              })}
            </Select>
            {!companyApiData.country && submitted ? (
              <FormHelperText>
                Country of Registration is required
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>

          {/* <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            label="Headquarter city"
            value={companyApiData.city}
            onChange={(event) => handelAccount("city", event.target.value)}
            variant="outlined"
            className="mr-1 mt-3"
          />

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.state}
            onChange={(event) => handelAccount("state", event.target.value)}
            label="State/Province"
            variant="outlined"
            className="mr-1 mt-3"
          />

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.postal_code}
            onChange={(event) =>
              handelAccount("postal_code", event.target.value)
            }
            label="Zip code"
            variant="outlined"
            className="mr-1 mt-3"
          /> */}

          <div className="d-flex -justify-content-between row-custom-company">
            {/* <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              label="Headquarter city"
              value={companyApiData?.city}
              onChange={(event) => handelAccount("city", event.target.value)}
              variant="outlined"
              className="mr-1 mt-3"
            /> */}

            <FormControl
              className="w-100 mr-1 mt-3"
              error={!companyApiData.state && submitted}
            >
              <InputLabel
                id="demo-simple-select-label"
                // sx={{ color: "white" }}
              >
                Headquarter city
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={companyApiData?.city}
                label="Headquarter city"
                sx={{
                  borderRadius: "5px",
                  // color: "white",
                }}
                onChange={handelCityAccount}
              >
                {cityList.map((val, index) => {
                  return (
                    <MenuItem value={val.name} key={index}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl
              className="w-100 mr-1 mt-3"
              error={!companyApiData.state && submitted}
            >
              <InputLabel
                id="demo-simple-select-label"
                // sx={{ color: "white" }}
              >
                State/Province
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={companyApiData?.state}
                label="State/Province"
                sx={{
                  borderRadius: "5px",
                  // color: "white",
                }}
                onChange={handelStateAccount}
              >
                {stateList.map((val, index) => {
                  return (
                    <MenuItem value={val.name} key={index}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              value={companyApiData?.state}
              onChange={(event) => handelAccount("state", event.target.value)}
              label="State/Province"
              variant="outlined"
              className="mr-1 mt-3"
            /> */}

            <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              value={companyApiData?.postal_code}
              onChange={(event) =>
                handelAccount("postal_code", event.target.value)
              }
              label="Zip code"
              variant="outlined"
              className="mr-1 mt-3"
            />
          </div>

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.address}
            onChange={(event) => handelAccount("address", event.target.value)}
            label="Company Address"
            variant="outlined"
            className="mr-1 mt-3"
          />

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.phone}
            onChange={(event) => handelAccount("phone", event.target.value)}
            label="Company phone"
            variant="outlined"
            className="mr-1 mt-3"
          />

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.website}
            onChange={(event) => handelAccount("website", event.target.value)}
            label="Company/Platform URL"
            variant="outlined"
            className="mr-1 mt-3"
          />

          {/* <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.platform_market_presence}
            onChange={(event) =>
              handelAccount("platform_market_presence", event.target.value)
            }
            label="Platform market presence"
            variant="outlined"
            className="mr-1 mt-3"
          /> */}

          <FormControl fullWidth className="mt-2">
            <InputLabel id="demo-simple-select-label">
              Platform market presence
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Platform market presence"
              value={companyApiData.platform_market_presence}
              onChange={(event) =>
                handelAccount("platform_market_presence", event.target.value)
              }
            >
              <MenuItem value={"South East Asia"}>South East Asia</MenuItem>
              <MenuItem value={"South Asia"}>South Asia</MenuItem>
              <MenuItem value={"Africa"}>Africa</MenuItem>
              <MenuItem value={"Europe"}>Europe</MenuItem>
              <MenuItem value={"North America"}>North America</MenuItem>
              <MenuItem value={"South America"}>South America</MenuItem>
              <MenuItem value={"Australia/Oceania"}>Australia/Oceania</MenuItem>
              <MenuItem value={"Antarctica"}>Antarctica</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth className="mt-2">
            <InputLabel id="demo-simple-select-label">
              Industry sectors
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Most represent industry"
              value={companyApiData.most_represent_industry}
              onChange={(event) =>
                handelAccount("most_represent_industry", event.target.value)
              }
            >
              {industryData.map((val, index) => {
                return (
                  <MenuItem value={val.name} index={index}>
                    {val.name}
                  </MenuItem>
                );
              })}
              {/* <MenuItem value={"Agriculture and Allied Industries."}>
                Agriculture and Allied Industries
              </MenuItem>
              <MenuItem value={"E-commerce"}>E-commerce</MenuItem>
              <MenuItem value={"Electronics System Design & Manufacturing"}>
                Electronics System Design & Manufacturing
              </MenuItem>
              <MenuItem value={"Metals And Mining"}>Metals And Mining</MenuItem> */}
            </Select>
          </FormControl>

          {/* <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.most_represent_industry}
            onChange={(event) =>
              handelAccount("most_represent_industry", event.target.value)
            }
            label="Most represent industry"
            variant="outlined"
            className="mr-1 mt-3"
          /> */}

          <TextField
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
            value={companyApiData.total_businesses_registered}
            onChange={(event) =>
              handelAccount("total_businesses_registered", event.target.value)
            }
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            label="Total businesses registered"
            variant="outlined"
            className="mr-1 mt-3"
          />

          {userDetails?.roles?.indexOf('ORG_ADMIN') !== -1 && (
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="contained"
                className="mr-2"
                color="error"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          )}

          {/* <div className="mt-3 permission-div pt-2">
            <Typography variant="h6" gutterBottom component="div">
              Access details
            </Typography>

            <FormGroup className="permission-check-box-grid">
              <FormControlLabel
                control={
                  <Checkbox disabled  checked={companyApiData?.api_access_permission} onChange={(event) =>
                    handelCheckAccount(event.target.value)
                  }/>
                }
                label="Api Integration"
              />
              <FormControlLabel
                control={<Checkbox disabled  checked={companyApiData?.search} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Search"
              />
              <FormControlLabel
                control={<Checkbox disabled  checked={companyApiData?.smes} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="SME"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.purchase_order} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Purchase order"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.invoice} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Invoice"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.remittance} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Remittance"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.shipment} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Shipment"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.sme_platform} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="SME platform"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.messaging} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Messaging"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.escrow_platform} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Escrow platform"
              />
              <FormControlLabel
                control={<Checkbox disabled checked={companyApiData?.financing} onChange={(event) =>
                  handelCheckAccount(event.target.value)
                }/>}
                label="Financing"
              />
              <FormControlLabel
                control={
                  <Checkbox disabled checked={companyApiData?.document_locking} onChange={(event) =>
                    handelCheckAccount(event.target.value)
                  }/>
                }
                label="Document locking"
              />
            </FormGroup>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Company;
