import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import "./SME.scss";
import SMEListTable from "./SMEListTable";

const SME = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const headCells = [
    {
      id: "sme_id",
      sort: true,
      align: "left",
      numeric: false,
      disablePadding: false,
      isVisible: true,
      label: "SME ID",
    },
    {
      id: "corporate_name",
      align: "left",
      sort: true,
      numeric: false,
      isVisible: userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 ? true : false,
      disablePadding: false,
      label: "Corporate name",
    },
    {
      id: "email",
      align: "center",
      sort: false,
      numeric: true,
      isVisible: true,
      disablePadding: false,
      label: "Email address",
    },
    {
      id: "name",
      align: "center",
      sort: false,
      numeric: true,
      isVisible: true,
      disablePadding: false,
      label: "Business name",
    },
    {
      id: "created",
      align: "center",
      sort: true,
      numeric: true,
      isVisible: true,
      disablePadding: false,
      label: "Onboard date",
    },
  ];

  useEffect(() => {
    document.title = "SME";
    // getSMEList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sme-list">
      <Box sx={{ width: "100%", height: "100%" }}>
        {/* {rows.length > 0 ? <SMEListTable
          headCells={headCells}
          data={rows}
          title={"SME List"}
          handleCSVChange={handleCSVChange}
        /> : ''} */}
        <SMEListTable
          headCells={headCells}
          title={"SME List"}
        />
      </Box>
    </div>
  );
};

export default SME;
