const apiRoutes = {
  platform: {
    detail: { path: 'platform', method: 'get'},
    checkUserExist: { path: 'platform/check_already_exists', method: 'post'},
  },
  profile: {
    updateProfile: { path: 'profile/update_profile', method: 'post'},    
    updateProfileImage: {path: 'profile/update_profile_image', method: 'post' },
  },
  customApi: {
    customApiUpload: { path: 'custom_api/upload', method: 'post'},
  },
  auth: {
    verifyCaptcha: { path: 'proxtera/recaptcha_verify', method: 'post'},
  },
  // inviteMember: {
  //   list: { path: `members/:platform_i`, method: "get" },
  //   create: { path: "members/:platform_id/invite", method: "post" },
  // },
};

export default apiRoutes;
