import React from "react";
import Grid from "@mui/material/Grid";
import { Button, TextField } from "@mui/material";

const WebhookURL = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Order events"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Message events"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Escrow events"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
      <Button className="mt-3" variant="contained">
        Submit
      </Button>
    </div>
  );
};

export default WebhookURL;
