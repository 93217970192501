import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const ApproveRoleModal = ({ onCloseModal, selectedRow, approveUSer }) => {
  const [checkValue, setCheckValue] = React.useState({
    api_access_permission: false,
    search: false,
    smes: false,
    purchase_order: false,
    invoice: false,
    remittance: false,
    shipment: false,
    sme_platform: false,
    messaging: false,
    escrow_platform: false,
    financing: false,
    document_locking: false,
  });

  const handleCheckboxChange = (e) => {
    const accountCopy = { ...checkValue };
    accountCopy[e.target.name] = e.target.value;
    setCheckValue(accountCopy);
  };

  const submitValue = () => {
    approveUSer(selectedRow, checkValue);
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Access details
      </Typography>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: {
                    name: "api_access_permission",
                    value: e.target.checked,
                  },
                });
              }}
            />
          }
          label="API Integration"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "search", value: e.target.checked },
                });
              }}
            />
          }
          label="Search"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "smes", value: e.target.checked },
                });
              }}
            />
          }
          label="SME"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "purchase_order", value: e.target.checked },
                });
              }}
            />
          }
          label="Purchase order"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "invoice", value: e.target.checked },
                });
              }}
            />
          }
          label="Invoice"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "remittance", value: e.target.checked },
                });
              }}
            />
          }
          label="Remittance"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "shipment", value: e.target.checked },
                });
              }}
            />
          }
          label="Shipment"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "sme_platform", value: e.target.checked },
                });
              }}
            />
          }
          label="SME platform"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "messaging", value: e.target.checked },
                });
              }}
            />
          }
          label="Messaging"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "escrow_platform", value: e.target.checked },
                });
              }}
            />
          }
          label="Escrow platform"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "financing", value: e.target.checked },
                });
              }}
            />
          }
          label="Financing"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange({
                  target: { name: "document_locking", value: e.target.checked },
                });
              }}
            />
          }
          label="Document locking"
        />
      </FormGroup>
      <div className="text-right mt-3">
        <Button
          variant="contained"
          color="error"
          className="mr-3"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={submitValue}>
          Approve
        </Button>
        {/* <Button size="medium" color="error">Cancel</Button>
      <Button size="medium" >Submit</Button> */}
      </div>
    </div>
  );
};

export default ApproveRoleModal;
