import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";

const AuthenticatorModal = ({onCloseModal}) => {
  const [qrData, setQRData] = useState("");
  const [step, setStep] = useState("step1");
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const [account, setAccount] = useState({
    code: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [cognitoUserdetail, setCognitoUser] = useState({})

  useEffect(() => {
    getQrCode();
  }, []);

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const getQrCode = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setCognitoUser(user)
        Auth.setupTOTP(user).then((code) => {
          setQRData(
            "otpauth://totp/Proxtera:" +
            userDetail?.email +
              "?secret=" +
              code
          );
        });
      })
      .catch((err) => console.log(err));
  };

  const verifyTOTP = () => {
    Auth.verifyTotpToken(cognitoUserdetail, account.code).then((data) => {
        // don't forget to set TOTP as the preferred MFA method
        Auth.setPreferredMFA(cognitoUserdetail, 'TOTP').then((data) => {
            onCloseModal();
    
            // don't forget to set TOTP as the preferred MFA method
            // ...
        }).catch( e => {
            // Token is not verified
        });;
        // ...
    }).catch( e => {
        // Token is not verified
    });
  }

  const changeStep = (value) => {
    setStep(value);
  };

  return (
    <>
      <div>
        <Typography variant="h6" gutterBottom component="div">
          Set up authenticator app
        </Typography>
        {step === "step1" ? (
          <div>
            <Typography variant="subtitle1" gutterBottom component="div">
              In the Google Authenticator app tap the +
            </Typography>

            <Typography variant="subtitle1" gutterBottom component="div">
              Choose Scan a OR code
            </Typography>

            <div className="text-center mt-4">
              <QRCode value={qrData} size={200} />
            </div>

            <div className="text-right mt-5">
              <Button size="small" onClick={onCloseModal}>Cancel</Button>

              <Button size="small" onClick={() => changeStep("step2")}>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="mt-4">
              <TextField
                sx={{
                  width: "100%",

                  borderRadius: "5px",
                }}
                id="outlined-basic"
                label="Enter code"
                variant="outlined"
                className="mr-1"
                helperText={
                  !account.code && submitted ? "Code is required" : ""
                }
                error={!account.code && submitted}
                onChange={(event) => handelAccount("code", event.target.value)}
              />
            </div>

            <div className="d-flex justify-content-between mt-5">
              <div>
                <Button size="small" onClick={() => changeStep("step1")}>Back</Button>
              </div>
              <div>
                <Button size="small" onClick={onCloseModal}>Cancel</Button>

                <Button size="small" onClick={verifyTOTP}>
                  Verify
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      
    </>
  );
};

export default AuthenticatorModal;
