const initValue = {
   menuOpen: false,
   darkMode: false,
   loaderOpenChange: false,
   loginOpen: false,
}

const reducer = (state = initValue, action) => {
    switch (action.type) {
       case 'MENU_OPEN': {
        return{
         ...state, menuOpen: action.payLoad
       }}
       case 'LOADER_CHANGE': {
        return{
         ...state, loaderOpenChange: action.payLoad
       }}
       case 'LOGIN_CHANGE': {
        return{
         ...state, loginOpen: action.payLoad
       }}
       case 'DARK_MODE_CHANGE': {
        return{
         ...state, loaderOpenChange: action.payLoad
       }}
       case 'ADD_DATA': return{         
         ...state, dataList: [...state.dataList, action.payLoad]
       }
       case 'UPDATE_DATA': return{         
         ...state, dataList: action.payLoad
       }
       case 'DELETE_DATA': return{         
         ...state, dataList:  action.payLoad
       }
       default: return state
    }
 }
 
 export default reducer;