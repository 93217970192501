import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Paper, Slide } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FileUploader } from "react-drag-drop-files";
import "./UATProgress.scss";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import apiService from "../../../Services/api-service";
import SearchProxtera from "./SearchProxtera";

const UATProgress = () => {
  const [uATcheck, setUATcheck] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const fileTypes = ["JPG", "PNG", "JPEG"];

  const [file, setFile] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const dispatch = useDispatch();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = "UAT Progress";
    getUATList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFileApi = async (uploadFile, category) => {
    dispatch(loaderChange(true));
    const data = new FormData();
    data.append("file", uploadFile);
    data.append("category", category);
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .post(`uat_progress/upload`, data, config)
      .then((response) => {
        dispatch(loaderChange(false));
        // setRows(response.data.data ? response.data.data : []);
        getUATList();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
      });
  };

  const getUATList = async () => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .get(`uat_progress`, config)
      .then((response) => {
        setUATcheck(response.data.data);
        dispatch(loaderChange(false));
        // setCheckApiData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const searchSubmit = (data, value) => {
    // e.preventDefault();
    // setSubmitted(true);
    if (!data.api_url) {
      return;
    }
    // if (!validateEmail(data.username)) {
    //   enqueueSnackbar("Invalid Email", {
    //     variant: "error",
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "right",
    //     },
    //     TransitionComponent: Slide,
    //     autoHideDuration: 2000,
    //   });
    //   return;
    // }
    const apiData = {
      api_url: data.api_url,
      username: data.username,
      password: data.password,
      email: data.email,
      category: value,
    };
    submitApi(apiData);
    // testApiFunction(apiData)
  };

  const validateEmail = (email) => {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const submitApi = async (bodyParameters) => {
    dispatch(loaderChange(true));
    apiService
      .post(`uat_progress`, bodyParameters)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        setExpanded(false);
        // navigate("invite-member");
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
        dispatch(loaderChange(false));
      });
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const handleChange = (fileEvent, category) => {
    let file = fileEvent[0];
    if (file.size > 5e6) {
      enqueueSnackbar("File size must be less than 5 mb", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    fileToDataUri(file).then((dataUri) => {
      uploadUatImage(dataUri, category);
    });
    setFile(fileEvent);
    fileEvent = null;
    setExpanded(false);
  };

  const uploadUatImage = async (uploadFile, category) => {
    dispatch(loaderChange(true));
    const data = new FormData();
    data.append("file", uploadFile);
    data.append("category", category);
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .post(`uat_progress/upload`, data, config)
      .then((response) => {
        dispatch(loaderChange(false));
        // setRows(response.data.data ? response.data.data : []);
        getUATList();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
      });
  };

  return (
    <div>
      <div>
        <Typography variant="h5" gutterBottom component="div">
          UAT Progress
        </Typography>
        {userDetails?.platformDetail?.allowed_permissions?.search && (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={
              !uATcheck?.search || uATcheck.search.status === "Rejected"
                ? handleChangeAccordion("panel1")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Search (Platform search Proxtera) </Typography>
              <Typography
                className={
                  uATcheck?.search?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.search?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            // handleChange={handleChange}
                            handleChange={(event) =>
                              handleChange(event, "search")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"search"}
                      placeHolder={"Search page URL"}
                    />
                    {/* <div>
                    <div className="head-div">Share your platform access</div>
                    <div className="pl-2 mt-3 sub-head-div">
                      Share platform search page URL, provide credentials where
                      applicable
                    </div>
                    <div className="pl-2 mt-3">
                      <div>
                        <TextField
                          sx={{ width: "100%" }}
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          id="outlined-basic"
                          label="Search page URL"
                          value={account.api_url}
                          variant="outlined"
                          onChange={(event) =>
                            handelAccount("api_url", event.target.value)
                          }
                          helperText={
                            !account.api_url && submitted
                              ? "Search page URL is required"
                              : ""
                          }
                          error={!account.api_url && submitted}
                        />
                        <TextField
                          sx={{ width: "100%" }}
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          // id="outlined-basic"
                          className="mt-2"
                          label="Email"
                          variant="outlined"
                          onChange={(event) =>
                            handelAccount("username", event.target.value)
                          }
                          helperText={
                            !account.username && submitted
                              ? "Email is required"
                              : ""
                          }
                          error={!account.username && submitted}
                        />
                        <TextField
                          sx={{ width: "100%" }}
                          inputProps={{ style: { fontSize: 14 } }}
                          InputLabelProps={{ style: { fontSize: 14 } }}
                          // id="outlined-basic"
                          className="mt-2"
                          type="password"
                          label="Password"
                          variant="outlined"
                          onChange={(event) =>
                            handelAccount("password", event.target.value)
                          }
                          helperText={
                            !account.password && submitted
                              ? "Password is required"
                              : ""
                          }
                          error={!account.password && submitted}
                        />

                        <Button
                          className="mt-2 w-100"
                          variant="contained"
                          onClick={searchSubmit}
                        >
                          Submit your verification
                        </Button>
                      </div>
                    </div>
                  </div> */}
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {/* <Accordion className="mt-3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Search (Proxtera search Platform)</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <div className="head-div">Upload your evidence</div>
                    <div className="pl-2 mt-3 sub-head-div">
                      Submit a search result screenshot where you have conducted
                      a search
                    </div>
                    <div className="pl-2 mt-3">
                      <div>
                        <FileUploader
                          multiple={true}
                          handleChange={handleChange}
                          name="file"
                          label="Drag and drop or browse your file."
                          className="text-center"
                          types={fileTypes}
                        />
                        <p>
                          {file.length > 0 && file[0].name
                            ? `File name: ${file[0].name}`
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                <SearchProxtera searchSubmit={searchSubmit} value={'search'} />
                </Item>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {userDetails?.platformDetail?.allowed_permissions?.smes && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel2"}
            onChange={
              !uATcheck?.sme || uATcheck.sme.status === "Rejected"
                ? handleChangeAccordion("panel2")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>SME</Typography>
              <Typography
                className={
                  uATcheck?.sme?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.sme?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) => handleChange(event, "sme")}
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
           <div className="help-text-div">
             Read more about this step
           </div>
           <div className="help-text-div">
             I'm facing this issue with this step, Ask for help
           </div>
         </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"sme"}
                      placeHolder={"SME page URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.purchase_order && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel3"}
            onChange={
              !uATcheck?.createPurchaseOrder ||
              uATcheck.createPurchaseOrder.status === "Rejected"
                ? handleChangeAccordion("panel3")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Create purchase order</Typography>
              <Typography
                className={
                  uATcheck?.createPurchaseOrder?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.createPurchaseOrder?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "createPurchaseOrder")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
               <div className="help-text-div">
                 Read more about this step
               </div>
               <div className="help-text-div">
                 I'm facing this issue with this step, Ask for help
               </div>
             </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"createPurchaseOrder"}
                      placeHolder={"Create purchase order URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.purchase_order && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel4"}
            onChange={
              !uATcheck?.confirmPurchaseOrder ||
              uATcheck.confirmPurchaseOrder.status === "Rejected"
                ? handleChangeAccordion("panel4")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>Confirm purchase order </Typography>
              <Typography
                className={
                  uATcheck?.confirmPurchaseOrder?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.confirmPurchaseOrder?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "confirmPurchaseOrder")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                  <div className="help-text-div">
                    Read more about this step
                  </div>
                  <div className="help-text-div">
                    I'm facing this issue with this step, Ask for help
                  </div>
                </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"confirmPurchaseOrder"}
                      placeHolder={"Confirm purchase order URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.invoice && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel5"}
            onChange={
              !uATcheck?.createInvoice ||
              uATcheck.createInvoice.status === "Rejected"
                ? handleChangeAccordion("panel5")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>Create invoice</Typography>
              <Typography
                className={
                  uATcheck?.createInvoice?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.createInvoice?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "createInvoice")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                 <div className="help-text-div">
                   Read more about this step
                 </div>
                 <div className="help-text-div">
                   I'm facing this issue with this step, Ask for help
                 </div>
               </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"createInvoice"}
                      placeHolder={"Create invoice URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.invoice && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel6"}
            onChange={
              !uATcheck?.acceptInvoice ||
              uATcheck.acceptInvoice.status === "Rejected"
                ? handleChangeAccordion("panel6")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography>Accept invoice</Typography>
              <Typography
                className={
                  uATcheck?.acceptInvoice?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.acceptInvoice?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "acceptInvoice")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"acceptInvoice"}
                      placeHolder={"Accept invoice URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.remittance && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel7"}
            onChange={
              !uATcheck?.sendRemittanceAdvice ||
              uATcheck.sendRemittanceAdvice.status === "Rejected"
                ? handleChangeAccordion("panel7")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography>Send Remittance advice</Typography>
              <Typography
                className={
                  uATcheck?.sendRemittanceAdvice?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.sendRemittanceAdvice?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "sendRemittanceAdvice")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"sendRemittanceAdvice"}
                      placeHolder={"Send Remittance advice URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.shipment && (
          <Accordion
            expanded={expanded === "panel8"}
            className="mt-3"
            onChange={
              !uATcheck?.updateShippingStatus ||
              uATcheck.updateShippingStatus.status === "Rejected"
                ? handleChangeAccordion("panel8")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
            >
              <Typography>Update shipping status</Typography>
              <Typography
                className={
                  uATcheck?.updateShippingStatus?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.updateShippingStatus?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "updateShippingStatus")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"updateShippingStatus"}
                      placeHolder={"Update shipping status URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.sme_platform && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel9"}
            onChange={
              !uATcheck?.SMEPlatformEvents ||
              uATcheck.SMEPlatformEvents.status === "Rejected"
                ? handleChangeAccordion("panel9")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9a-content"
              id="panel9a-header"
            >
              <Typography>SME platforms (webhook)</Typography>
              <Typography
                className={
                  uATcheck?.SMEPlatformEvents?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.SMEPlatformEvents?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "SMEPlatformEvents")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
           <div className="help-text-div">
             Read more about this step
           </div>
           <div className="help-text-div">
             I'm facing this issue with this step, Ask for help
           </div>
         </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"SMEPlatformEvents"}
                      placeHolder={"SME platforms (webhook) URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.messaging && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel10"}
            onChange={
              !uATcheck?.sendTextMessage ||
              uATcheck.sendTextMessage.status === "Rejected"
                ? handleChangeAccordion("panel10")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10a-content"
              id="panel10a-header"
            >
              <Typography>Send message</Typography>
              <Typography
                className={
                  uATcheck?.sendTextMessage?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.sendTextMessage?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "sendTextMessage")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"sendTextMessage"}
                      placeHolder={"Send message URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.escrow_platform && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel11"}
            onChange={
              !uATcheck?.escrowPlatformEvents ||
              uATcheck.escrowPlatformEvents.status === "Rejected"
                ? handleChangeAccordion("panel11")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11a-content"
              id="panel11a-header"
            >
              <Typography>Escrow Platforms (webhook)</Typography>
              <Typography
                className={
                  uATcheck?.escrowPlatformEvents?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.escrowPlatformEvents?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "escrowPlatformEvents")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"escrowPlatformEvents"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {userDetails?.platformDetail?.allowed_permissions?.document_locking && (
          <Accordion
            className="mt-3"
            expanded={expanded === "panel12"}
            onChange={
              !uATcheck?.lockDocument ||
              uATcheck.lockDocument.status === "Rejected"
                ? handleChangeAccordion("panel12")
                : null
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12a-content"
              id="panel12a-header"
            >
              <Typography>Document locking</Typography>
              <Typography
                className={
                  uATcheck?.lockDocument?.status === "Approved"
                    ? "status-approve ml-3"
                    : "status-other ml-3"
                }
              >
                {uATcheck?.lockDocument?.status}{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <div>
                      <div className="head-div">Upload your evidence</div>
                      <div className="pl-2 mt-3 sub-head-div">
                        Submit a search result screenshot where you have
                        conducted a search
                      </div>
                      <div className="pl-2 mt-3">
                        <div>
                          <FileUploader
                            multiple={true}
                            handleChange={(event) =>
                              handleChange(event, "lockDocument")
                            }
                            name="file"
                            label="Drag and drop or browse your file."
                            className="text-center"
                            types={fileTypes}
                          />
                          <p>
                            {file.length > 0 && file[0].name
                              ? `File name: ${file[0].name}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div> */}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <SearchProxtera
                      searchSubmit={searchSubmit}
                      value={"lockDocument"}
                      placeHolder={"Document locking URL"}
                    />
                  </Item>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {/* <Accordion className="mt-3" onChange={!uATcheck?.escrowPlatformEvents ? handleChangeAccordion('panel5') : null}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Send GRN</Typography>
            <Typography className={uATcheck?.escrowPlatformEvents?.status === 'Approved' ? 'status-approve ml-3' : 'status-other ml-3' } >{uATcheck?.escrowPlatformEvents?.status} </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Item>
                  <div>
                    <div className="head-div">Upload your evidence</div>
                    <div className="pl-2 mt-3 sub-head-div">
                      Submit a search result screenshot where you have conducted
                      a search
                    </div>
                    <div className="pl-2 mt-3">
                      <div>
                        <FileUploader
                          multiple={true}
                          handleChange={handleChange}
                          name="file"
                          label="Drag and drop or browse your file."
                          className="text-center"
                          types={fileTypes}
                        />
                        <p>
                          {file.length > 0 && file[0].name
                            ? `File name: ${file[0].name}`
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-5 mb-3">
                    <div className="help-text-div">
                      Read more about this step
                    </div>
                    <div className="help-text-div">
                      I'm facing this issue with this step, Ask for help
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <SearchProxtera
                    searchSubmit={searchSubmit}
                    value={"updateShippingStatus"}
                  />
                </Item>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}
      </div>
    </div>
  );
};

export default UATProgress;
