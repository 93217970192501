import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

const SearchProxtera = ({ searchSubmit, value, placeHolder }) => {
  const [account, setAccount] = useState({
    api_url: "",
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const dataSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    searchSubmit(account, value);
  };

  return (
    <div>
      <div className="head-div">Share your platform access</div>
      <div className="pl-2 mt-3 sub-head-div">
        Share platform Screen recording/Document URL(Google drive/drop box), also provide credentials where applicable
      </div>
      <div className="pl-2 mt-3">
        <div>
          <TextField
            sx={{ width: "100%" }}
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            // id="outlined-basic"
            label={placeHolder}
            value={account.api_url}
            variant="outlined"
            onChange={(event) => handelAccount("api_url", event.target.value)}
            helperText={
              !account.api_url && submitted ? "Search page URL is required" : ""
            }
            error={!account.api_url && submitted}
          />
          <TextField
            sx={{ width: "100%" }}
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            // id="outlined-basic"
            className="mt-2"
            label="Username (optional)"
            variant="outlined"
            onChange={(event) => handelAccount("username", event.target.value)}
            helperText={
              !account.username && submitted ? "Email is required" : ""
            }
            error={!account.username && submitted}
          />
          <TextField
            sx={{ width: "100%" }}
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            // id="outlined-basic"
            className="mt-2"
            type="password"
            label="Password (optional)"
            variant="outlined"
            onChange={(event) => handelAccount("password", event.target.value)}
            helperText={
              !account.password && submitted ? "Password is required" : ""
            }
            error={!account.password && submitted}
          />

          <Button
            className="mt-2 w-100"
            variant="contained"
            onClick={(e) => dataSubmit(e)}
          >
            Submit your verification
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchProxtera;
