import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { passwordMaxLenth } from "../../../../Utility/CommonJson";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../../Redux/actions";
import apiService from "../../../../Services/api-service";

const WebhookAuthAuth = () => {
  const [account, setAccount] = React.useState({
    api_url: "",
    method: "POST",
    auth_type: "",
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    getRequest();
  }, []);

  const getRequest = async () => {
    dispatch(loaderChange(true));
    // setReturnResponse({});
    apiService
      .get(`platform/get_webhook_endpoint`)
      .then((res) => {
        setAccount({
          api_url: res?.data?.data?.notification_webhook_endpoint?.api_url
            ? res?.data?.data?.notification_webhook_endpoint?.api_url
            : "",
          method: res?.data?.data?.notification_webhook_endpoint?.method
            ? res?.data?.data?.notification_webhook_endpoint?.method
            : "",
          auth_type: res?.data?.data?.notification_webhook_endpoint
            ?.authorization_type
            ? res?.data?.data?.notification_webhook_endpoint?.authorization_type
            : "",
          username: res?.data?.data?.notification_webhook_endpoint?.key
            ? res?.data?.data?.notification_webhook_endpoint?.key
            : "",
          password: res?.data?.data?.notification_webhook_endpoint?.secret
            ? res?.data?.data?.notification_webhook_endpoint?.secret
            : "",
        });
        // enqueueSnackbar(res.data.message, {
        //   variant: "success",
        //   anchorOrigin: {
        //     vertical: "top",
        //     horizontal: "right",
        //   },
        //   TransitionComponent: Slide,
        //   autoHideDuration: 2000,
        // });
        dispatch(loaderChange(false));
        // setSubmitted(false);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const handelChangeAuthType = (property, event) => {
    const accountCopy = { ...account };    
    accountCopy['username'] = '';
    accountCopy['password'] = '';
    accountCopy[property] = event;
    setAccount(accountCopy);
    // handelAccount(property, event)
  };

  const handleClickShowPassword = () => {
    setAccount({
      ...account,
      showPassword: !account.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isValidHttpUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[#!-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!account.api_url || !account.method || !account.auth_type) {
      return;
    }
    if (account.auth_type !== "None") {
      if (!account.username || !account.password) {
        return;
      }
    }
    if (!isValidHttpUrl(account.api_url)) {
      return;
    }
    const apiData = {
      api_url: account.api_url,
      method: account.method,
      authorization_type: account.auth_type,
      // username: account.username,
      // password: account.password,
      // category: "catalog",
    };
    if (apiData?.authorization_type !== "None") {
      apiData.key = account?.username;
      apiData.secret = account?.password;
      // apiData.id = checkApiData?._id;
    }
    sendRequest(apiData);

    // testApiFunction(apiData)
  };

  const sendRequest = async (bodyParameters) => {
    dispatch(loaderChange(true));
    // setReturnResponse({});
    apiService
      .put(`platform/update_webhook_endpoint`, bodyParameters)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        setSubmitted(false);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  // console.log('account', account)

  return (
    <>
      <div className="mt-3">
        <FormControl fullWidth>
          <InputLabel id="method-select-label">Method</InputLabel>
          <Select
            labelId="method-select-label"
            id="method-select"
            value={account.method}
            label="Method"
            onChange={(event) => handelAccount("method", event.target.value)}
            error={!account.method && submitted}
          >
            {/* <MenuItem value={"GET"}>GET</MenuItem> */}
            <MenuItem value={"POST"}>POST</MenuItem>
          </Select>
          {!account.method && submitted ? (
            <FormHelperText error={true}>Method is required</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>

        <TextField
          sx={{
            width: "100%",
          }}
          label="API Url"
          value={account.api_url}
          className="mt-3"
          variant="outlined"
          onChange={(event) => handelAccount("api_url", event.target.value)}
          // helperText={
          //   !account.api_url && submitted ? "API Url is required" : ""
          // }
          error={!account.api_url && submitted}
        />
        {!account.api_url && submitted && (
          <FormHelperText error={true}>API Url is required</FormHelperText>
        )}

        {account.api_url && !isValidHttpUrl(account.api_url) && submitted && (
          <FormHelperText error={true}>Invalid API Url</FormHelperText>
        )}
        {/* {!account.api_url && submitted && (
                  <FormHelperText error="true" className="ml-3 mb-3">
                     API Url is required
                  </FormHelperText>
                )} */}

        <FormControl className="w-100 mt-3">
          <InputLabel
            id="auth_type-select-label"
            sx={{ background: "white", width: "139px" }}
          >
            Authorization type
          </InputLabel>
          <Select
            labelId="auth_type-select-label"
            id="auth_type-select"
            value={account.auth_type ? account.auth_type : ""}
            label="Method"
            onChange={(event) => handelChangeAuthType("auth_type", event.target.value)}
            error={!account.auth_type && submitted}
          >
            <MenuItem value={"Basic"}>Basic Auth</MenuItem>
            <MenuItem value={"API_KEY"}>API key</MenuItem>
            <MenuItem value={"None"}>None</MenuItem>
          </Select>
          {!account.auth_type && submitted ? (
            <FormHelperText error={true}>
              Authorization type is required
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>

        {account.auth_type === "Basic" && (
          <>
            <TextField
              sx={{
                width: "100%",
              }}
              label="Username"
              className="mt-3"
              variant="outlined"
              value={account.username}
              onChange={(event) =>
                handelAccount("username", event.target.value)
              }
              inputProps={{ maxLength: 100 }}
              helperText={
                !account.username && submitted && account.auth_type === "Basic"
                  ? "Username is required"
                  : ""
              }
              error={
                !account.username && submitted && account.auth_type === "Basic"
              }
            />

            <FormControl
              sx={{ width: "100%" }}
              className="mt-3"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={account.showPassword ? "text" : "password"}
                value={account.password}
                onChange={(event) =>
                  handelAccount("password", event.target.value)
                }
                error={
                  !account.password && submitted && account.auth_type === "Basic"
                }
                inputProps={{ maxLength: passwordMaxLenth }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {account.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {!account.password &&
              submitted &&
              account.auth_type === "Basic" ? (
                <FormHelperText error={true}>
                  Password is required
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </>
        )}
         {account.auth_type === "API_KEY" && (
          <>
            <TextField
              sx={{
                width: "100%",
              }}
              label="key"
              className="mt-3"
              variant="outlined"
              value={account?.username}
              onChange={(event) =>
                handelAccount("username", event.target.value)
              }
              inputProps={{ maxLength: 100 }}
              helperText={
                !account?.username && submitted && account?.auth_type === "API_KEY"
                  ? "Key is required"
                  : ""
              }
              error={
                !account.username && submitted && account.auth_type === "API_KEY"
              }
            />

            <TextField
              sx={{
                width: "100%",
              }}
              label="value"
              className="mt-3"
              variant="outlined"
              value={account?.password}
              onChange={(event) =>
                handelAccount("password", event.target.value)
              }
              inputProps={{ maxLength: 100 }}
              helperText={
                !account.password && submitted && account.auth_type === "API_KEY"
                  ? "Value is required"
                  : ""
              }
              error={
                !account.password && submitted && account.auth_type === "API_KEY"
              }
            />
          </>
        )}

        <div className="d-flex justify-content-end w-100 mt-5 mb-4">
          {/* <Button
                variant="contained"
                color="error"
                // sx={{ width: "100%" }}
                className="mr-2"
                // onClick={onCloseModal}
              >
                Close
              </Button> */}

          <Button
            variant="contained"
            // sx={{ width: "100%" }}
            className="ml-2"
            // endIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      {/* <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox disabled />}
                label="Oauth 2"
              />
            </FormGroup>
          </div>
          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Client ID"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Client secret"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Token exchange endpoint"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Scopes"
              variant="outlined"
            />
          </div>
        </Grid>

        <Grid item xs={4}>
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox disabled />}
                label="Auth token"
              />
            </FormGroup>
          </div>
          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Token type"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Token"
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox disabled />}
                label="Header"
              />
            </FormGroup>
          </div>
          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Header key"
              variant="outlined"
            />
          </div>

          <div className="mt-3">
            <TextField
              disabled
              fullWidth
              id="outlined-basic"
              label="Header token"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
      <Button className="mt-3" variant="contained">
        Submit
      </Button>
    </div> */}
    </>
  );
};

export default WebhookAuthAuth;
