import {
    Button,
    FormHelperText,
    Link,
    Slide,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loaderChange } from "../../Redux/actions";
import apiService from "../../Services/api-service";
import "./SignIn.scss";
import { Auth } from "aws-amplify";
import requestApi from "../../Services/restService";
import authRequestApi from "../../Services/auth-api-service";
import { passwordMaxLenth } from "../../Utility/CommonJson";
import GoogleIcon from "@mui/icons-material/Google";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import circleBG from "../../Assets/image/circleBG.webp";

const SignIn = () => {
    const [account, setAccount] = React.useState({
        email: "",
        password: "",
        code: "",
        cPassword: "",
    });
    const [challengeName, setChallengeName] = React.useState("DEFAULT");
    const [passwordChangeSubmit, setPasswordChangeSubmit] =
        React.useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [apiResponse, serApiResponse] = React.useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const screenMatchWebMobile = useMediaQuery("(min-width:740px)");
    let [reCaptchaToken, setReCaptchaToken] = React.useState("");

    useEffect(() => {
        document.title = "SignIn";
        localStorage.removeItem("captcha");
        getReCaptcha();
        // console.log(
        //     "CognitoHostedUIIdentityProvider",
        //     CognitoHostedUIIdentityProvider
        // );
    }, []);

    const getReCaptcha = () => {
        window.grecaptcha.ready(function () {
            dispatch(loaderChange(true));
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_KEY, {
                    action: "submit",
                })
                .then(async function (token) {
                    dispatch(loaderChange(false));
                    setReCaptchaToken(token);
                    // reCaptchaToken = token;
                    // Send form value as well as token to the server
                });
            // toggleCaptchaBadge(true);
            // return () => toggleCaptchaBadge(false);
        });
    };

    // const toggleCaptchaBadge = (show) => {
    //   const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    //   if (badge && badge instanceof HTMLElement) {
    //     badge.style.visibility = show ? 'visible' : 'hidden';
    //   }
    // };

    const handelAccount = (property, event) => {
        const accountCopy = { ...account };
        accountCopy[property] = event;
        setAccount(accountCopy);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (validateEmail(account.email) === null) {
            return;
        }
        if (!account.email || !account.password) {
            return;
        }
        const captcha = JSON.parse(localStorage.getItem("captcha"));
        if (captcha?.success) {
            signIn();
            return;
        }
        const formValue = {
            body: { token: reCaptchaToken },
        };
        dispatch(loaderChange(true));
        const apiResult = authRequestApi("auth", "verifyCaptcha", formValue);
        apiResult.then(
            (resolveOutput) => {
                localStorage.setItem(
                    "captcha",
                    JSON.stringify(resolveOutput.data.data)
                );
                if (resolveOutput.data.data.success) {
                    dispatch(loaderChange(false));
                    signIn();
                } else {
                    dispatch(loaderChange(false));
                    enqueueSnackbar("Captcha error", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 1000,
                    });
                }
            },
            (rejectOutput) => {
                dispatch(loaderChange(false));
                if (rejectOutput) {
                    enqueueSnackbar(rejectOutput?.data?.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
            }
        );
    };

    const navigatePage = (routeValue) => {
        navigate(routeValue);
    };

    const resendConfirmationCode = async () => {
        try {
            await Auth.resendSignUp(account.email);
        } catch (err) {}
    };

    const handleSubmitSmsOtp = async (e) => {
        e.preventDefault();

        try {
            // dispatch(loaderChange(true));
            const user = await Auth.confirmSignIn(
                apiResponse,
                account.code,
                challengeName
            );
            localStorage.removeItem("CognitoUser");
            localStorage.setItem("CognitoUser", JSON.stringify(user));
            localStorage.setItem(
                "token",
                user?.signInUserSession?.idToken?.jwtToken
            );
            await getUserDetail();
        } catch (error) {
            dispatch(loaderChange(false));
            enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                TransitionComponent: Slide,
                autoHideDuration: 1000,
            });
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const signIn = async () => {
        setSubmitted(true);
        if (validateEmail(account.email) === null) {
            return;
        }
        if (!account.email || !account.password) {
            return;
        }
        try {
            dispatch(loaderChange(true));
            const user = await Auth.signIn(account.email, account.password);
            setSubmitted(false);
            serApiResponse(user);
            dispatch(loaderChange(false));
            resetValue();
            if (user.challengeName) {
                setChallengeName(user.challengeName);
                return;
            }
            // localStorage.setItem("CognitoUser", JSON.stringify(user));
            // localStorage.setItem("token", user.signInUserSession.idToken.jwtToken);
            await getUserDetail();
            // navigate("/");
            // navigate("home");
        } catch (error) {
            dispatch(loaderChange(false));
            enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                TransitionComponent: Slide,
                autoHideDuration: 1000,
            });
        }
    };

    const getUserDetail = () => {
        // const formValue = {body: apiData, path: {platform_id: userDetails.platform_id}};
        // formValue.platform_id = userDetails.platform_id;
        dispatch(loaderChange(true));
        const apiResult = requestApi("platform", "detail");
        apiResult.then(
            (resolveOutput) => {
                dispatch(loaderChange(false));
                localStorage.setItem(
                    "userDetail",
                    JSON.stringify(resolveOutput.data.data)
                );
                localStorage.removeItem("captcha");
                // if (userDetails) {
                navigate("/home");
            },
            (rejectOutput) => {
                dispatch(loaderChange(false));
                if (rejectOutput) {
                    enqueueSnackbar(rejectOutput?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
            }
        );
    };

    // const getUserDetail = () => {
    //   const token = localStorage.getItem("token");
    //   const config = {
    //     headers: { Authorization: `Bearer ${token}` },
    //   };
    //   dispatch(loaderChange(true));
    //   apiService
    //     .get(`platform`, config)
    //     .then((response) => {
    //       dispatch(loaderChange(false));
    //       localStorage.setItem("userDetail", JSON.stringify(response.data.data));
    //       const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //       // if (userDetails) {
    //       navigate("/home");
    //       // }
    //     })
    //     .catch((error) => {
    //       dispatch(loaderChange(false));
    //       enqueueSnackbar("Something went wrong", {
    //         variant: "error",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "right",
    //         },
    //         TransitionComponent: Slide,
    //         autoHideDuration: 2000,
    //       });
    //     });
    // };

    const checkPassword = (str) => {
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(str);
    };

    const handleChangePassword = () => {
        setPasswordChangeSubmit(true);
        if (
            account.password !== account.cPassword ||
            !checkPassword(account.password)
        ) {
            // handelErrorMsg('passwordNotMatch', 'The password confirmation does not match.');
            return;
        }
        dispatch(loaderChange(true));
        Auth.completeNewPassword(apiResponse, account.password, {})
            .then((res) => {
                enqueueSnackbar("You Have Successfully Logged in", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
                resetValue();
                changeChallengeName("DEFAULT");
                // getUserDetail();
                // winning
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                dispatch(loaderChange(false));
                enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 1000,
                });

                // not winning
            });
    };

    const handleGoogleSignin = () => {
        dispatch(loaderChange(true));
        Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        })
            .then((res) => {
                enqueueSnackbar("You Have Successfully Logged in", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
                changeChallengeName("DEFAULT");
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                dispatch(loaderChange(false));
                enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 1000,
                });

                // not winning
            });
    };

    const changeChallengeName = (name) => {
        resetValue();
        setChallengeName(name);
    };

    const handleChangeForgotPassword = () => {
        setSubmitted(true);
        setPasswordChangeSubmit(true);
        if (!account.email || validateEmail(account?.email) === null) {
            return;
        }
        dispatch(loaderChange(true));
        Auth.forgotPassword(account.email)
            .then((res) => {
                enqueueSnackbar("Email sent successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
                setChallengeName("FORGOT_PASSWORD_SUBMIT");
                setSubmitted(false);
                setPasswordChangeSubmit(false);
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                dispatch(loaderChange(false));
                enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 5000,
                });
                // not winning
            });
    };

    const handleChangeForgotPasswordSubmit = () => {
        setPasswordChangeSubmit(true);
        if (!account.email || !account.code || !account.password) {
            return;
        }
        dispatch(loaderChange(true));
        Auth.forgotPasswordSubmit(account.email, account.code, account.password)
            .then((res) => {
                enqueueSnackbar("Password changed successfully", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
                setChallengeName("DEFAULT");
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                dispatch(loaderChange(false));
                enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 1000,
                });
                // not winning
            });
    };

    const focusedColor = "#182269";
    const useStyles = makeStyles({
        root: {
            // input label when focused
            "& label.Mui-focused": {
                color: focusedColor,
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
                borderBottomColor: focusedColor,
            },
            // focused color for input with variant='filled'
            "& .MuiFilledInput-underline:after": {
                borderBottomColor: focusedColor,
            },
            // focused color for input with variant='outlined'
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: focusedColor,
                },
            },
            label: {
                color: "red",
            },
        },
    });
    const classes = useStyles();

    const renderSwitch = () => {
        switch (challengeName) {
            case "NEW_PASSWORD_REQUIRED":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Set new password
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        borderRadius: "5px",
                                    }}
                                    className={classes.root}
                                    InputLabelProps={
                                        {
                                            // style: { color: "white" },
                                        }
                                    }
                                    inputProps={{ maxLength: passwordMaxLenth }}
                                    id="outlined-basic"
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    value={account.password}
                                    onChange={(event) =>
                                        handelAccount(
                                            "password",
                                            event.target.value
                                        )
                                    }
                                    helperText={
                                        !account.password && submitted
                                            ? "Password is required"
                                            : ""
                                    }
                                    error={!account.password && submitted}
                                />
                                <div className="err-msg ml-1 mt-1">
                                    {!checkPassword(account.password) &&
                                    passwordChangeSubmit
                                        ? "A minimum 8 characters password contains a combination of uppercase and lowercase letter, number and special character are required."
                                        : ""}
                                </div>
                            </div>
                            <div className="mt-4">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        borderRadius: "5px",
                                    }}
                                    className={classes.root}
                                    // InputLabelProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    // InputProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    inputProps={{ maxLength: passwordMaxLenth }}
                                    id="outlined-basic"
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    value={account.cPassword}
                                    onChange={(event) =>
                                        handelAccount(
                                            "cPassword",
                                            event.target.value
                                        )
                                    }
                                    // helperText={
                                    //   account.password !== account.cPassword &&
                                    //   passwordChangeSubmit
                                    //     ? "Password is required"
                                    //     : ""
                                    // }
                                    error={!account.cPassword && submitted}
                                />
                                <div className="err-msg ml-1 mt-1">
                                    {account.password !== account.cPassword &&
                                    passwordChangeSubmit
                                        ? "The password confirmation does not match."
                                        : ""}
                                </div>
                            </div>
                            <div className="">
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleChangePassword}
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className="mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("DEFAULT")
                                    }
                                >
                                    Back to Sign in
                                </span>
                            </div>
                        </div>
                    </div>
                );
            case "FORGOT_PASSWORD":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Forgot password
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        //
                                        borderRadius: "5px",
                                    }}
                                    InputLabelProps={
                                        {
                                            // style: { color: "white" },
                                        }
                                    }
                                    InputProps={
                                        {
                                            // style: { color: "white" },
                                        }
                                    }
                                    className={classes.root}
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    value={account.email}
                                    onChange={(event) =>
                                        handelAccount(
                                            "email",
                                            event.target.value
                                        )
                                    }
                                    // helperText={
                                    //   !account.email && submitted ? "Email ID is required" : ""
                                    // }
                                    error={!account.email && submitted}
                                />
                                {!account.email && submitted && (
                                    <FormHelperText
                                        error="true"
                                        className="ml-3 mb-3"
                                    >
                                        Email ID is required
                                    </FormHelperText>
                                )}
                                {submitted &&
                                    account.email &&
                                    validateEmail(account.email) === null && (
                                        <FormHelperText
                                            error="true"
                                            className="ml-3 mb-3"
                                        >
                                            Invalid email
                                        </FormHelperText>
                                    )}
                            </div>
                            <div className="">
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleChangeForgotPassword}
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className="mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("DEFAULT")
                                    }
                                >
                                    Back to Sign in
                                </span>
                            </div>
                        </div>
                    </div>
                );
            case "FORGOT_PASSWORD_SUBMIT":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Forgot password
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        //
                                        borderRadius: "5px",
                                    }}
                                    className={classes.root}
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    value={account.email}
                                    onChange={(event) =>
                                        handelAccount(
                                            "email",
                                            event.target.value
                                        )
                                    }
                                    // helperText={
                                    //   !account.email && submitted ? "Email ID is required" : ""
                                    // }
                                    error={!account.email && submitted}
                                />
                                {!account.email && passwordChangeSubmit && (
                                    <FormHelperText
                                        error="true"
                                        className="ml-3 mb-3"
                                    >
                                        Email ID is required
                                    </FormHelperText>
                                )}
                                {passwordChangeSubmit &&
                                    account.email &&
                                    validateEmail(account.email) === null && (
                                        <FormHelperText
                                            error="true"
                                            className="ml-3 mb-3"
                                        >
                                            Invalid email
                                        </FormHelperText>
                                    )}
                                <div className="mt-4">
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            //
                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        className={classes.root}
                                        id="outlined-basic"
                                        label="Code"
                                        variant="outlined"
                                        value={account.code}
                                        onChange={(event) =>
                                            handelAccount(
                                                "code",
                                                event.target.value
                                            )
                                        }
                                        // helperText={
                                        //   !account.email && submitted ? "Code is required" : ""
                                        // }
                                        error={!account.code && submitted}
                                    />
                                    {!account.code && passwordChangeSubmit && (
                                        <FormHelperText
                                            error="true"
                                            className="ml-3 mb-3"
                                        >
                                            Email ID is required
                                        </FormHelperText>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <TextField
                                        sx={{
                                            width: "100%",
                                            borderRadius: "5px",
                                        }}
                                        // InputLabelProps={{
                                        //   style: { color: "white" },
                                        // }}
                                        // InputProps={{
                                        //   style: { color: "white" },
                                        // }}
                                        inputProps={{
                                            maxLength: passwordMaxLenth,
                                        }}
                                        className={classes.root}
                                        id="outlined-basic"
                                        label="Password"
                                        type="password"
                                        variant="outlined"
                                        value={account.password}
                                        onChange={(event) =>
                                            handelAccount(
                                                "password",
                                                event.target.value
                                            )
                                        }
                                        // helperText={
                                        //   !account.password && submitted
                                        //     ? "Password is required"
                                        //     : ""
                                        // }
                                        error={!account.password && submitted}
                                    />
                                </div>
                                {!checkPassword(account.password) &&
                                    passwordChangeSubmit && (
                                        <FormHelperText
                                            error="true"
                                            className="ml-3 mb-3"
                                        >
                                            minimum 8 characters password
                                            contains a combination of uppercase
                                            and lowercase letter, number and
                                            special character are required.
                                        </FormHelperText>
                                    )}

                                {/* <div className="err-msg ml-1 mt-1">
                  {!checkPassword(account.password) && passwordChangeSubmit
                    ? "A minimum 8 characters password contains a combination of uppercase and lowercase letter, number and special character are required."
                    : ""}
                </div> */}
                            </div>
                            <div className="">
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleChangeForgotPasswordSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                            <div className="mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("DEFAULT")
                                    }
                                >
                                    Back to Sign in
                                </span>
                            </div>
                        </div>
                    </div>
                );

            case "SMS_MFA":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Enter code
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        //
                                        borderRadius: "5px",
                                    }}
                                    // InputLabelProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    // InputProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    label="Code"
                                    variant="outlined"
                                    value={account.code}
                                    onChange={(event) =>
                                        handelAccount(
                                            "code",
                                            event.target.value
                                        )
                                    }
                                    className={classes.root}
                                    helperText={
                                        !account.code && submitted
                                            ? "Code is required"
                                            : ""
                                    }
                                    error={!account.code && submitted}
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleSubmitSmsOtp}
                                >
                                    Submit code
                                </Button>
                            </div>
                            <div className="mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("DEFAULT")
                                    }
                                >
                                    Back to Sign in
                                </span>
                            </div>
                        </div>
                    </div>
                );
            case "SOFTWARE_TOKEN_MFA":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Enter code
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    autocomplete="email"
                                    sx={{
                                        width: "100%",
                                        //
                                        borderRadius: "5px",
                                    }}
                                    // InputLabelProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    // InputProps={{
                                    //   style: { color: "white" },
                                    // }}
                                    className={classes.root}
                                    label="Code"
                                    variant="outlined"
                                    value={account.code}
                                    onChange={(event) =>
                                        handelAccount(
                                            "code",
                                            event.target.value
                                        )
                                    }
                                    helperText={
                                        !account.code && submitted
                                            ? "Code is required"
                                            : ""
                                    }
                                    error={!account.code && submitted}
                                />
                            </div>
                            <div className="help-text-custom">
                                * check your Authenticator app
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleSubmitSmsOtp}
                                >
                                    Submit code
                                </Button>
                            </div>
                            <div className="mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("DEFAULT")
                                    }
                                >
                                    Back to Sign in
                                </span>
                            </div>
                        </div>
                    </div>
                );

            case "DEFAULT":
                return (
                    <div
                        className={
                            screenMatchWebMobile ? "form-div w-50 " : "form-div"
                        }
                    >
                        <div className="w-100">
                            <div className="f-22 right-div-header">
                                Login to Proxtera
                            </div>
                            {/* <div>All we need are a few details...</div> */}
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        marginBottom: "16px",
                                        borderRadius: "5px",
                                    }}
                                    className={classes.root}
                                    InputLabelProps={
                                        {
                                            // style: { color: "#333333", },
                                        }
                                    }
                                    // InputProps={{
                                    //   // style: { color: '#333333' },
                                    // }}
                                    id="outlined-basic"
                                    label="Email ID"
                                    variant="outlined"
                                    value={account.email}
                                    onChange={(event) =>
                                        handelAccount(
                                            "email",
                                            event.target.value
                                        )
                                    }
                                    // helperText={
                                    //   <>
                                    //   {!account.email && submitted && <p>Email ID is required</p>}
                                    //   {submitted && validateEmail(account.email) === null && <p>Invalid email</p>}
                                    //   {/* ? "Email ID is required" : submitted && validateEmail(account.email) === null && 'Invalid email' */}
                                    //   </>

                                    // }
                                    error={!account.email && submitted}
                                />
                                {!account.email && submitted && (
                                    <FormHelperText
                                        error="true"
                                        className="ml-3 mb-3"
                                    >
                                        Email ID is required
                                    </FormHelperText>
                                )}
                                {submitted &&
                                    account.email &&
                                    validateEmail(account.email) === null && (
                                        <FormHelperText
                                            error="true"
                                            className="ml-3 mb-3"
                                        >
                                            Invalid email
                                        </FormHelperText>
                                    )}
                            </div>
                            <div className="mt-2 ">
                                <TextField
                                    sx={{
                                        width: "100%",
                                        borderRadius: "5px",
                                    }}
                                    className={classes.root}
                                    InputLabelProps={
                                        {
                                            // style: { color: "#333333" },
                                        }
                                    }
                                    inputProps={{ maxLength: passwordMaxLenth }}
                                    value={account.password}
                                    id="outlined-basic"
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    // helperText={
                                    //   !account.password && submitted ? "Password is required" : ""
                                    // }
                                    error={!account.password && submitted}
                                    onChange={(event) =>
                                        handelAccount(
                                            "password",
                                            event.target.value
                                        )
                                    }
                                />
                                {!account.password && submitted && (
                                    <FormHelperText
                                        error="true"
                                        sx={{ marginTop: "16px" }}
                                        className="ml-3 mb-3"
                                    >
                                        Password is required
                                    </FormHelperText>
                                )}
                            </div>
                            <div className="">
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "100%",
                                        marginTop: "24px",
                                        padding: "13px 16px",
                                        backgroundColor: "#182269",
                                    }}
                                    onClick={handleSubmit}
                                    size="large"
                                >
                                    Sign In
                                </Button>
                                {/* <Button
                  sx={{
                    width: "100%",
                    marginTop: "24px",
                    padding: "13px 16px",
                  }}
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleSignin}
                >
                  Sign in with Google
                </Button> */}
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() =>
                                        changeChallengeName("FORGOT_PASSWORD")
                                    }
                                >
                                    Forgot password?
                                </span>
                                <span
                                    className="help-text-custom cursor-pointer"
                                    onClick={() => navigate("/register")}
                                >
                                    Sign up
                                </span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return;
        }
    };

    const resetValue = () => {
        setAccount({
            email: "",
            password: "",
            code: "",
            cPassword: "",
        });
    };

    const renderMobileSwitch = () => {
        if (!screenMatchWebMobile) {
            return (
                <div className="form-div ">
                    <div className="w-100">
                        {/* <div className="f-22 right-div-header">Login to Proxtera</div> */}
                        {/* <div>All we need are a few details...</div> */}
                        {renderSwitch()}

                        {/* <div className="mt-3">
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  borderRadius: "5px",
                }}
                className={classes.root}
                id="outlined-basic"
                label="Email ID"
                variant="outlined"
                value={account.email}
                onChange={(event) => handelAccount("email", event.target.value)}
                helperText={
                  !account.email && submitted ? "Email ID is required" : ""
                }
                error={!account.email && submitted}
              />
            </div>
            <div className="mt-2 ">
              <TextField
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                }}
                className={classes.root}
                value={account.password}
                id="outlined-basic"
                label="Password"
                type="password"
                variant="outlined"
                helperText={
                  !account.password && submitted ? "Password is required" : ""
                }
                error={!account.password && submitted}
                onChange={(event) =>
                  handelAccount("password", event.target.value)
                }
              />
            </div>
            <div className="">
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  marginTop: "24px",
                  padding: "13px 16px",
                  backgroundColor: "#182269",
                }}
                onClick={handleSubmit}
                size="large"
              >
                Sign In
              </Button>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <span
                className="help-text-custom cursor-pointer"
                onClick={() => changeChallengeName("FORGOT_PASSWORD")}
              >
                Forgot password?
              </span>
              <span
                className="help-text-custom cursor-pointer"
                onClick={() => navigate("/register")}
              >
                Sign up
              </span>
            </div> */}
                    </div>
                </div>
            );
        }
    };

    const renderWebSwitch = () => {
        if (screenMatchWebMobile) {
            return (
                <div className="d-flex justify-content-between w-100 main-div-sign-in">
                    <div className="text-center w-50 position-relative mobile-d-none common-color">
                        <div className="first-div-alignment-center">
                            Proxtera, bringing the world to you
                        </div>
                        <div className="first-div-alignment-bottom">
                            © 2023 Proxtera. All rights reserved.
                        </div>
                        {/* <img
              src={"https://sso.proxtera.app/assets/images/login-bg.png"}
              alt="logo"
              className="mr-2 w-100 left-img"
            />
            <img
              src={"https://sso.proxtera.app/assets/images/proxtera-white.png"}
              alt="logo"
              className="logo-1"
            />
            <img
              src={"https://sso.proxtera.app/assets/images/logo.png"}
              alt="logo"
              className="logo-2"
            /> */}
                        <img
                            src={circleBG}
                            alt="logo"
                            className="mr-2 w-100 "
                        />
                    </div>
                    {/* <div className="link-div-older-version">
                        <Link
                            href="https://uat.proxtera.app"
                            underline="none"
                            sx={{ fontSize: "14px" }}
                        >
                            Switch to Previous Version
                        </Link>
                    </div> */}
                    {renderSwitch()}
                </div>
            );
        }
    };

    return (
        <>
            {renderWebSwitch()}
            {renderMobileSwitch()}
        </>
    );
};

export default SignIn;
