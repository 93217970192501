import { Navigate } from "react-router-dom";
import Auth from "./Auth/Auth";
import Landing from "./Components/Landing";
import NotFound from "./NotFound";
import Home from "./Components/Pages/Home/Home";
import CatalogList from "./Components/Pages/CatalogList/CatalogList";
import ApiIntegration from "./Components/Pages/API-integration/ApiIntegration";
import Register from "./Auth/Register/Register";
import GenerateApiKey from "./Components/Pages/API-integration/GenerateApiKey/GenerateApiKey";
import InviteMember from "./Components/Pages/InviteMember/InviteMember";
import Platform from "./Components/Pages/Platform/Platform";
import Profile from "./Components/Pages/Profile/Profile";
import UATProgress from "./Components/Pages/UATProgress/UATProgress";
import Company from "./Components/Pages/Profile/Company";
import Catalog from "./Components/Pages/Catalog/Catalog";
import SME from "./Components/Pages/SME/SME";
import UATList from "./Components/Pages/UATProgress/UATList";
import ProfileEdit from "./Components/Pages/Profile/ProfileEdit";
import ChangePassword from "./Components/Pages/Profile/ChangePassword";
import MyPlan from "./Components/Pages/Profile/MyPlan";
import Authenticator from "./Components/Pages/Profile/Authenticator";
import SignIn from "./Auth/SignIn/SignIn";
import UpdateCompany from "./Components/Pages/Platform/UpdateCompany";
import Survey from "./Survey/Survey";

const getUserDetail = () => {
  return JSON.parse(localStorage.getItem("userDetail"));
};

const routes = (isLoggedIn) => [
  {
    path: "*",
    element: <NotFound />,
  },

  {
    path: "/*",
    element: isLoggedIn ? <Landing /> : <Navigate to="/sign-in" />,
    // element: isLoggedIn ? (
    //   !getUserDetail()?.platformDetail.is_questionnaires_done && getUserDetail()?.roles?.indexOf('ORG_ADMIN') !== -1 ? (
    //     <Survey />
    //   ) : (
    //     <Landing />
    //   )
    // ) : (
    //   <Navigate to="/sign-in" />
    // ),
    children: [
      // {
      //   path: 'survey',
      //   element:
      //         !getUserDetail()?.platformDetail.is_questionnaires_done ? (
      //           <Survey />
      //         ) : (
      //           <Navigate to="/home" />
      //         ),
      // },
      {
        path: "home/*",
        element: <Home />,
      },
      {
        path: "sme-list",
        element: <SME />,
      },
      {
        path: "platform",
        element:
        getUserDetail()?.roles?.indexOf('GLOBAL_ADMIN') !== -1 ? (
            <Platform />
          ) : (
            <Navigate to="/home" />
          ),
      },
      {
        path: "update-company",
        element: <UpdateCompany />,
      },
      {
        path: "catalog-list",
        element: <CatalogList />,
      },
      {
        path: "api-integration",
        element:
          getUserDetail()?.roles?.indexOf('ORG_USER') !== -1 ||
          getUserDetail()?.roles?.indexOf('ORG_ADMIN') !== -1 ? (
            <ApiIntegration />
          ) : (
            <Navigate to="/home" />
          ),
      },
      {
        path: "generate-api-key",
        element: <GenerateApiKey />,
      },
      {
        path: "invite-member",
        element:
          getUserDetail()?.roles?.indexOf('ORG_ADMIN') !== -1 ||
          getUserDetail()?.roles?.indexOf('GLOBAL_ADMIN') !== -1 ? (
            <InviteMember />
          ) : (
            <Navigate to="/home" />
          ),
      },
      {
        path: "uat-progress",
        element:
          getUserDetail()?.roles?.indexOf('ORG_ADMIN') !== -1 ? (
            <UATProgress />
          ) : (
            <Navigate to="/home" />
          ),
      },
      {
        path: "uat-approval",
        element:
          getUserDetail()?.roles?.indexOf('GLOBAL_ADMIN') !== -1 ? (
            <UATList />
          ) : (
            <Navigate to="/home" />
          ),
      },
      {
        path: "catalog",
        element: <Catalog />,
      },
      {
        path: "profile-edit",
        element: <ProfileEdit />,
      },
      {
        path: "authenticator",
        element: <Authenticator />,
      },
      {
        path: "my-plan",
        element: <MyPlan />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "edit-company",
        element:
          getUserDetail()?.roles?.indexOf('ORG_USER') !== -1 ||
          getUserDetail()?.roles?.indexOf('ORG_ADMIN') !== -1 ? (
            <Company />
          ) : (
            <Navigate to="/home" />
          ),
      },
      // {
      //   path: "*",
      //   element: <NotFound />,
      // },
    ],
  },

  {
    path: "register",
    element: <Register />,
  },
  // {
  //   path: 'survey', element: <Survey />
  // },
  {
    path: "/",
    element: !isLoggedIn ? <SignIn /> : <Navigate to="/home" />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      // {
      //   path: "*",
      //   element: <NotFound />,
      // },
      // {
      //   path: "auth",
      //   element: <Auth />,
      // },
      {
        path: "/",
        element: <Navigate to="/sign-in" />,
      },
    ],
  },
  // {
  //   path: "/",
  //   element: !isLoggedIn ? <Register /> : <Navigate to='/dashboard' />,
  //   children: [
  //     {
  //       path: "register",
  //       element: <Register />,
  //     },
  //     {
  //       path: "/",
  //       element: <Navigate to='/register' />,
  //     },
  //   ],
  // },
];

export default routes;
