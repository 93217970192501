import "react-responsive-modal/styles.css";
import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { SnackbarProvider } from "notistack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

function App() {
  const isLoggedIn = localStorage.getItem("userDetail") ? true : false;
  const routing = useRoutes(routes(isLoggedIn));
  const loaderValueChange = useSelector((state) => state.loaderOpenChange);

  return (
    <div>
      <SnackbarProvider maxSnack={3}>
        {routing}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderValueChange}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </SnackbarProvider>
    </div>
  );
}

export default App;
