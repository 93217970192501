import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import apiService from "../../../Services/api-service";
import PlatformCollapsibleTable from "./PlatformList";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import { useSnackbar } from "notistack";
import ApproveRoleModal from "./ApproveRoleModal";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import InvitePlatform from "./InvitePlatform/InvitePlatform";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // height: "calc(100vh - 80px)",
  maxHeight: "80%",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  overflow: "auto",
  width: "50%",
  // maxHeight: "calc(30rem - 80px)",
};

const Platform = () => {

  const headCells = [
    {
      id: "",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "",
      sort: false,
    },
    {
      id: "corporate_name",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Company/Corporate name",
      sort: true,
    },
    {
      id: "country",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Country of registration",
      sort: true,
    },
    {
      id: "tax_id",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Company Tax ID",
      sort: true,
    },
    {
      id: "platform_type",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Platform type",
      sort: true,
    },
    {
      id: "status",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Status",
      sort: false,
    },
    {
      id: "action",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Action",
      sort: false,
    },
    {
      id: "view_detail",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "",
      sort: false,
    },
  ];

  useEffect(() => {
    document.title = "Platform";
    // getUserList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sme-list">
      <PlatformCollapsibleTable
      headCells={headCells}
          // rows={rows}
          // approveUSer={onOpenApproveModal}
          // rejectUser={rejectUser}
        />
    </div>
  );
};

export default Platform;
