import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { IconButton, Slide, TextField, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import apiService from "../../../Services/api-service";
import Modal from "react-responsive-modal";
import InviteMemberModal from "./InviteMemberModal/InviteMemberModal";
import FileSaver from "file-saver";
import XLSX from "xlsx";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property, sort) => (event) => {
    if(sort){
      onRequestSort(event, property);
    }    
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={"normal"}
            size={"medium"}
            sx={{ fontSize: "18px", fontWeight: 500 }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.sort ? orderBy === headCell.id : false}
              hideSortIcon={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.sort)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, title, inviteModal, rows, exportToCSV, handelSearchEvent } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
      className="pl-0 mb-2"
    >
      {/* <TextField id="outlined-basic" label={title} variant="outlined" /> */}
      <div className="d-flex align-items-center">
      <Typography
        sx={{ fontWeight: 700, fontSize: 20 }}
        variant="h6"
        id="tableTitle"
        component="div"
        display="flex"
        justifyContent="flex-start"
      >
        {title}
      </Typography>
      {rows.length > 0 && (
        <TextField
          label="Search"
          id="outlined-size-small"
          size="small"
          className="ml-3"
          onChange={(event) => handelSearchEvent(event.target.value)}
        />
      )}      
      </div>
      <div className="d-flex">
      <Tooltip title="Invite Member">
        <Button variant="contained" onClick={inviteModal}>
          Invite
        </Button>
      </Tooltip>
      {rows.length > 0 ? (
        <div>
          <Tooltip title="Download">
            <IconButton onClick={() => exportToCSV(rows, title)}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        ""
      )}
      </div>
      
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function InviteTableCustomComponent({
  headCells = [],
  title,
}) {
  let [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [noDataText, setNoDataText] = useState("Loading ....");
  const [totalData, setTotalData] = useState(0);
  let searchValue = "";
  let queryParams = {
    rowsPerPage: 10,
    page: 0,
  };
  const [openInviteMember, setOpenInviteMember] = useState(false);
  const [editValue, setEditValue] = useState({});
  const fileExtension = ".xlsx";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  
  useEffect(() => {
    getUserList();
    // const apiResponse = getService(`members/${userDetails.platform_id}`);
    // console.log('apiResponse========>', apiResponse);
    
    // testApiFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserList = async () => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&search=${searchValue}`
    if(queryParams.sortBy) {
      apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${searchValue}`
    }
    
    apiService
      .get(`members?${apiQueryParam}`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        setRows(response.data?.data?.data);
        console.log('response.data?.data', response.data?.data?.data);
        setTotalData(response.data?.data?.total);
        // setRowsPerPage(response.data?.data?.size)
        dataCheck();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const dataCheck = () => {
    if (rows.length === 0) {
      setNoDataText("No results found");
    }
  };  

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // getUserList();
    queryParams.sortOrder = isAsc ? "dsc" : "asc";
    queryParams.sortBy = property;
    getUserList()
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    queryParams.rowsPerPage = parseInt(event.target.value)
    // queryParams.page = parseInt(page);
    setPage(0);
    getUserList();
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(parseInt(rowsPerPage));
    queryParams.page = parseInt(newPage);
    queryParams.rowsPerPage = parseInt(rowsPerPage)
    getUserList();
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   setRowsPerPage(parseInt(rowsPerPage));
  //   queryParams.page = parseInt(newPage);
  //   queryParams.rowsPerPage = parseInt(rowsPerPage)
  //   getUserList();
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   queryParams.rowsPerPage = parseInt(event.target.value, 10)
  //   setPage(0);
  //   getUserList();
  // };

  const handelSearchEvent = (event) => {
    searchValue = event;
    getUserList();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actionIcons = (row) => {
    return (
      <div className="action-icon">
        {row.active === true ? (
          <span>
            <Tooltip title="Disable">
              <i
                className="fa fa-ban cursor-pointer"
                aria-hidden="true"
                onClick={() => disableUser(row)}
              ></i>
            </Tooltip>
            {/* <Tooltip title="Edit">
              <i
                className="fa fa-check-circle ml-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => enableUser(row)}
              ></i>
              <i className="fa fa-pencil ml-2 cursor-pointer" aria-hidden="true" onClick={() => openEditModal(row)}></i>

            </Tooltip> */}
          </span>
        ) : (
          <span>
            <Tooltip title="Delete">
              <i
                className="fa fa-trash-o mr-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => deleteUser(row)}
              ></i>
            </Tooltip>
            <Tooltip title="Enable">
              <i
                className="fa fa-check-circle ml-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => enableUser(row)}
              ></i>
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  const disableUser = async (bodyParameters) => {
    dispatch(loaderChange(true));
    const apiData = { member_id: bodyParameters._id };
    apiService
      .post(`members/disable`, apiData)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        getUserList();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
        dispatch(loaderChange(false));
      });
  };

  const enableUser = async (bodyParameters) => {
    dispatch(loaderChange(true));
    const apiData = { member_id: bodyParameters._id };
    apiService
      .post(`members/enable`, apiData)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        getUserList();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
        dispatch(loaderChange(false));
      });
  };

  const deleteUser = async (bodyParameters) => {
    dispatch(loaderChange(true));
    const apiData = { member_id: bodyParameters._id };
    apiService
      .post(`members/remove`, apiData)
      .then((res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        getUserList();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }  
        dispatch(loaderChange(false));
      });
  };

  const inviteModal = () => {
    setEditValue({})
    setOpenInviteMember(true);
  };

  const onCloseModal = () => {
    setOpenInviteMember(false);
    getUserList();
  };

  const openEditModal = (row) =>{
    setEditValue(row)
    setOpenInviteMember(true);
  }

  const exportToCSV = (csvData, fileName) => {
    const dataCsv = [['Name', 'Email', 'Phone']];
    csvData.filter((val, index) => {
      dataCsv.push([`${val.first_name} ${val.last_name}`, val.email, val.phone  ])
    });
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
    <Paper
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 0,
        boxShadow: 'none'
      }}
    >
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={title}        
        inviteModal={inviteModal}
        rows={rows}
        exportToCSV={exportToCSV}
        handelSearchEvent={handelSearchEvent}
      />

      {rows.length > 0 ? (
        <TableContainer sx={{ boxShadow: 2, borderRadius: 2 }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <TableCell align="left" padding={"normal"}>
                        {row.first_name + ' ' + row.last_name}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {row.email}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        {row.phone}
                      </TableCell>
                      <TableCell align="center" padding={"normal"}>
                        <div className="action-icon">
                          {userDetails._id !== row._id ? actionIcons(row) : ""}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="center-no-data">{noDataText}</div>
      )}

      {rows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
    </Paper>

    <Modal
          open={openInviteMember}
          onClose={onCloseModal}
          closeIcon={<div></div>}
          closeOnOverlayClick={true}
        >
          <InviteMemberModal editValue={editValue} onCloseModal={onCloseModal} />
        </Modal>
    </>
    
  );
}
