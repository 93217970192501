import { Button, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./GenerateApiKey.scss";
import apiService from "../../../../Services/api-service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../../Redux/actions";
import { useSnackbar } from "notistack";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const GenerateApiKey = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const [apiKeyList, setApiKeyList] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    createApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createApiKey = async () => {
    dispatch(loaderChange(true));
    apiService
      .post(`platform/generate_apikeys`, {})
      .then((res) => {
        dispatch(loaderChange(false));
        setApiKeyList(res.data.data);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const backBtn = () => {
    // navigate("/api-integration");
    navigate({
      pathname: '/api-integration',
      search: `?id=2`,
    });
  };

  const copyText = (containerId) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerId));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
    enqueueSnackbar("Copied !!", {
      // variant: "default",
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Slide,
      autoHideDuration: 1000,
    });
  };

  return (
    <div className="centered">
      <div>
        <div className="text-center">
          {/* <i className="fa-solid fa-circle-check"></i> */}
          <CheckCircleIcon sx={{color: 'green', width: '3rem', height: '3rem'}} />          
        </div>
        <div className="text-head text-center mt-2">
          Successfully created Client ID & Secret
        </div>
        <div className="small-text">
          These keys are specific to the your account API.
        </div>
        <div className="small-text">
          Do not share this out side your organization.
        </div>
        <div className="d-flex copy-div mt-4">
          <div className="client-secret-text mr-2">Client ID</div>
          <div className="copy-text mr-2" id='client_secret'>{apiKeyList.client_id}</div>
          <div>
            <Button variant="contained" onClick={() => copyText("client_secret")}>copy</Button>
          </div>
        </div>

        <div className="d-flex copy-div mt-2">
          <div className="client-secret-text mr-2">Secret Key</div>
          <div className="copy-text mr-2" id='api_key'>{apiKeyList.client_secret}</div>
          <div>
            <Button variant="contained" onClick={() => copyText("api_key")}>copy</Button>
          </div>
        </div>

        <div className="back-text text-center mt-2" onClick={backBtn}>
          Back to Integration
        </div>

        <div className="help-text text-center">* Once you hit back you can't get Secret Key again</div>
        
      </div>
    </div>
  );
};

export default GenerateApiKey;
