import {
    Backdrop,
    Button,
    Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Slide,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import "./Register.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { loaderChange } from "../../Redux/actions";
import { useDispatch } from "react-redux";
import apiService from "../../Services/api-service";
import { Box } from "@mui/system";
import * as countryList from "country-list";
import requestApi from "../../Services/restService";
import circleBG from "../../Assets/image/circleBG.webp";
const Register = () => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        // height: "calc(56vh - 1px)",
        maxHeight: "80%",
        boxShadow: 24,
        p: 4,
        borderRadius: "5px",
        overflow: "auto",
        width: "40%",
    };
    const [registerData, setRegisterData] = useState({
        tax_id: "",
        corporate_name: "",
        country: "",
        first_name: "",
        phone: "",
        last_name: "",
        email: "",
        designation: "",
        website: "",
        platform_type: "",
        emailOtp: "",
        mobileOtp: "",
        platform_category: "",
    });
    const [selectedCountryCode, setSelectedCountryCode] = useState("");
    const [emailVerify, setEmailVerify] = useState(false);
    const [mobileVerify, setMobileVerify] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [openMobileModal, setOpenMobileModal] = useState(false);
    const [afterSubmit, setAfterSubmit] = useState(false);
    const [termsCondition, setTermsCondition] = useState({
        termsConditionAgreement: false,
        termsOfUSe: false,
        privacyAndSecurity: false,
    });
    let [emailCount, setEmailCount] = useState(0);
    let [mobileCount, setMobileCount] = useState(0);
    const screenMatchWebMobile = useMediaQuery("(min-width:740px)");

    useEffect(() => {
        document.title = "Register";
        let i = setInterval(function () {
            if (document.querySelector(".flag")) {
                document
                    .querySelector(".flag")
                    .classList.add("remove-flag-class");
                clearInterval(i);
            }
        }, 10);
    }, []);

    const styles = (theme) => ({
        select: {
            "&:before": {
                borderColor: "white",
            },
            "&:after": {
                borderColor: "white",
            },
        },
        icon: {
            fill: "white",
        },
    });

    const handelAccount = (property, event) => {
        const accountCopy = { ...registerData };
        accountCopy[property] = event;
        setRegisterData(accountCopy);
    };

    const handleTermsCheckboxChange = (event) => {
        const accountCopy = { ...termsCondition };
        accountCopy[event.target.name] = event.target.value;
        setTermsCondition(accountCopy);
    };

    const validateEmail = (email) => {
        return email.match(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // const isValidHttpUrl = (s) => {
    //   let url;
    //   try {
    //     url = new URL(s);
    //   } catch (e) {
    //     return false;
    //   }
    //   return /https?/.test(url.protocol);
    // };

    const isValidHttpUrl = (urlString) => {
        var urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // validate fragment locator
        return !!urlPattern.test(urlString);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (
            !registerData.tax_id ||
            !registerData.corporate_name ||
            !registerData.country ||
            !registerData.first_name ||
            !registerData.phone ||
            !registerData.email ||
            !registerData.designation ||
            !registerData.website ||
            !emailVerify
        ) {
            return;
        }
        if (!isValidHttpUrl(registerData.website)) {
            enqueueSnackbar("Invalid Company URL", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                TransitionComponent: Slide,
                autoHideDuration: 2000,
            });
            return;
        }
        if (!validateEmail(registerData.email)) {
            enqueueSnackbar("Invalid Email", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                TransitionComponent: Slide,
                autoHideDuration: 2000,
            });
            return;
        }

        if (
            !termsCondition.privacyAndSecurity ||
            !termsCondition.termsConditionAgreement ||
            !termsCondition.termsOfUSe
        ) {
            enqueueSnackbar("Please check all checkbox", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                TransitionComponent: Slide,
                autoHideDuration: 2000,
            });
            return;
        }

        const apiPassData = {
            first_name: registerData.first_name,
            last_name: registerData.last_name,
            email: registerData.email,
            phone: "+" + registerData.phone,
            designation: registerData.designation,
            tax_id: registerData.tax_id,
            corporate_name: registerData.corporate_name,
            platform_type: "both",
            platform_category: "marketplace",
            country: registerData.country,
            website: registerData.website,
            // company_info: {
            //   tax_id: registerData.tax_id,
            //   corporate_name: registerData.corporate_name,
            //   platform_type: registerData.platform_type,
            //   address: {
            //     country: registerData.country,
            //   },
            // },
            // admin: {
            //   first_name: registerData.first_name,
            //   phone: registerData.phone,
            //   last_name: registerData.first_name,
            //   email: registerData.email,
            //   designation: registerData.designation,
            //   calling_code: registerData.calling_code,
            // },
            // website: registerData.website,
        };
        postData(apiPassData);
    };

    const postData = async (bodyParameters) => {
        dispatch(loaderChange(true));
        apiService
            .post(`platform/register`, bodyParameters)
            .then((res) => {
                enqueueSnackbar(res.data.message, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
                dispatch(loaderChange(false));
                // navigate("sign-in");
                setAfterSubmit(true);
            })
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
                dispatch(loaderChange(false));
            });
    };

    const phoneFieldChange = (value, data, event, formattedValue) => {
        const accountCopy = { ...registerData };
        accountCopy.phone = value;
        accountCopy.calling_code = data.dialCode;
        setRegisterData(accountCopy);
    };

    const handelCountryAccount = (event) => {
        handelAccount("country", event.target.value);
        setSelectedCountryCode(
            countryList.getCode(event.target.value).toLowerCase()
        );
    };

    const handelPlatformCategory = (event) => {
        handelAccount("platform_category", event.target.value);
    };

    const onEmailCloseModal = () => {
        setOpenEmailModal(false);
    };

    const onMobileCloseModal = () => {
        setOpenMobileModal(false);
    };

    const sendMobileOtp = () => {
        const apiPassData = {
            phone: `+${registerData.phone}`,
        };
        dispatch(loaderChange(true));
        apiService
            .post(`platform/send_sms`, apiPassData)
            .then((res) => {
                setOpenMobileModal(true);
                dispatch(loaderChange(false));
                mobileCount = 60;
                mobileCounter();
            })
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
                dispatch(loaderChange(false));
            });
    };

    const submitMobileOtp = () => {
        const apiPassData = {
            phone: `+${registerData.phone}`,
            otp: registerData.mobileOtp,
        };
        apiService
            .post(`platform/verify_sms`, apiPassData)
            .then((res) => {
                setMobileVerify(true);
                onMobileCloseModal();
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
                dispatch(loaderChange(false));
            });
    };

    const checkEmailExist = () => {
        const apiPassData = {
            email: registerData.email,
        };
        const formValue = { body: apiPassData };
        dispatch(loaderChange(true));
        const apiResult = requestApi("platform", "checkUserExist", formValue);
        apiResult.then(
            (resolveOutput) => {
                dispatch(loaderChange(false));
                sendEmailOtp();
            },
            (rejectOutput) => {
                dispatch(loaderChange(false));
                enqueueSnackbar(rejectOutput?.data?.data?.errorMessage, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    TransitionComponent: Slide,
                    autoHideDuration: 2000,
                });
            }
        );
    };

    const sendEmailOtp = () => {
        const apiPassData = {
            email: registerData.email,
        };
        dispatch(loaderChange(true));
        apiService
            .post(`platform/send_email`, apiPassData)
            .then((res) => {
                setOpenEmailModal(true);
                setSubmitted(false);
                dispatch(loaderChange(false));
                emailCount = 60;
                emailCounter();
            })
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
                dispatch(loaderChange(false));
            });
    };

    const submitEmailOtp = () => {
        const apiPassData = {
            email: registerData.email,
            otp: registerData.emailOtp,
        };
        apiService
            .post(`platform/verify_email`, apiPassData)
            .then((res) => {
                setEmailVerify(true);
                setSubmitted(false);
                onEmailCloseModal();
                dispatch(loaderChange(false));
            })
            .catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        TransitionComponent: Slide,
                        autoHideDuration: 2000,
                    });
                }
                dispatch(loaderChange(false));
            });
    };

    const emailCounter = () => {
        const handleSetInterval = setInterval(() => {
            setEmailCount(emailCount--);
            if (emailCount < 0) {
                clearInterval(handleSetInterval);
            }
        }, 1000);
    };

    const mobileCounter = () => {
        const handleSetInterval = setInterval(() => {
            setMobileCount(mobileCount--);
            if (mobileCount < 0) {
                clearInterval(handleSetInterval);
            }
        }, 1000);
    };

    const focusedColor = "#182269";
    const useStyles = makeStyles({
        root: {
            // input label when focused
            "& label.Mui-focused": {
                color: focusedColor,
            },
            // focused color for input with variant='standard'
            "& .MuiInput-underline:after": {
                borderBottomColor: focusedColor,
            },
            // focused color for input with variant='filled'
            "& .MuiFilledInput-underline:after": {
                borderBottomColor: focusedColor,
            },
            // focused color for input with variant='outlined'
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: focusedColor,
                },
            },
            label: {
                color: "red",
            },
        },
    });

    const classes = useStyles();

    const renderWebSwitch = () => {
        if (screenMatchWebMobile) {
            return (
                <>
                    <div className="text-center w-50 position-relative common-color">
                        <div className="first-div-alignment-center">
                            Proxtera, bringing the world to you
                        </div>
                        <div className="first-div-alignment-bottom">
                            © 2023 Proxtera. All rights reserved.
                        </div>
                        {/* <div className="right-img-background"></div> */}
                        {/* <img
              src={"https://sso.proxtera.app/assets/images/login-bg.png"}
              alt="logo"
              className="mr-2 w-100 left-img"
            />
            <img
              src={"https://sso.proxtera.app/assets/images/proxtera-white.png"}
              alt="logo"
              className="logo-1"
            />
            <img
              src={"https://sso.proxtera.app/assets/images/logo.png"}
              alt="logo"
              className="logo-2"
            /> */}
                        <img
                            src={circleBG}
                            alt="logo"
                            className="mr-2 w-100 "
                        />
                    </div>
                    {!afterSubmit ? (
                        <div className="form-div w-50">
                            <div>
                                <div className="f-22 right-div-header ">
                                    Start Your Journey With Proxtera
                                </div>
                                <div className="right-div-header ">
                                    All we need are a few details...
                                </div>
                                <div className="mt-3">
                                    {/* <FormControl
                fullWidth
                className="w-100"
                error={!registerData.platform_category && submitted}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "white" }}
                >
                  Are you a ?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={registerData.platform_category}
                  label="Are you ?"
                  sx={{
                    borderRadius: "5px",
                    color: "white",
                  }}
                  onChange={handelPlatformCategory}
                >
                  <MenuItem value={"buyer"}>Buyer</MenuItem>
                  <MenuItem value={"marketplace"}>Market place</MenuItem>
                </Select>
                {!registerData.platform_category && submitted ? (
                  <FormHelperText>Register as is required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl> */}

                                    <TextField
                                        sx={{
                                            width: "100%",
                                            //
                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company/Corporate Name"
                                        variant="outlined"
                                        onChange={(event) =>
                                            handelAccount(
                                                "corporate_name",
                                                event.target.value
                                            )
                                        }
                                        // className="mt-2"
                                        className={`${classes.root} mt-2`}
                                        helperText={
                                            !registerData.corporate_name &&
                                            submitted
                                                ? "Company/Corporate Name is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.corporate_name &&
                                            submitted
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    {/* <TextField
              sx={{
                width: "50%",

                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                style: { color: "white" },
              }}
              id="outlined-basic"
              label="Country of Registration"
              variant="outlined"
              className="mr-1"
              helperText={
                !registerData.country && submitted
                  ? "Country of Registration is required"
                  : ""
              }
              error={!registerData.country && submitted}
              onChange={(event) => handelAccount("country", event.target.value)}
            /> */}

                                    <FormControl
                                        className="w-50 mr-1"
                                        error={
                                            !registerData.country && submitted
                                        }
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            // sx={{ color: "white" }}
                                        >
                                            Country of Registration
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={registerData.country}
                                            label="Country of Registration"
                                            sx={{
                                                // width: "50%",

                                                borderRadius: "5px",
                                                // color: "white",
                                            }}
                                            className={classes.root}
                                            onChange={handelCountryAccount}
                                        >
                                            {countryList
                                                .getNames()
                                                .map((val, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={val}
                                                            key={index}
                                                        >
                                                            {val}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                        {!registerData.country && submitted ? (
                                            <FormHelperText>
                                                Country of Registration is
                                                required
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>

                                    <TextField
                                        sx={{
                                            width: "50%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company Tax ID"
                                        variant="outlined"
                                        // className="ml-1"
                                        className={`${classes.root} ml-1`}
                                        helperText={
                                            !registerData.tax_id && submitted
                                                ? "Company Tax ID is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.tax_id && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "tax_id",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 ">
                                    <TextField
                                        sx={{
                                            width: "100%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company URL"
                                        variant="outlined"
                                        className={`${classes.root}`}
                                        helperText={
                                            !registerData.website && submitted
                                                ? "Company URL is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.website && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "website",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    <TextField
                                        sx={{
                                            width: "50%",
                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="First name"
                                        variant="outlined"
                                        className={`${classes.root} mr-1`}
                                        helperText={
                                            !registerData.first_name &&
                                            submitted
                                                ? "Contact Person is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.first_name &&
                                            submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "first_name",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <TextField
                                        sx={{
                                            width: "50%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Last name"
                                        variant="outlined"
                                        className={`${classes.root} ml-1`}
                                        helperText={
                                            !registerData.last_name && submitted
                                                ? "Last name is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.last_name && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "last_name",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>

                                <div className="mt-4">
                                    {/* <TextField
                sx={{
                  width: "50%",

                  borderRadius: "5px",
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                className="mr-1"
                helperText={
                  !registerData.first_name && submitted
                    ? "Contact Person is required"
                    : ""
                }
                error={!registerData.first_name && submitted}
                onChange={(event) =>
                  handelAccount("first_name", event.target.value)
                }
              /> */}
                                    <TextField
                                        sx={{
                                            width: "100%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Designation"
                                        variant="outlined"
                                        className={`${classes.root}`}
                                        helperText={
                                            !registerData.designation &&
                                            submitted
                                                ? "Designation is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.designation &&
                                            submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "designation",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    <div className="w-100 mr-1 position-relative">
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                borderRadius: "5px",
                                            }}
                                            InputLabelProps={
                                                {
                                                    // style: { color: "white" },
                                                }
                                            }
                                            InputProps={{
                                                style: {
                                                    paddingRight: "4.5rem",
                                                },
                                            }}
                                            id="outlined-basic"
                                            label="Contact Email"
                                            variant="outlined"
                                            className={`${classes.root}`}
                                            helperText={
                                                !registerData.email && submitted
                                                    ? "Email is required"
                                                    : ""
                                            }
                                            error={
                                                !registerData.email && submitted
                                            }
                                            onChange={(event) =>
                                                handelAccount(
                                                    "email",
                                                    event.target.value
                                                )
                                            }
                                        />
                                        {!emailVerify &&
                                            submitted &&
                                            registerData.email && (
                                                <FormHelperText
                                                    error="true"
                                                    className="ml-3"
                                                >
                                                    <span className="cursor-pointer">
                                                        Email not verified
                                                    </span>
                                                </FormHelperText>
                                            )}

                                        {validateEmail(registerData.email) &&
                                            !emailVerify && (
                                                <>
                                                    {emailCount > 0 ? (
                                                        <FormHelperText className="ml-3">
                                                            <span>
                                                                Please wait for{" "}
                                                                {emailCount}{" "}
                                                                second to resend
                                                                Email
                                                            </span>
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                        // <FormHelperText sx={{ color: "white" }}>
                                                        //   <span
                                                        //     className="cursor-pointer"
                                                        //     onClick={checkEmailExist}
                                                        //   >
                                                        //     Enter email OTP
                                                        //   </span>
                                                        // </FormHelperText>
                                                    )}
                                                </>
                                            )}

                                        {/* <FormHelperText sx={{ color: "green" }}>
                  {verifyEmailText()}
                </FormHelperText> */}
                                        <div className="">
                                            {emailVerify ? (
                                                <>
                                                    <Button
                                                        className="verify-btn"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            background: "green",
                                                        }}
                                                    >
                                                        Verified
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {validateEmail(
                                                        registerData.email
                                                    ) &&
                                                        emailCount <= 0 && (
                                                            <>
                                                                <Button
                                                                    className="verify-btn"
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={
                                                                        checkEmailExist
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "gray",
                                                                    }}
                                                                >
                                                                    Verify
                                                                </Button>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="w-100 position-relative">
                                        <PhoneInput
                                            country={selectedCountryCode}
                                            className={`${classes.root} w-99 ml-1`}
                                            value={registerData.phone}
                                            // isValid={!registerData.phone && submitted ? false : true}
                                            // onChange={(event) => handelAccount("phone", event)}
                                            onChange={phoneFieldChange}
                                            countryCodeEditable={true}
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                                // autoFocus: true,
                                            }}
                                        />
                                        {/* {!mobileVerify && submitted && registerData.phone && (
                  <FormHelperText error="true" className="ml-3">
                    <span className="cursor-pointer" color="error">
                      Phone not verified
                    </span>
                  </FormHelperText>
                )} */}
                                        {registerData.phone.length > 8 &&
                                            !mobileVerify && (
                                                <>
                                                    {mobileCount > 0 ? (
                                                        <FormHelperText className="ml-3">
                                                            <span>
                                                                Please wait for{" "}
                                                                {mobileCount}{" "}
                                                                second to resend
                                                                SMS
                                                            </span>
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                        // <FormHelperText sx={{ color: "white" }}>
                                                        //   <span
                                                        //     className="cursor-pointer"
                                                        //     onClick={sendMobileOtp}
                                                        //   >
                                                        //     Enter Mobile OTP
                                                        //   </span>
                                                        // </FormHelperText>
                                                    )}
                                                </>
                                            )}
                                        {/* <FormHelperText sx={{ color: "green" }}>
                  {verifyMobileText()}
                </FormHelperText> */}
                                        <div className="">
                                            {mobileVerify ? (
                                                <>
                                                    <Button
                                                        className="verify-btn"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            background: "green",
                                                        }}
                                                    >
                                                        Verified
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {registerData.phone.length >
                                                        8 &&
                                                        mobileCount <= 0 && (
                                                            <>
                                                                <Button
                                                                    className="verify-btn"
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={
                                                                        sendMobileOtp
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "gray",
                                                                    }}
                                                                >
                                                                    Verify
                                                                </Button>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>

                                        {!registerData.phone && submitted ? (
                                            <span className="phone-error-msg">
                                                Phone number is required
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {/* {registerData.platform_category !== "buyer" && (
              <div className="w-100 mt-3">
                <FormControl
                  fullWidth
                  className="w-100"
                  error={!registerData.platform_type && submitted}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ color: "white" }}
                  >
                    Register as
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={registerData.platform_type}
                    label="Register as"
                    sx={{
                      // width: "50%",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    // onChange={(event) => handelCountryAccount(event)}
                    onChange={(event) =>
                      handelAccount("platform_type", event.target.value)
                    }
                  >
                    <MenuItem value={"buyer"}>Buyer</MenuItem>
                    <MenuItem
                      value={"seller"}
                      disabled={
                        registerData.platform_category === "buyer"
                          ? true
                          : false
                      }
                    >
                      Seller
                    </MenuItem>
                    <MenuItem
                      value={"both"}
                      disabled={
                        registerData.platform_category === "buyer"
                          ? true
                          : false
                      }
                    >
                      Both
                    </MenuItem>
                  </Select>
                  {!registerData.platform_type && submitted ? (
                    <FormHelperText>Register as is required</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            )} */}

                                <div className="mt-4">
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    sx={{ color: "#d3d3d3" }}
                                                    checked={
                                                        termsCondition?.termsConditionAgreement
                                                    }
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "termsConditionAgreement",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree the Terms and Condition in Proxtera's Commercial Agreement "
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to the &nbsp;
                                                    <a
                                                        href="/#"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                    <a
                                                        href="https://proxtera.com/terms-and-conditions/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Terms and Conditions
                                                    </a>
                                                        &nbsp;
                                                    </a>
                                                    in Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/commercial-agreement/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Commercial Agreement
                                                    </a>
                                                    {/* Commercial Agreement */}
                                                </span>
                                            }
                                        />
                                        {!termsCondition.termsConditionAgreement &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Terms
                                                    and Conditions & Commercial
                                                    Agreement
                                                </FormHelperText>
                                            )}
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        termsCondition?.termsOfUSe
                                                    }
                                                    sx={{ color: "#d3d3d3" }}
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "termsOfUSe",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree to Proxtera's Terms of Use"
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/termsofuse/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Terms of Use
                                                    </a>
                                                </span>
                                            }
                                        />
                                        {!termsCondition.termsOfUSe &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Terms of
                                                    Use
                                                </FormHelperText>
                                            )}
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    sx={{ color: "#d3d3d3" }}
                                                    // labelStyle={{color: 'white'}}
                                                    checked={
                                                        termsCondition?.privacyAndSecurity
                                                    }
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "privacyAndSecurity",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree to Proxtera's Privacy and Security Policies"
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/privacy-policy/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Privacy and Security
                                                        Policies
                                                    </a>
                                                </span>
                                            }
                                        />
                                        {!termsCondition.privacyAndSecurity &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Privacy
                                                    and Security Policies
                                                </FormHelperText>
                                            )}
                                    </FormGroup>
                                </div>
                                <div className="mt-3">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: "100%",
                                            padding: "13px 16px",
                                            backgroundColor: "#182269",
                                        }}
                                        onClick={handleSubmit}
                                        // disabled={mobileVerify && emailVerify ? false : true}
                                    >
                                        Join Proxtera
                                    </Button>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <span className="help-text-custom">
                                        *Please check all boxes to proceed
                                    </span>
                                    <span
                                        className="help-text-custom cursor-pointer"
                                        onClick={() => {
                                            navigate("/sign-in");
                                        }}
                                    >
                                        Back to sign in
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-50 after-register right-div-header">
                            <div>
                                <h2>Thank you signing up! </h2>
                                <h3 className="mt-2">
                                    You’ve taken the first step to join the
                                    Proxtera ecosystem. We will send you an
                                    email within the next week with the next
                                    steps.
                                </h3>
                                <Button
                                    variant="contained"
                                    className="mt-3"
                                    sx={{ width: "100%" }}
                                    onClick={() => navigate("/sign-in")}
                                >
                                    Back to homepage
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            );
        }
    };

    const renderMobileSwitch = () => {
        if (!screenMatchWebMobile) {
            return (
                <>
                    {!afterSubmit ? (
                        <div className="form-div">
                            <div>
                                <div className="f-22 right-div-header ">
                                    Start Your Journey With Proxtera
                                </div>
                                <div className="right-div-header ">
                                    All we need are a few details...
                                </div>
                                <div className="mt-3">
                                    {/* <FormControl
                fullWidth
                className="w-100"
                error={!registerData.platform_category && submitted}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "white" }}
                >
                  Are you a ?
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={registerData.platform_category}
                  label="Are you ?"
                  sx={{
                    borderRadius: "5px",
                    color: "white",
                  }}
                  onChange={handelPlatformCategory}
                >
                  <MenuItem value={"buyer"}>Buyer</MenuItem>
                  <MenuItem value={"marketplace"}>Market place</MenuItem>
                </Select>
                {!registerData.platform_category && submitted ? (
                  <FormHelperText>Register as is required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl> */}

                                    <TextField
                                        sx={{
                                            width: "100%",
                                            //
                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company/Corporate Name"
                                        variant="outlined"
                                        onChange={(event) =>
                                            handelAccount(
                                                "corporate_name",
                                                event.target.value
                                            )
                                        }
                                        // className="mt-2"
                                        className={`${classes.root} mt-2`}
                                        helperText={
                                            !registerData.corporate_name &&
                                            submitted
                                                ? "Company/Corporate Name is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.corporate_name &&
                                            submitted
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    {/* <TextField
              sx={{
                width: "50%",

                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                style: { color: "white" },
              }}
              id="outlined-basic"
              label="Country of Registration"
              variant="outlined"
              className="mr-1"
              helperText={
                !registerData.country && submitted
                  ? "Country of Registration is required"
                  : ""
              }
              error={!registerData.country && submitted}
              onChange={(event) => handelAccount("country", event.target.value)}
            /> */}

                                    <FormControl
                                        className="w-50 mr-1"
                                        error={
                                            !registerData.country && submitted
                                        }
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            // sx={{ color: "white" }}
                                        >
                                            Country of Registration
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={registerData.country}
                                            label="Country of Registration"
                                            sx={{
                                                // width: "50%",

                                                borderRadius: "5px",
                                                // color: "white",
                                            }}
                                            className={classes.root}
                                            onChange={handelCountryAccount}
                                        >
                                            {countryList
                                                .getNames()
                                                .map((val, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={val}
                                                            key={index}
                                                        >
                                                            {val}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                        {!registerData.country && submitted ? (
                                            <FormHelperText>
                                                Country of Registration is
                                                required
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>

                                    <TextField
                                        sx={{
                                            width: "50%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company Tax ID"
                                        variant="outlined"
                                        // className="ml-1"
                                        className={`${classes.root} ml-1`}
                                        helperText={
                                            !registerData.tax_id && submitted
                                                ? "Company Tax ID is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.tax_id && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "tax_id",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 ">
                                    <TextField
                                        sx={{
                                            width: "100%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Company URL"
                                        variant="outlined"
                                        className={`${classes.root}`}
                                        helperText={
                                            !registerData.website && submitted
                                                ? "Company URL is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.website && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "website",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    <TextField
                                        sx={{
                                            width: "50%",
                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="First name"
                                        variant="outlined"
                                        className={`${classes.root} mr-1`}
                                        helperText={
                                            !registerData.first_name &&
                                            submitted
                                                ? "Contact Person is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.first_name &&
                                            submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "first_name",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <TextField
                                        sx={{
                                            width: "50%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Last name"
                                        variant="outlined"
                                        className={`${classes.root} ml-1`}
                                        helperText={
                                            !registerData.last_name && submitted
                                                ? "Last name is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.last_name && submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "last_name",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>

                                <div className="mt-4">
                                    {/* <TextField
                sx={{
                  width: "50%",

                  borderRadius: "5px",
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                className="mr-1"
                helperText={
                  !registerData.first_name && submitted
                    ? "Contact Person is required"
                    : ""
                }
                error={!registerData.first_name && submitted}
                onChange={(event) =>
                  handelAccount("first_name", event.target.value)
                }
              /> */}
                                    <TextField
                                        sx={{
                                            width: "100%",

                                            borderRadius: "5px",
                                        }}
                                        InputLabelProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        InputProps={
                                            {
                                                // style: { color: "white" },
                                            }
                                        }
                                        id="outlined-basic"
                                        label="Designation"
                                        variant="outlined"
                                        className={`${classes.root}`}
                                        helperText={
                                            !registerData.designation &&
                                            submitted
                                                ? "Designation is required"
                                                : ""
                                        }
                                        error={
                                            !registerData.designation &&
                                            submitted
                                        }
                                        onChange={(event) =>
                                            handelAccount(
                                                "designation",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-4 d-flex">
                                    <div className="w-100 mr-1 position-relative">
                                        <TextField
                                            sx={{
                                                width: "100%",
                                                borderRadius: "5px",
                                            }}
                                            InputLabelProps={
                                                {
                                                    // style: { color: "white" },
                                                }
                                            }
                                            InputProps={{
                                                style: {
                                                    paddingRight: "4.5rem",
                                                },
                                            }}
                                            id="outlined-basic"
                                            label="Contact Email"
                                            variant="outlined"
                                            className={`${classes.root}`}
                                            helperText={
                                                !registerData.email && submitted
                                                    ? "Email is required"
                                                    : ""
                                            }
                                            error={
                                                !registerData.email && submitted
                                            }
                                            onChange={(event) =>
                                                handelAccount(
                                                    "email",
                                                    event.target.value
                                                )
                                            }
                                        />
                                        {!emailVerify &&
                                            submitted &&
                                            registerData.email && (
                                                <FormHelperText
                                                    error="true"
                                                    className="ml-3"
                                                >
                                                    <span className="cursor-pointer">
                                                        Email not verified
                                                    </span>
                                                </FormHelperText>
                                            )}

                                        {validateEmail(registerData.email) &&
                                            !emailVerify && (
                                                <>
                                                    {emailCount > 0 ? (
                                                        <FormHelperText
                                                            sx={{
                                                                color: "white",
                                                            }}
                                                        >
                                                            <span>
                                                                Please wait for{" "}
                                                                {emailCount}{" "}
                                                                second to resend
                                                                Email
                                                            </span>
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                        // <FormHelperText sx={{ color: "white" }}>
                                                        //   <span
                                                        //     className="cursor-pointer"
                                                        //     onClick={checkEmailExist}
                                                        //   >
                                                        //     Enter email OTP
                                                        //   </span>
                                                        // </FormHelperText>
                                                    )}
                                                </>
                                            )}

                                        {/* <FormHelperText sx={{ color: "green" }}>
                  {verifyEmailText()}
                </FormHelperText> */}
                                        <div className="">
                                            {emailVerify ? (
                                                <>
                                                    <Button
                                                        className="verify-btn"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            background: "green",
                                                        }}
                                                    >
                                                        Verified
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {validateEmail(
                                                        registerData.email
                                                    ) &&
                                                        emailCount <= 0 && (
                                                            <>
                                                                <Button
                                                                    className="verify-btn"
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={
                                                                        checkEmailExist
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "gray",
                                                                    }}
                                                                >
                                                                    Verify
                                                                </Button>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="w-100 position-relative">
                                        <PhoneInput
                                            country={selectedCountryCode}
                                            className={`${classes.root} w-99 ml-1`}
                                            value={registerData.phone}
                                            // isValid={!registerData.phone && submitted ? false : true}
                                            // onChange={(event) => handelAccount("phone", event)}
                                            onChange={phoneFieldChange}
                                            countryCodeEditable={false}
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                                // autoFocus: true,
                                            }}
                                        />
                                        {/* {!mobileVerify && submitted && registerData.phone && (
                  <FormHelperText error="true" className="ml-3">
                    <span className="cursor-pointer" color="error">
                      Phone not verified
                    </span>
                  </FormHelperText>
                )} */}
                                        {registerData.phone.length > 8 &&
                                            !mobileVerify && (
                                                <>
                                                    {mobileCount > 0 ? (
                                                        <FormHelperText
                                                            sx={{
                                                                color: "white",
                                                            }}
                                                        >
                                                            <span>
                                                                Please wait for{" "}
                                                                {mobileCount}{" "}
                                                                second to resend
                                                                SMS
                                                            </span>
                                                        </FormHelperText>
                                                    ) : (
                                                        ""
                                                        // <FormHelperText sx={{ color: "white" }}>
                                                        //   <span
                                                        //     className="cursor-pointer"
                                                        //     onClick={sendMobileOtp}
                                                        //   >
                                                        //     Enter Mobile OTP
                                                        //   </span>
                                                        // </FormHelperText>
                                                    )}
                                                </>
                                            )}
                                        {/* <FormHelperText sx={{ color: "green" }}>
                  {verifyMobileText()}
                </FormHelperText> */}
                                        <div className="">
                                            {mobileVerify ? (
                                                <>
                                                    <Button
                                                        className="verify-btn"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            background: "green",
                                                        }}
                                                    >
                                                        Verified
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {registerData.phone.length >
                                                        8 &&
                                                        mobileCount <= 0 && (
                                                            <>
                                                                <Button
                                                                    className="verify-btn"
                                                                    size="small"
                                                                    variant="contained"
                                                                    onClick={
                                                                        sendMobileOtp
                                                                    }
                                                                    sx={{
                                                                        background:
                                                                            "gray",
                                                                    }}
                                                                >
                                                                    Verify
                                                                </Button>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>

                                        {!registerData.phone && submitted ? (
                                            <span className="phone-error-msg">
                                                Phone number is required
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {/* {registerData.platform_category !== "buyer" && (
              <div className="w-100 mt-3">
                <FormControl
                  fullWidth
                  className="w-100"
                  error={!registerData.platform_type && submitted}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ color: "white" }}
                  >
                    Register as
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={registerData.platform_type}
                    label="Register as"
                    sx={{
                      // width: "50%",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    // onChange={(event) => handelCountryAccount(event)}
                    onChange={(event) =>
                      handelAccount("platform_type", event.target.value)
                    }
                  >
                    <MenuItem value={"buyer"}>Buyer</MenuItem>
                    <MenuItem
                      value={"seller"}
                      disabled={
                        registerData.platform_category === "buyer"
                          ? true
                          : false
                      }
                    >
                      Seller
                    </MenuItem>
                    <MenuItem
                      value={"both"}
                      disabled={
                        registerData.platform_category === "buyer"
                          ? true
                          : false
                      }
                    >
                      Both
                    </MenuItem>
                  </Select>
                  {!registerData.platform_type && submitted ? (
                    <FormHelperText>Register as is required</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
            )} */}

                                <div className="mt-4">
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    sx={{ color: "#d3d3d3" }}
                                                    checked={
                                                        termsCondition?.termsConditionAgreement
                                                    }
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "termsConditionAgreement",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree the Terms and Condition in Proxtera's Commercial Agreement "
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to the &nbsp;
                                                    <a
                                                        href="/#"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Terms and Conditions
                                                        &nbsp;
                                                    </a>
                                                    in Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/commercial-agreement/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Commercial Agreement
                                                    </a>
                                                    {/* Commercial Agreement */}
                                                </span>
                                            }
                                        />
                                        {!termsCondition.termsConditionAgreement &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Terms
                                                    and Conditions & Commercial
                                                    Agreement
                                                </FormHelperText>
                                            )}
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        termsCondition?.termsOfUSe
                                                    }
                                                    sx={{ color: "#d3d3d3" }}
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "termsOfUSe",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree to Proxtera's Terms of Use"
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/termsofuse/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Terms of Use
                                                    </a>
                                                </span>
                                            }
                                        />
                                        {!termsCondition.termsOfUSe &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Terms of
                                                    Use
                                                </FormHelperText>
                                            )}
                                        <FormControlLabel
                                            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                                            control={
                                                <Checkbox
                                                    sx={{ color: "#d3d3d3" }}
                                                    // labelStyle={{color: 'white'}}
                                                    checked={
                                                        termsCondition?.privacyAndSecurity
                                                    }
                                                    onChange={(e) => {
                                                        handleTermsCheckboxChange(
                                                            {
                                                                target: {
                                                                    name: "privacyAndSecurity",
                                                                    value: e
                                                                        .target
                                                                        .checked,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                />
                                            }
                                            // label="By checking the box, I agree to Proxtera's Privacy and Security Policies"
                                            label={
                                                <span>
                                                    By checking the box, I agree
                                                    to Proxtera's &nbsp;
                                                    <a
                                                        href="https://proxtera.com/privacy-policy/"
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        Privacy and Security
                                                        Policies
                                                    </a>
                                                </span>
                                            }
                                        />
                                        {!termsCondition.privacyAndSecurity &&
                                            submitted && (
                                                <FormHelperText
                                                    error={true}
                                                    className="ml-2"
                                                >
                                                    Please agree to the Privacy
                                                    and Security Policies
                                                </FormHelperText>
                                            )}
                                    </FormGroup>
                                </div>
                                <div className="mt-3">
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100%" }}
                                        onClick={handleSubmit}
                                        // disabled={mobileVerify && emailVerify ? false : true}
                                    >
                                        Join Proxtera
                                    </Button>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <span className="help-text-custom">
                                        *Please check all boxes to proceed
                                    </span>
                                    <span
                                        className="help-text-custom cursor-pointer"
                                        onClick={() => {
                                            navigate("/sign-in");
                                        }}
                                    >
                                        Back to sign in
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="after-register right-div-header">
                            <div>
                                <h2>Thank you signing up! </h2>
                                <h3 className="mt-2">
                                    You’ve taken the first step to join the
                                    Proxtera ecosystem. We will send you an
                                    email within the next week with the next
                                    steps.
                                </h3>
                                <Button
                                    variant="contained"
                                    className="mt-3"
                                    sx={{ width: "100%" }}
                                    onClick={() => navigate("/sign-in")}
                                >
                                    Back to homepage
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            );
        }
    };

    return (
        <div className="d-flex justify-content-between w-100 main-div register-page">
            {renderWebSwitch()}
            {renderMobileSwitch()}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openEmailModal}
                onClose={onEmailCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openEmailModal}>
                    <Box sx={style} id="style-1">
                        <div>
                            <h3 className="text-center modal-header-custom">
                                Enter Email OTP
                            </h3>
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                    }}
                                    label="OTP"
                                    value={registerData.emailOtp}
                                    className={`${classes.root} mt-3`}
                                    variant="outlined"
                                    onChange={(event) =>
                                        handelAccount(
                                            "emailOtp",
                                            event.target.value
                                        )
                                    }
                                    helperText={
                                        !registerData.emailOtp && submitted
                                            ? "Email otp is required"
                                            : ""
                                    }
                                    error={!registerData.emailOtp && submitted}
                                />

                                <div className="d-flex justify-content-end w-100 mt-3 mb-3">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        // sx={{ width: "100%" }}
                                        className="mr-2"
                                        onClick={onEmailCloseModal}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        variant="contained"
                                        // sx={{ width: "100%" }}
                                        className="ml-2"
                                        // endIcon={<SendIcon />}
                                        onClick={submitEmailOtp}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openMobileModal}
                onClose={onMobileCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openMobileModal}>
                    <Box sx={style} id="style-1">
                        <div>
                            <h3 className="text-center modal-header-custom">
                                Enter Mobile OTP
                            </h3>
                            <div className="mt-3">
                                <TextField
                                    sx={{
                                        width: "100%",
                                    }}
                                    label="OTP"
                                    value={registerData.mobileOtp}
                                    className={`${classes.root} mt-3`}
                                    variant="outlined"
                                    onChange={(event) =>
                                        handelAccount(
                                            "mobileOtp",
                                            event.target.value
                                        )
                                    }
                                    helperText={
                                        !registerData.emailOtp && submitted
                                            ? "Phone is required"
                                            : ""
                                    }
                                    error={!registerData.emailOtp && submitted}
                                />

                                <div className="d-flex justify-content-end w-100 mt-3 mb-3">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        // sx={{ width: "100%" }}
                                        className="mr-2"
                                        onClick={onMobileCloseModal}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        variant="contained"
                                        // sx={{ width: "100%" }}
                                        className="ml-2"
                                        // endIcon={<SendIcon />}
                                        onClick={submitMobileOtp}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                    {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
                </Fade>
            </Modal>
        </div>
    );
};

export default Register;
