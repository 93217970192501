import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  IconButton,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import apiService from "../../../Services/api-service";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import FileSaver from "file-saver";
import XLSX from "xlsx";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property, sort) => (event) => {
    if(sort) {
      onRequestSort(event, property);
    }    
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell.isVisible && (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={"normal"}
                size={"medium"}
                sx={{ fontSize: "18px", fontWeight: 500 }}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={headCell.sort ? orderBy === headCell.id : false}
                  hideSortIcon={true}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id, headCell.sort)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    title,
    handleCSVChange,
    handelSearchEvent,
    rows,
    exportToCSV,
  } = props;
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const inputFile = useRef(null);

  const openFileUploadCsv = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  //   const handleCSVChange = (event) => {
  //     console.log("event.target.files[0]", event.target.files[0]);
  //     const csvFile = event.target.files[0];
  //     // Papa.parse(csvFile, {
  //     //   worker: true,
  //     //   step: function(results) {
  //     //     console.log("Row:", results.data);
  //     //   }
  //     // });
  //     const deFaultHeader = ['year', 'industry_code_ANZSIC', 'industry_name_ANZSIC', 'rme_size_grp', 'variable', 'value', 'unit'];

  //     Papa.parse(csvFile, {
  //       complete: function(results) {
  //         console.log("Finished:", results.data);
  //         console.log('arraysEqual', arraysEqual(deFaultHeader, results.data[0]))
  //       }
  //     });
  //     event.target.value = null
  //   };

  //   const arraysEqual = (a1,a2) => {
  //     /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  //     return JSON.stringify(a1) === JSON.stringify(a2);
  // }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
      className="pl-0 mb-2"
    >
      <div className="d-flex align-items-center">
        <Typography
          sx={{ fontWeight: 700, fontSize: 20 }}
          variant="h6"
          id="tableTitle"
          component="div"
          display="flex"
          justifyContent="flex-start"
        >
          {title}
        </Typography>
        {rows.length > 0 && (
          <TextField
            label="Search"
            id="outlined-size-small"
            size="small"
            className="ml-3"
            onChange={(event) => handelSearchEvent(event.target.value)}
          />
        )}
      </div>
      {/* <TextField id="outlined-basic" label={title} variant="outlined" /> */}
      <div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          accept=".csv"
          onChange={handleCSVChange}
        />

        {(userDetails?.roles?.indexOf('ORG_ADMIN') !== -1) && (
          <>
            {/* <Tooltip title="Download csv">
            <Button sx={{ width: "300px" }} variant="contained">
              Download csv
            </Button>
          </Tooltip> */}

            <Tooltip title="Upload CSV">
              <Button
                onClick={openFileUploadCsv}
                // sx={{ width: "300px" }}
                className="ml-3"
                variant="contained"
              >
                Upload CSV
              </Button>
            </Tooltip>

            <Tooltip title="Get CSV sample">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.google.com/spreadsheets/d/1VIssEp_gKI_pWholgDqh9J3VXUZ9FYsusBeImLxdCjQ/edit?usp=sharing"
              >
                <Button
                  // onClick={openFileUploadCsv}
                  // sx={{ width: "300px" }}
                  className="ml-3"
                  variant="contained"
                >
                  Get CSV sample
                </Button>
              </a>
            </Tooltip>
          </>
        )}
        {rows.length > 0 && (
          <Tooltip title="Download CSV">
            <IconButton onClick={() => exportToCSV(rows, title)}>
              <DownloadIcon className="ml-2" />
            </IconButton>
          </Tooltip>
        )}
      </div>

      {/* {rows.length > 0 ? (
          <div>
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload">
              <IconButton>
                <UploadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton onClick={() => exportToCSV(rows, title)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          ""
        )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SMEListTable = ({ headCells = [], title }) => {
  const [order, setOrder] = React.useState("asc");
  const [rows, setRows] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [noDataText, setNoDataText] = useState("Loading ....");
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));
  let searchValue = "";
  let queryParams = {
    rowsPerPage: 10,
    page: 0,
  };
  const [totalData, setTotalData] = useState(0);
  const fileExtension = ".xlsx";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  useEffect(() => {
    getSMEList();
  }, []);

  const exportToCSV = (csvData, fileName) => {
    const dataCsv = [
      ["SME ID", "Email address", "Business name", "Onboard date"],
    ];
    csvData.filter((val, index) => {
      dataCsv.push([
        val.sme_id,
        val.email,
        val.name,
        moment(new Date(val.created)).format("DD/MM/YYYY HH:mm:ss"),
      ]);
    });
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fileTypeCheck = (image) => {
    let image_type_allowed = ["csv"];
    var new_upload_ext = image.name.split(".").pop();
    return image_type_allowed.includes(new_upload_ext);
  };

  const handleCSVChange = (event) => {
    if (!fileTypeCheck(event.target.files[0])) {
      // alert('Please upload valid csv file only.')
      enqueueSnackbar("Please upload valid csv file only.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    const csvFile = event.target.files[0];
    // Papa.parse(csvFile, {
    //   worker: true,
    //   step: function(results) {
    //   }
    // });
    const defaultHeader = [
      "SME ID",
      "SME Name",
      "Seller (True/ False)",
      "Buyer (True/ False)",
      "Email",
      "Phone",
      "Currency",
      "Language",
      "Registration Authority",
      "Year Founded",
      "Website",
      "Tax ID",
      "Address",
    ];

    Papa.parse(csvFile, {
      complete: function (results) {
        if (!arraysEqual(defaultHeader, results.data[0])) {
          enqueueSnackbar("Invalid CSV file", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
          return;
        }
        uploadSMEapi(csvFile);
      },
    });

    event.target.value = null;
  };

  const uploadSMEapi = async (csvFile) => {
    dispatch(loaderChange(true));
    const data = new FormData();
    data.append("file", csvFile);
    data.append("remove_existing", true);
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .post(`smes/bulk_upload`, data, config)
      .then((response) => {
        dispatch(loaderChange(false));
        // setRows(response.data.data ? response.data.data : []);
        getSMEList();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const arraysEqual = (a1, a2) => {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    queryParams.sortOrder = isAsc ? "dsc" : "asc";
    queryParams.sortBy = property;
    getSMEList()
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // var CancelToken = axios.CancelToken;
  // var cancel;

  const getSMEList = async () => {
    // if (cancel != undefined) {
    //   cancel();
    // }
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      // cancelToken: new CancelToken(function executor(c) {
      //   // An executor function receives a cancel function as a parameter
      //   cancel = c;
      // })
    };
    let apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&search=${searchValue}`
    if(queryParams.sortBy) {
      apiQueryParam = `page=${queryParams.page + 1}&size=${queryParams.rowsPerPage}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${searchValue}`
    }

    apiService
      .get(
        `smes?${apiQueryParam}`,
        config
      )
      .then((response) => {
        dispatch(loaderChange(false));
        setRows(response.data?.data?.data);
        dataCheck();
        setTotalData(response.data?.data?.total);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    queryParams.rowsPerPage = parseInt(event.target.value);
    // queryParams.page = parseInt(page);
    setPage(0);
    getSMEList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(parseInt(rowsPerPage));
    queryParams.page = parseInt(newPage);
    queryParams.rowsPerPage = parseInt(rowsPerPage);
    getSMEList();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const dataCheck = () => {
    if (rows.length === 0) {
      setNoDataText("No results found");
    }
  };

  const actionIcons = (row) => {
    return (
      <div className="action-icon">
        {row.active === true ? (
          <span>
            <Tooltip title="Disable">
              <i className="fa fa-ban cursor-pointer" aria-hidden="true"></i>
            </Tooltip>
            <Tooltip title="Enable">
              {/* <i
                  className="fa fa-check-circle ml-2 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => enableUser(row)}
                ></i> */}
            </Tooltip>
          </span>
        ) : (
          <span>
            <Tooltip title="Delete">
              <i
                className="fa fa-trash-o mr-2 cursor-pointer"
                aria-hidden="true"
              ></i>
            </Tooltip>
            <Tooltip title="Enable">
              <i
                className="fa fa-check-circle ml-2 cursor-pointer"
                aria-hidden="true"
              ></i>
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  const handelSearchEvent = (event) => {
    searchValue = event;
    getSMEList();
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 0,
      }}
    >
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={title}
        handleCSVChange={handleCSVChange}
        handelSearchEvent={handelSearchEvent}
        rows={rows}
        exportToCSV={exportToCSV}
      />
      {rows.length > 0 ? (
        <TableContainer sx={{ boxShadow: 2, borderRadius: 2 }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={index}>
                    <TableCell align="left" padding={"normal"}>
                      {row.sme_id}
                    </TableCell>
                    {userDetails?.roles?.indexOf('GLOBAL_ADMIN') !== -1 && (
                      <TableCell align="left" padding={"normal"}>
                        {row.platformDetail?.company_info.corporate_name}
                      </TableCell>
                    )}
                    <TableCell align="center" padding={"normal"}>
                      {row.email}
                    </TableCell>
                    <TableCell align="center" padding={"normal"}>
                      {row.name}
                    </TableCell>
                    <TableCell align="center" padding={"normal"}>
                      {moment(new Date(row.created)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="center-no-data">{noDataText}</div>
      )}

      {rows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
    </Paper>
  );
};

export default SMEListTable;
