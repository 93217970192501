import { Box } from "@mui/material";
import React, { useEffect } from "react";
import UATTable from "./UATTable";
import "./UATProgress.scss";

const UATList = () => {
  const headCells = [
    {
      id: "corporate_name",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Company name",
      sort: true,
    },
    {
      id: "category",
      align: "left",
      numeric: false,
      disablePadding: false,
      label: "Category",
      sort: true,
    },
    {
      id: "api_url",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "API URL",
      sort: false,
    },
    {
      id: "status",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Status",
      sort: true,
    },
    {
      id: "action",
      align: "center",
      numeric: true,
      disablePadding: false,
      label: "Action",
      sort: false,
    },
  ];

  useEffect(() => {
    document.title = "UAT Approval";
    // getUATlist();
    // const apiResponse = getService(`members/${userDetails.platform_id}`);

    // testApiFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="uat-list">
      <Box sx={{ width: "100%", height: "100%" }}>
        <UATTable
          headCells={headCells}
          title={"UAT Approval"}
        />
      </Box>
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openRejectModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRejectModal}>
          <Box sx={style} id="style-1">
            <UATRejectModal
              onCloseModal={onCloseModal}
              handleSubmit={modalSubmit}
              row={selectedRow}
            />
          </Box>
        </Fade>
      </Modal> */}
    </div>
  );
};

export default UATList;
