import React, { useEffect, useState } from "react";
import "./Company.scss";
import profileImage from "../../../Assets/image/blank-profile-picture.png";
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import apiService from "../../../Services/api-service";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { passwordMaxLenth } from '../../../Utility/CommonJson';

const ChangePassword = () => {
  const [account, setAccount] = React.useState({
    currentPassword: "",
    password: "",
    cPassword: "",
  });
  const [profileImg, setProfileImg] = useState(profileImage);
  const [userDetail, setUserDetail] = useState({});
  const [passwordChangeSubmit, setPasswordChangeSubmit] = React.useState(false);
  const [passwordState, setPasswordState] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();
  let responseData = {};
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Change password";
    getUserDetail();
  }, []);

  //   const handelAccount = (property, event) => {
  //     const accountCopy = { ...userApiData };
  //     accountCopy[property] = event;
  //     setUserApiData(accountCopy);
  //   };

  const getUserDetail = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loaderChange(true));
    apiService
      .get(`platform`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        if (response.data?.data?.profile_image_url) {
          setProfileImg(response.data?.data?.profile_image_url);
        }
        // setProfileImg(response.data?.data?.profile_image_url);
        responseData = response.data.data;
        setUserDetail(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const checkPassword = (str) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  };

  const handleSubmit = () => {
    setPasswordChangeSubmit(true);
    if (
      account.password !== account.cPassword ||
      !checkPassword(account.password)
    ) {
      // handelErrorMsg('passwordNotMatch', 'The password confirmation does not match.');
      return;
    }
  };

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event;
    setAccount(accountCopy);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handelPassword = (property, event) => {
    const accountCopy = { ...passwordState };
    accountCopy[property] = event;
    setPasswordState(accountCopy);
  };

  const handleChangePasswordSubmit = () => {
    setPasswordChangeSubmit(true);
    if (
      account.password !== account.cPassword ||
      !checkPassword(account.password)
    ) {
      return;
    }
    dispatch(loaderChange(true));
    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then((user) => {
        return Auth.changePassword(
          user,
          account.currentPassword,
          account.password
        );
      })
      .then((data) => {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
      })
      .catch((err) => {
        dispatch(loaderChange(false));
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  return (
    <div className="change-password-page">
      <div className="row row-custom-change-password">
        <div className="col-3 first-div">
          <div className="text-center">
            <img className="profile-img" src={profileImg} alt={"profileImg"} />
          </div>
          <div className="text-center mt-3 company-title">
            {userDetail?.platformDetail?.company_info?.corporate_name}
          </div>

          <div className="text-center mt-2 country-title">
            {userDetail?.platformDetail?.company_info?.address.country}
          </div>

          <div className="text-center mt-2 role-div">
            <Chip label={userDetail?.roles?.length > 0 ? userDetail?.roles[0] : ''} color="primary" size="small" />
          </div>
          {/* <div className="text-center mt-3">
            <button className="btn btn-primary btn-buyer" type="submit">
              Become a Business buyer
            </button>
          </div> */}
        </div>
        <div className="col-9 second-div">
          {/* <div className="text-center title-div">
            Congratulations you have signed up as an Individual Buyer!
          </div> */}
          <div className="d-flex justify-content-between mt-3">
            <div className="sub-head">User Profile</div>
            {/* <div className="edit-div">Edit</div> */}
          </div>

          <FormControl
            sx={{ width: "100%" }}
            className="mt-3"
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ width: "8.5rem", background: "white" }}
            >
              Current password
            </InputLabel>
            <OutlinedInput
              type={passwordState.currentPassword ? "text" : "password"}
              // value={userApiData.password}
              onChange={(event) =>
                handelAccount("currentPassword", event.target.value)
              }
              inputProps={{ maxLength: passwordMaxLenth }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handelPassword(
                        "currentPassword",
                        !passwordState.currentPassword
                      )
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordState.currentPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <FormControl
            sx={{ width: "100%" }}
            className="mt-3"
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ width: "7rem", background: "white" }}
            >
              New password
            </InputLabel>
            <OutlinedInput
              type={passwordState.newPassword ? "text" : "password"}
              // value={userApiData.password}
              inputProps={{ maxLength: passwordMaxLenth }}
              onChange={(event) =>
                handelAccount("password", event.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handelPassword("newPassword", !passwordState.newPassword)
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordState.newPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText error={true}>
              {!checkPassword(account.password) && passwordChangeSubmit
                ? "Password should contain one digit one special character and one capital letter."
                : ""}
            </FormHelperText>
          </FormControl>

          <FormControl
            sx={{ width: "100%" }}
            className="mt-3"
            variant="outlined"
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ width: "8.5rem", background: "white" }}
            >
              Confirm password
            </InputLabel>
            <OutlinedInput
              type={passwordState.confirmPassword ? "text" : "password"}
              // value={userApiData.password}
              inputProps={{ maxLength: passwordMaxLenth }}
              onChange={(event) =>
                handelAccount("cPassword", event.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handelPassword(
                        "confirmPassword",
                        !passwordState.confirmPassword
                      )
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordState.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText error={true}>
              {account.password !== account.cPassword && passwordChangeSubmit
                ? "The password confirmation does not match."
                : ""}
            </FormHelperText>
          </FormControl>

          <div className="d-flex justify-content-end mt-3 mb-2">
            <Button
              variant="contained"
              className="mr-2"
              color="error"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              onClick={handleChangePasswordSubmit}
              variant="contained"
              className="ml-2"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
