export function addData(payLoad) {
  return { type: "ADD_DATA", payLoad };
}

export function deleteData(payLoad) {
  return { type: "DELETE_DATA", payLoad };
}

export function fetchData(payLoad) {
  return { type: "FETCH_DATA", payLoad };
}

export function updateData(payLoad) {
   return { type: 'UPDATE_DATA', payLoad }
}

export function menuChange(payLoad) {
  return { type: 'MENU_OPEN', payLoad }
}

export function loaderChange(payLoad) {
  return { type: 'LOADER_CHANGE', payLoad }
}

export function loginOpenChange(payLoad) {
  return { type: 'LOGIN_CHANGE', payLoad }
}

export function darkModeChange(payLoad) {
  return { type: 'DARK_MODE_CHANGE', payLoad }
}