import { createStore } from 'redux';
import reducer from '../reducer/index';

export const store = createStore(
    reducer,window.__REDUX_DEVTOOLS_EXTENSION__ && 
    window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  // don't delete
  // window.__REDUX_DEVTOOLS_EXTENSION__ && 
  // window.__REDUX_DEVTOOLS_EXTENSION__()

