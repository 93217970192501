import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AuthenticatorModal from "./AuthenticatorModal";
import { Auth } from "aws-amplify";
import RemoveIcon from "@mui/icons-material/Remove";
import { loaderChange } from "../../../Redux/actions";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // height: "calc(100vh - 80px)",
  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  borderRadius: "5px",
  overflow: "auto",
  width: "50%",
};

const Authenticator = () => {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [mfaType, steMfaType] = useState("");
  const [cognitoUserDetail, setCognitoUserDetail] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getAuthUser();
  }, []);

  const getAuthUser = () => {
    dispatch(loaderChange(true));
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setCognitoUserDetail(user);
        // setUser(user);
        Auth.getPreferredMFA(user, {})
          .then((data) => {
            dispatch(loaderChange(false));
            // setEnable2Fa(data === "SMS_MFA" ? true : false);
            steMfaType(data);
          })
          .catch((error) => {
            dispatch(loaderChange(false));
          });
      })
      .catch((err) => {
        dispatch(loaderChange(false));
      });
  };

  const removeMFA = () => {
    dispatch(loaderChange(true));
    Auth.setPreferredMFA(cognitoUserDetail, "NOMFA")
      .then((data) => {
        dispatch(loaderChange(false));
        // setEnable2Fa(data === "SMS_MFA" ? true : false);
        getAuthUser();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
      });
  };

  const onClosAuthenticatorModal = () => {
    setOpenAuthModal(false);
    getAuthUser();
  };

  const onOpenAuthenticatorModal = () => {
    setOpenAuthModal(true);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom component="div">
        Authenticator app
      </Typography>

      <Typography variant="subtitle1" gutterBottom component="div">
        Instead of waiting for text messages, get free verification codes from
        an authenticator app. it works if your phone is offline
      </Typography>

      <Typography variant="subtitle1" gutterBottom component="div">
        First, download Google Authenticator from the Google Play Store or the
        iOS App Store
      </Typography>

      {/* <Button
        onClick={onOpenAuthenticatorModal}
        className="mt-3"
        variant="outlined"
        startIcon={<HealthAndSafetyIcon />}
      >
        Set up authenticator
      </Button> */}

      {mfaType === "SOFTWARE_TOKEN_MFA" ? (
        <>
          <Button
            variant="outlined"
            startIcon={<RemoveIcon />}
            className="mr-3"
            onClick={removeMFA}
          >
            Remove Google authenticator
          </Button>

          <Button
            variant="outlined"
            onClick={onOpenAuthenticatorModal}
            startIcon={<HealthAndSafetyIcon />}
          >
            Change authenticator
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={onOpenAuthenticatorModal}
            className="mt-3"
            variant="outlined"
            startIcon={<HealthAndSafetyIcon />}
          >
            Set up authenticator
          </Button>
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAuthModal}
        onClose={onClosAuthenticatorModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAuthModal}>
          <Box sx={style} id="style-1">
            <AuthenticatorModal onCloseModal={onClosAuthenticatorModal} />
          </Box>
          {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
        </Fade>
      </Modal>
    </div>
  );
};

export default Authenticator;
