import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AppsIcon from "@mui/icons-material/Apps";
import "./Home.scss";
import apiService from "../../../Services/api-service";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Slide, Stack } from "@mui/material";

const Home = () => {
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = JSON.parse(localStorage.getItem("userDetail"));

  useEffect(() => {
    document.title = "Home";
    getDashboardDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboardDetail = () => {
    dispatch(loaderChange(true));
    apiService
      .get(`home/overview`)
      .then((response) => {
        setDashboardData(response.data.data);
        dispatch(loaderChange(false));
        // responseData = response.data.data;
        // setCompanyDetail(response.data.data);

        // setCompanyData();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const openApiDoc = () => {
    if (process.env.REACT_APP_ENV === "dev") {
      window.open("https://devapi.proxtera.app/v4/", "_blank");
    }
    if (process.env.REACT_APP_ENV === "staging") {
      window.open("https://stagingapi.proxtera.app/v4/", "_blank");
    }
    if (process.env.REACT_APP_ENV === "prod") {
      window.open("https://api2.proxtera.com/", "_blank");
    }
    if (process.env.REACT_APP_ENV === "uat") {
      window.open("https://uatapi.proxtera.app/v4/", "_blank");
    }
  };

  const navigateUrl = (url) => {
    navigate(url);
  };

  return (
    <div>
      {((userDetails?.roles?.indexOf('ORG_ADMIN') !== -1 ||
      userDetails?.roles?.indexOf('ORG_USER') !== -1) && (
        <>
          <div className="d-flex justify-content-between m-3">
            <Card
              sx={{
                minWidth: 275,
                maxWidth: "50%",
                background: "#caddfd",
                border: "1px solid #6689e1",
              }}
              className="mr-2"
            >
              <CardContent>
                Welcome to Proxtera portal. Let's get started and check out
                integrations over here.{" "}
              </CardContent>
              <CardContent className="d-flex justify-content-between">
                <Button
                  sx={{ background: "#ffffff" }}
                  variant="outlined"
                  onClick={() => navigateUrl("/api-integration?id=2")}
                >
                  API key
                </Button>
                <Button variant="contained" onClick={openApiDoc}>
                  API documentation
                </Button>
              </CardContent>
            </Card>
            <Card
              sx={{
                minWidth: 275,
                maxWidth: "50%",
                background: "#caddfd",
                border: "1px solid #6689e1",
              }}
              className="ml-2"
            >
              <CardContent>
                Congratulations! Welcome onboard Proxtera. Please fill in some
                more details about your company.{" "}
              </CardContent>
              <CardContent className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => navigate(`/edit-company`)}
                >
                  Start
                </Button>
              </CardContent>
            </Card>
          </div>
        </>
      ))}

      <div className="mt-5 m-3 sub-head">Overview</div>
      <Stack
        className="m-3"
        direction={{ sm: "column", md: "row" }}
        spacing={{ sm: 1, md: 2, lg: 4 }}
      >
        <Card
          sx={{ minWidth: 275, background: "#f1f2f9", width: "32%" }}
          className="py-3"
        >
          <CardContent className="text-center py-1">
            <AccountBalanceIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">
              {dashboardData?.uploaded_catalogues}
            </span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Uploaded catalogues</span>
          </CardContent>
        </Card>

        <Card
          sx={{ minWidth: 275, background: "#f1f2f9", width: "32%" }}
          className="py-3"
        >
          <CardContent className="text-center py-1">
            <AddBusinessIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">{dashboardData?.onboarded_smes}</span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Onboarded SMEs</span>
          </CardContent>
        </Card>

        <Card
          sx={{ minWidth: 275, background: "#f1f2f9", width: "32%" }}
          className="py-3"
        >
          <CardContent className="text-center py-1">
            <AppsIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">
              {dashboardData?.uploaded_categories}
            </span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Uploaded categories</span>
          </CardContent>
        </Card>
      </Stack>
      {/* <div className="d-flex justify-content-between m-3">
        <Card sx={{ minWidth: 275, background: '#f1f2f9', width: '32%' }} className='py-3'>
          <CardContent className="text-center py-1">
            <AccountBalanceIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">{dashboardData?.uploaded_catalogues}</span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Uploaded catalogues</span>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275, background: '#f1f2f9', width: '32%' }} className='py-3'>
          <CardContent className="text-center py-1">
            <AddBusinessIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">{dashboardData?.onboarded_smes}</span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Onboarded SMEs</span>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275, background: '#f1f2f9', width: '32%' }} className='py-3'>
          <CardContent className="text-center py-1">
            <AppsIcon />
          </CardContent>

          <CardContent className="text-center py-1">
            <span className="card-count">{dashboardData?.uploaded_categories}</span>
          </CardContent>

          <CardContent className="text-center pt-0 pb-2">
            <span className="card-count-text">Uploaded categories</span>
          </CardContent>
        </Card>
      </div> */}
    </div>
  );
};

export default Home;
