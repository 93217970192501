import React from 'react'
import './CatalogList.scss';
import Box from "@mui/material/Box";
import TableCustomComponent from "../../../Utility/TableCustom";

const CatalogList = () => {
    const headCells = [
        {
          id: "name",
          align: "left",
          numeric: false,
          disablePadding: false,
          label: "SUPPLIER ID",
        },
        {
          id: "calories",
          align: "center",
          numeric: true,
          disablePadding: false,
          label: "NAME",
        },
        {
          id: "fat",
          align: "center",
          numeric: true,
          disablePadding: false,
          label: "EMAIL ADDRESS",
        },
        {
          id: "carbs",
          align: "center",
          numeric: true,
          disablePadding: false,
          label: "BUSINESS NAME",
        },
        {
          id: "protein",
          align: "center",
          numeric: true,
          disablePadding: false,
          label: "ONBOARD DATE",
        },
      ];
    
      function createData(name, calories, fat, carbs, protein) {
        return {
          name,
          calories,
          fat,
          carbs,
          protein,
        };
      }
    
      const rows = [
        // createData("Cupcake", 305, 3.7, 67, 4.3),
        // createData("Donut", 452, 25.0, 51, 4.9),
        // createData("Eclair", 262, 16.0, 24, 6.0),
        // createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
        // createData("Gingerbread", 356, 16.0, 49, 3.9),
        // createData("Honeycomb", 408, 3.2, 87, 6.5),
        // createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
        // createData("Jelly Bean", 375, 0.0, 94, 0.0),
        // createData("KitKat", 518, 26.0, 65, 7.0),
        // createData("Lollipop", 392, 0.2, 98, 0.0),
        // createData("Marshmallow", 318, 0, 81, 2.0),
        // createData("Nougat", 360, 19.0, 9, 37.0),
        // createData("Oreo", 437, 18.0, 63, 4.0),
      ];
      return (
        <div className="catalog-list">
          <Box sx={{ width: "100%", height: "100%" }}>
            <TableCustomComponent
            //   headCells={headCells}
            //   rows={rows}
              title={"Search for catalog"}
            />
          </Box>
        </div>
      );
}

export default CatalogList