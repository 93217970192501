import axios from "axios";
import { Auth } from "aws-amplify";

// const baseURL = 'https://qnhmg8f3y7.execute-api.ap-southeast-1.amazonaws.com/dev/';
const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err, data) => {
              if (err) {
                Auth.signOut();
              } else {
                config.headers.Authorization =
                  "Bearer " + data.getIdToken().getJwtToken();
                resolve(config);
              }
            });
          });
        } else {
          config.headers.Authorization =
            "Bearer " + session.getIdToken().getJwtToken();
          resolve(config);
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config);
      });
  });
});

// instance.interceptors.request.use(
//     (requestConfig) => {
//       let token = localStorage.getItem("token");
//       requestConfig.headers["Authorization"] = `Bearer ${token}`;
//       requestConfig.headers["Content-Type"] = `application/json`;
//       if (requestConfig.data?.source) {
//         requestConfig["cancelToken"] = requestConfig.data.source.token;
//         delete requestConfig.data.source;
//       }
//       return requestConfig;
//     },

//     (error) => {
//       console.log(error);

//       return Promise.reject(error);
//     }
//   );

export const getService = async (url) => {
  axios
    .get(baseURL + url)
    .then((response) => {
      return response;
      // dispatch(loaderChange(false));
      // userList = response.data.data;
      // rows = userList;
      // setRows(userList);
    })
    .catch((error) => {
      return error;
      // dispatch(loaderChange(false));
      // console.log("error", error);
    });
};

export default instance;
