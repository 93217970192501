import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Slide, Tab } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import "./ApiIntegration.scss";
import APIKeys from "./ApiKeys/APIKeys";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import WebhookURL from "./WebhookURL/WebhookURL";
import WebhookAuthAuth from "./WebhookAuth/WebhookAuthAuth";

const ApiIntegration = () => {
  const [tabValue, setTabValue] = React.useState("1");
  const { enqueueSnackbar } = useSnackbar();

  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    document.title = "API Integration";
    if (id) {
      setTabValue(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSearchParams({ id: newValue });
  };

  function createData(createdAt, publicKey, status) {
    return { createdAt, publicKey, status };
  }

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const rows = [
    createData("1.1.2022", "hjbJHBHJBHJhjB-HJb", "active"),
    createData("13.1.2022", "hjbJHBHJBHJhjB-HJb", "active"),
    createData("14.1.2022", "hjbJHBHJBHJhjB-HJb", "active"),
    createData("19.1.2022", "hjbJHBHJBHJhjB-HJb", "active"),
    createData("1.2.2022", "hjbJHBHJBHJhjB-HJb", "active"),
  ];

  const copyText = (text) => {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    enqueueSnackbar("Copied !!", {
      // variant: "default",
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Slide,
      autoHideDuration: 1000,
    });
  };

  const getAPIURL = () => {
    if(process.env.REACT_APP_ENV === 'dev'){
      return 'https://devapi.proxtera.app/v4/';
    }
    if(process.env.REACT_APP_ENV === 'staging'){
      return 'https://stagingapi.proxtera.app/v4/';
    }
    if(process.env.REACT_APP_ENV === 'prod'){
      return 'https://devapi.proxtera.app/v4/';
    }
    if(process.env.REACT_APP_ENV === 'uat'){
      return 'https://uatapi.proxtera.app/v4/';
    }
  }

  return (
    <div>
      <div className="m-3 page-title">API Integration</div>

      <div className="mt-3 px-3">
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="API CONFIG" value="1" />
              <Tab label="API KEYS" value="2" />
              {/* <Tab label="PUBLIC KEYS" value="3" />
              <Tab label="WEBHOOK URL" value="4" /> */}
              <Tab label="WEBHOOK AUTH" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div>
              <div className="custom-help-text">
                These URLs can be copied and used for integration purpose.
              </div>
              <div className="mt-2">
                <div className="custom-tab-sub-header mt-1">TOKEN URL</div>
                <div className="url-div mt-1">
                  <div className="url-text" id="style-1">
                    {truncateString(
                      getAPIURL() + 'auth/token',
                      35
                    )}
                  </div>{" "}
                  <div>
                    <Button
                      variant="contained"
                      onClick={() =>
                        copyText(
                          getAPIURL() + 'auth/token',
                        )
                      }
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="custom-tab-sub-header mt-1">API HOST</div>
                <div className="url-div mt-1" >
                  <div className="url-text" id="style-1">
                    {truncateString(
                      getAPIURL(),
                      35
                    )}
                  </div>{" "}
                  <div>
                    <Button
                      variant="contained"
                      onClick={() =>
                        copyText(
                          getAPIURL()
                        )
                      }
                    >
                      Copy
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <APIKeys />
          </TabPanel>
          <TabPanel value="3">
            <div>
              <div className="custom-tab-sub-header mt-1">PUBLIC KEY</div>
              <div className="url-div mt-1">
                <input
                  type="text"
                  className="form-control url-input"
                  placeholder=""
                />
                <div>
                  <Button variant="contained">Save</Button>
                </div>
              </div>
              <div className="mt-3">
                <div>No data found</div>
                {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>CREATED DATE</TableCell>
                        <TableCell align="right">PUBLIC KEY</TableCell>
                        <TableCell align="right">STATUS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.createdAt}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.createdAt}
                          </TableCell>
                          <TableCell align="right">{row.publicKey}</TableCell>
                          <TableCell align="right">{row.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <WebhookURL />
          </TabPanel>
          <TabPanel value='5'>
            <WebhookAuthAuth />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default ApiIntegration;
