import React, { useEffect, useRef, useState } from "react";
import "./Company.scss";
import profileImage from "../../../Assets/image/blank-profile-picture.png";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  LinearProgress,
  Modal,
  Slide,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import apiService from "../../../Services/api-service";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import RemoveIcon from "@mui/icons-material/Remove";
import "./ProfileEdit.scss";
import requestApi from "../../../Services/restService";

const ProfileEdit = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    // height: "calc(56vh - 1px)",
    maxHeight: "80%",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    overflow: "auto",
    width: "40%",
  };
  const [profileImg, setProfileImg] = useState(profileImage);
  const [userDetail, setUserDetail] = useState({});
  const [changeSubmit, setChangeSubmit] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  let [emailCount, setEmailCount] = useState(0);
  const [userApiData, setUserApiData] = useState({
    first_name: "",
    last_name: "",
    role: "",
    phone: "",
    email: "",
    emailOtp: "",
  });
  const navigate = useNavigate();
  let responseData = {};
  const dispatch = useDispatch();
  const [memberId, setMemberId] = useState(null);
  const [user, setUser] = useState(null);
  const [enable2Fa, setEnable2Fa] = useState(false);
  const [mfaType, steMfaType] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  let [mobileCount, setMobileCount] = useState(0);
  const [mobileVerify, setMobileVerify] = useState(false);

  useEffect(() => {
    document.title = "Profile";
    getUserDetail();
    getAuthUser();
  }, []);

  const getAuthUser = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUser(user);
        Auth.getPreferredMFA(user, {})
          .then((data) => {
            setEnable2Fa(data === "SMS_MFA" ? true : false);
            steMfaType(data);
          })
          .catch((error) => {});
      })
      .catch((err) => console.log(err));
  };

  const handelAccount = (property, event) => {
    const accountCopy = { ...userApiData };
    accountCopy[property] = event;
    setUserApiData(accountCopy);
  };

  const handelOnlyAlphabetsAccount = (property, event) => {
    const re = /^[A-Za-z]+$/;
    if (event === "" || re.test(event)){
      const accountCopy = { ...userApiData };
      accountCopy[property] = event;
      setUserApiData(accountCopy);
    }
  };

  const getUserDetail = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(loaderChange(true));
    apiService
      .get(`platform`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        responseData = response.data.data;
        setUserDetail(response.data.data);
        if (response.data?.data?.profile_image_url) {
          setProfileImg(response.data?.data?.profile_image_url);
        }
        setUserApiData({
          first_name: response.data.data?.first_name,
          last_name: response.data.data?.last_name,
          role: response.data.data?.roles,
          phone: response.data.data?.phone,
          email: response.data.data?.email,
        });
        setMemberId(response?.data?.data?._id);

        // setCompanyData();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const handleSubmit = () => {
    setChangeSubmit(true);
    if (!userApiData.first_name || !userApiData.last_name) {
      // handelErrorMsg('passwordNotMatch', 'The password confirmation does not match.');
      return;
    }
    const apiData = {
      first_name: userApiData.first_name,
      last_name: userApiData.last_name,
      // member_id: memberId,
    };
    submitUpdateMember(apiData);
  };

  const submitUpdateMember = (apiData) => {
    const formValue = {
      body: apiData,
    };
    dispatch(loaderChange(true));
    const apiResult = requestApi("profile", "updateProfile", formValue);
    apiResult.then(
      (resolveOutput) => {
        enqueueSnackbar(resolveOutput.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        dispatch(loaderChange(false));
        getUserDetail();
      },
      (rejectOutput) => {
        if (rejectOutput) {
          enqueueSnackbar(rejectOutput?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      }
    );
  };

  // const submitUpdateMember = async (bodyParameters) => {
  //   dispatch(loaderChange(true));
  //   apiService
  //     .post(`profile/${userDetails.platform_id}/update_profile`, bodyParameters)
  //     .then((res) => {
  //       enqueueSnackbar(res.data.message, {
  //         variant: "success",
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         TransitionComponent: Slide,
  //         autoHideDuration: 2000,
  //       });
  //       dispatch(loaderChange(false));
  //       getUserDetail();
  //       // navigate("invite-member");
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar("Something went wrong", {
  //         variant: "error",
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //         TransitionComponent: Slide,
  //         autoHideDuration: 2000,
  //       });
  //       dispatch(loaderChange(false));
  //     });
  // };

  const handle2FaChange = (event) => {
    setEnable2Fa(event.target.checked);
    const FmaType = event.target.checked ? "SMS" : "NOMFA";
    // if(!event.target.checked) {
    dispatch(loaderChange(true));
    Auth.setPreferredMFA(user, FmaType)
      .then((data) => {
        dispatch(loaderChange(false));
        enqueueSnackbar("2FA updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        getAuthUser();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
      });
    // }
  };

  const openFileUpload = () => {
    inputFile.current.click();
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const uploadProfileImage = (file) => {
    const formValue = {
      body: { file: file },
    };
    dispatch(loaderChange(true));
    const apiResult = requestApi("profile", "updateProfileImage", formValue);
    apiResult.then(
      (resolveOutput) => {
        dispatch(loaderChange(false));
        getUserDetail();
      },
      (rejectOutput) => {
        if (rejectOutput) {
          enqueueSnackbar(rejectOutput?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      }
    );
  };

  // const uploadProfileImage = (file) => {
  //   dispatch(loaderChange(true));
  //   const body = { member_id: userDetails._id, file: file };
  //   apiService
  //     .post(`profile/${userDetails.platform_id}/update_profile_image`, body)
  //     .then(async (response) => {
  //       dispatch(loaderChange(false));
  //       getUserDetail();
  //     })
  //     .catch((error) => {
  //       dispatch(loaderChange(false));
  //     });
  // };

  const handleImageChange = (event) => {
    let file = event.target.files[0];
    if (file.size > 5e6) {
      enqueueSnackbar("File size must be less than 5 mb", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Slide,
        autoHideDuration: 2000,
      });
      return;
    }
    fileToDataUri(event.target.files[0]).then((dataUri) => {
      uploadProfileImage(dataUri);
    });
    event.target.value = null;
  };

  const focusedColor = "#182269";
  const useStyles = makeStyles({
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: focusedColor,
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: focusedColor,
        },
      },
      label: {
        color: "red",
      },
    },
  });

  const classes = useStyles();

  const onEmailCloseModal = () => {
    setOpenEmailModal(false);
  };

  const sendEmailOtp = () => {
    const apiPassData = {
      email: userApiData.email,
    };
    dispatch(loaderChange(true));
    apiService
      .post(`platform/send_email`, apiPassData)
      .then((res) => {
        setOpenEmailModal(true);
        setSubmitted(false);
        dispatch(loaderChange(false));
        emailCount = 60;
        emailCounter();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const emailCounter = () => {
    const handleSetInterval = setInterval(() => {
      setEmailCount(emailCount--);
      if (emailCount < 0) {
        clearInterval(handleSetInterval);
      }
    }, 1000);
  };

  const submitEmailOtp = () => {
    const apiPassData = {
      // email: userApiData.email,
      otp: userApiData.emailOtp,
    };
    setSubmitted(true);
    apiService
      .put(`profile/verify_email`, apiPassData)
      .then((res) => {
        setEmailVerify(true);
        setSubmitted(false);
        onEmailCloseModal();
        dispatch(loaderChange(false));
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const onMobileCloseModal = () => {
    setOpenMobileModal(false);
  };

  const sendMobileOtp = () => {
    const apiPassData = {
      phone: `${userApiData.phone}`,
    };
    dispatch(loaderChange(true));
    apiService
      .post(`platform/send_sms`, apiPassData)
      .then((res) => {
        setOpenMobileModal(true);
        dispatch(loaderChange(false));
        mobileCount = 60;
        mobileCounter();
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const mobileCounter = () => {
    const handleSetInterval = setInterval(() => {
      setMobileCount(mobileCount--);
      if (mobileCount < 0) {
        clearInterval(handleSetInterval);
      }
    }, 1000);
  };

  const submitMobileOtp = () => {
    const apiPassData = {
      phone: `+${userApiData.phone}`,
      otp: userApiData.mobileOtp,
    };
    apiService
      .post(`platform/verify_sms`, apiPassData)
      .then((res) => {
        setMobileVerify(true);
        onMobileCloseModal();
        dispatch(loaderChange(false));
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  const ValidateString = (event) => {
    var k;
    document.all ? (k = event.keyCode) : (k = event.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  };

  return (
    <>
      <div className="profile-edit-page">
        <div className="row row-custom-profile">
          <div className="col-3 first-div">
            <div className="text-center">
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                // accept=".csv"
                accept="image/*"
                onChange={handleImageChange}
              />
              <img
                className="profile-img"
                src={profileImg}
                alt={"profileImg"}
              />
            </div>
            <div className="text-center">
              <span
                className="upload-text cursor-pointer"
                onClick={openFileUpload}
              >
                upload
              </span>
            </div>{" "}
            <div className="text-center mt-3 company-title">
              {userDetail?.platformDetail?.company_info?.corporate_name}
            </div>
            <div className="text-center mt-2 country-title">
              {userDetail?.platformDetail?.company_info?.address.country}
            </div>
            <div className="text-center mt-2 role-div">
              <Chip
                label={userDetail?.roles?.length > 0 ? userDetail?.roles[0] : ''}
                color="primary"
                size="small"
              />
            </div>
            {/* <div className="text-center mt-3">
            <button className="btn btn-primary btn-buyer" type="submit">
              Become a Business buyer
            </button>
          </div> */}
          </div>
          <div className="col-9 second-div">
            {/* <div className="text-center title-div">
            Congratulations you have signed up as an Individual Buyer!
          </div> */}
            <div className="d-flex justify-content-between mt-3">
              <div className="sub-head">User Profile</div>
              {/* <div className="edit-div">Edit</div> */}
            </div>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={46} />
            </Box>
            <Box sx={{ minWidth: 100 }}>
              <Typography variant="body2" color="text.secondary">
                10% Updated
              </Typography>
            </Box>
          </Box> */}

            <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              value={userApiData.first_name}
              onChange={(event) =>
                handelOnlyAlphabetsAccount(
                  "first_name",
                  event.target.value.replace(/[^\w\s]/gi, "")
                )
              }
              label="First name"
              variant="outlined"
              className="mr-1 mt-3"
              helperText={
                !userApiData.first_name && changeSubmit
                  ? "First name is required"
                  : ""
              }
              error={!userApiData.first_name && changeSubmit}
            />

            <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              label="Last name"
              variant="outlined"
              value={userApiData.last_name}
              onChange={(event) =>
                handelOnlyAlphabetsAccount(
                  "last_name",
                  event.target.value.replace(/[^\w\s]/gi, "")
                )
              }
              className="mr-1 mt-3"
              helperText={
                !userApiData.last_name && changeSubmit
                  ? "Last name is required"
                  : ""
              }
              error={!userApiData.last_name && changeSubmit}
            />

            <TextField
              sx={{
                width: "100%",
                borderRadius: "5px",
              }}
              value={userApiData.role}
              label="Role"
              variant="outlined"
              className="mr-1 mt-3"
              disabled
            />

            <div className="position-relative">
              <TextField
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                }}
                value={userApiData.phone}
                label="Phone"
                variant="outlined"
                className="mr-1 mt-3"
                disabled
              />

              {userDetail.phone_verified ? (
                <>
                  <Button
                    className="verify-btn-profile"
                    size="small"
                    variant="contained"
                    sx={{ background: "green" }}
                  >
                    Verified
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="verify-btn-profile"
                    size="small"
                    variant="contained"
                    onClick={sendMobileOtp}
                    sx={{ background: "gray" }}
                  >
                    Verify
                  </Button>
                </>
              )}

              {!mobileVerify && (
                <>
                  {mobileCount > 0 ? (
                    <FormHelperText className="ml-3">
                      <span>
                        Please wait for {mobileCount} second to resend SMS
                      </span>
                    </FormHelperText>
                  ) : (
                    ""
                    // <FormHelperText sx={{ color: "white" }}>
                    //   <span
                    //     className="cursor-pointer"
                    //     onClick={sendMobileOtp}
                    //   >
                    //     Enter Mobile OTP
                    //   </span>
                    // </FormHelperText>
                  )}
                </>
              )}
            </div>

            <div className="position-relative">
              <TextField
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                }}
                value={userApiData.email}
                label="Email"
                variant="outlined"
                className="mr-1 mt-3"
                disabled
              />
              {userDetail.email_verified ? (
                <>
                  <Button
                    className="verify-btn-profile"
                    size="small"
                    variant="contained"
                    sx={{ background: "green" }}
                  >
                    Verified
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="verify-btn-profile"
                    size="small"
                    variant="contained"
                    onClick={sendEmailOtp}
                    sx={{ background: "gray" }}
                  >
                    Verify
                  </Button>
                </>
              )}

              {!emailVerify && (
                <>
                  {emailCount > 0 ? (
                    <FormHelperText sx={{ color: "white" }}>
                      <span>
                        Please wait for {emailCount} second to resend Email
                      </span>
                    </FormHelperText>
                  ) : (
                    ""
                    // <FormHelperText sx={{ color: "white" }}>
                    //   <span
                    //     className="cursor-pointer"
                    //     onClick={checkEmailExist}
                    //   >
                    //     Enter email OTP
                    //   </span>
                    // </FormHelperText>
                  )}
                </>
              )}
            </div>

            {/* <Button variant="outlined" onClick={()=>navigate("/authenticator")} startIcon={<HealthAndSafetyIcon />}>
            Set up authenticator
          </Button> */}

            <div className="d-flex justify-content-end mt-3 mb-2">
              <Button
                variant="contained"
                className="mr-2"
                color="error"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
            <hr />
            <div>
              <FormGroup sx={{ display: "inline-block" }}>
                <FormControlLabel
                  control={
                    <Switch checked={enable2Fa} onChange={handle2FaChange} />
                  }
                  label="Enable 2FA"
                />
              </FormGroup>
              <div className="clearfix"></div>
              {mfaType === "SOFTWARE_TOKEN_MFA" ? (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/authenticator")}
                  startIcon={<RemoveIcon />}
                >
                  Remove Google authenticator
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/authenticator")}
                  startIcon={<HealthAndSafetyIcon />}
                >
                  Set up authenticator
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEmailModal}
        onClose={onEmailCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEmailModal}>
          <Box sx={style} id="style-1">
            <div>
              <h3 className="text-center modal-header-custom">
                Enter Email OTP
              </h3>
              <div className="mt-3">
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  label="OTP"
                  value={userApiData.emailOtp}
                  className={`${classes.root} mt-3`}
                  variant="outlined"
                  onChange={(event) =>
                    handelAccount("emailOtp", event.target.value)
                  }
                  helperText={
                    !userApiData.emailOtp && submitted
                      ? "Email otp is required"
                      : ""
                  }
                  error={!userApiData.emailOtp && submitted}
                />

                <div className="d-flex justify-content-end w-100 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="error"
                    // sx={{ width: "100%" }}
                    className="mr-2"
                    onClick={onEmailCloseModal}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    // sx={{ width: "100%" }}
                    className="ml-2"
                    // endIcon={<SendIcon />}
                    onClick={submitEmailOtp}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Box>
          {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openMobileModal}
        onClose={onMobileCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openMobileModal}>
          <Box sx={style} id="style-1">
            <div>
              <h3 className="text-center modal-header-custom">
                Enter Mobile OTP
              </h3>
              <div className="mt-3">
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  label="OTP"
                  value={userApiData.mobileOtp}
                  className={`${classes.root} mt-3`}
                  variant="outlined"
                  onChange={(event) =>
                    handelAccount("mobileOtp", event.target.value)
                  }
                  helperText={
                    !userApiData.emailOtp && submitted
                      ? "Phone is required"
                      : ""
                  }
                  error={!userApiData.emailOtp && submitted}
                />

                <div className="d-flex justify-content-end w-100 mt-3 mb-3">
                  <Button
                    variant="contained"
                    color="error"
                    // sx={{ width: "100%" }}
                    className="mr-2"
                    onClick={onMobileCloseModal}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    // sx={{ width: "100%" }}
                    className="ml-2"
                    // endIcon={<SendIcon />}
                    onClick={submitMobileOtp}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Box>
          {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
        </Fade>
      </Modal>
    </>
  );
};

export default ProfileEdit;
