import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Backdrop,
  Button,
  Fade,
  Link,
  Modal,
  Slide,
  TablePagination,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderChange } from "../../../Redux/actions";
import apiService from "../../../Services/api-service";
import { useSnackbar } from "notistack";
import InvitePlatform from "./InvitePlatform/InvitePlatform";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import ApproveRoleModal from "./ApproveRoleModal";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // height: "calc(100vh - 80px)",
  maxHeight: "80%",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  overflow: "auto",
  width: "50%",
  // maxHeight: "calc(30rem - 80px)",
};

function Row(props) {
  const { row, onOpenApproveModal, rejectUser, disableUser, enableUser } =
    props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.website ? (
            <a rel="noopener noreferrer" href={row.website} target="_blank">
              {row?.company_info?.corporate_name}
            </a>
          ) : (
            row?.company_info?.corporate_name
          )}

          {/* {row?.company_info?.corporate_name} */}
        </TableCell>
        <TableCell align="center">
          {row?.company_info?.address.country}
        </TableCell>
        {/* <TableCell align="center">
          <a rel="noopener noreferrer" href={row.website} target="_blank">
            {row?.website}
          </a>
        </TableCell> */}
        <TableCell align="center">{row?.company_info?.tax_id}</TableCell>
        <TableCell align="center">
          {row?.company_info?.platform_type === "both"
            ? "buyer & seller"
            : row?.company_info?.platform_type}
        </TableCell>
        <TableCell align="center">
          <span className="action-icon">{row?.status}</span>
        </TableCell>
        <TableCell align="center">
          <span className="action-icon">
            {row.status === "Approved" ? (
              <>
                {row.active ? (
                  <>
                    <Tooltip title="Disable">
                      <i
                        className="fa fa-ban cursor-pointer"
                        aria-hidden="true"
                        onClick={() => disableUser(row)}
                      ></i>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Enable">
                      <i
                        className="fa fa-check-circle ml-2 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => enableUser(row)}
                      ></i>
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <>
                <Tooltip title="Reject">
                  <i
                    className="fa fa-times-circle cursor-pointer"
                    aria-hidden="true"
                    onClick={() => rejectUser(row)}
                  ></i>
                </Tooltip>
                <Tooltip title="Approve">
                  <i
                    className="fa fa-check-circle ml-2 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => onOpenApproveModal(row)}
                  ></i>
                </Tooltip>
              </>
              // <Button
              //   variant="contained"
              //   color="success"
              //   onClick={() => approveUSer(row)}
              // >
              //   Approve
              // </Button>
            )}
          </span>
        </TableCell>
        <TableCell align="center">
          {row.status === "Approved" ? (
            <Link
              component="button"
              onClick={() =>
                navigate(`/update-company?platform_id=${row?._id}`)
              }
            >
              View detail
            </Link>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                User detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Phone number</TableCell>
                    <TableCell align="center">Designation</TableCell>
                    <TableCell align="center">Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.users.map((user, index) => (
                    <TableRow key={index + user.first_name}>
                      <TableCell component="th" scope="row">
                        {user.first_name}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell align="center">{user.phone}</TableCell>
                      <TableCell align="center">{user.designation}</TableCell>
                      <TableCell align="center">{user.role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property, sort) => (event) => {
    if (sort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={"normal"}
            size={"medium"}
            sx={{ fontSize: "18px", fontWeight: 500 }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.sort ? orderBy === headCell.id : false}
              hideSortIcon={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.sort)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PlatformCollapsibleTable({ headCells }) {
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [totalData, setTotalData] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [openApproveModal, setOpenCApproveModal] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  let queryParams = {
    rowsPerPage: 10,
    page: 0,
  };
  const [selectedRow, setSelectedRow] = React.useState(null);
  let searchValue = "";
  const dispatch = useDispatch();
  const fileExtension = ".xlsx";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  React.useEffect(() => {
    getUserList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    queryParams.sortOrder = isAsc ? "dsc" : "asc";
    queryParams.sortBy = property;
    getUserList();
  };

  const getUserList = async () => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let apiQueryParam = `page=${queryParams.page + 1}&size=${
      queryParams.rowsPerPage
    }&search=${searchValue}`;
    if (queryParams.sortBy) {
      apiQueryParam = `page=${queryParams.page + 1}&size=${
        queryParams.rowsPerPage
      }&sortBy=${queryParams.sortBy}&sortOrder=${
        queryParams.sortOrder
      }&search=${searchValue}`;
    }
    apiService
      .get(`platform/all?${apiQueryParam}`, config)
      .then((response) => {
        dispatch(loaderChange(false));
        let userList = response.data.data.data;
        setRows(userList);
        setTotalData(response.data?.data?.total);
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
        dispatch(loaderChange(false));
      });
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   queryParams.page = parseInt(newPage)
  //   getUserList();
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   queryParams.rowsPerPage = parseInt(event.target.value, 10)
  //   setPage(0);
  //   getUserList();
  // };

  const approveUSer = (data, checkValue) => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const apiData = checkValue;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .post(`platform/${data._id}/approve`, apiData, config)
      .then((res) => {
        dispatch(loaderChange(false));
        enqueueSnackbar("Platform approved successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        getUserList();
        onCloseApproveModal();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const rejectUser = (data, checkValue) => {
    dispatch(loaderChange(true));
    const token = localStorage.getItem("token");
    const apiData = checkValue;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    apiService
      .post(`platform/${data._id}/reject`, apiData, config)
      .then((res) => {
        dispatch(loaderChange(false));
        enqueueSnackbar("Platform rejected successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        getUserList();
        onCloseApproveModal();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const disableUser = (data) => {
    dispatch(loaderChange(true));
    apiService
      .put(`platform/${data._id}/disable`)
      .then((res) => {
        dispatch(loaderChange(false));
        enqueueSnackbar(res?.data?.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        getUserList();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const enableUser = (data) => {
    dispatch(loaderChange(true));
    apiService
      .put(`platform/${data._id}/enable`)
      .then((res) => {
        console.log("res", res);
        dispatch(loaderChange(false));
        enqueueSnackbar(res?.data?.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          autoHideDuration: 2000,
        });
        getUserList();
      })
      .catch((error) => {
        dispatch(loaderChange(false));
        if (error.response) {
          enqueueSnackbar(error?.response?.data?.data?.errorMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Slide,
            autoHideDuration: 2000,
          });
        }
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    queryParams.rowsPerPage = parseInt(event.target.value);
    // queryParams.page = parseInt(page);
    setPage(0);
    getUserList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(parseInt(rowsPerPage));
    queryParams.page = parseInt(newPage);
    queryParams.rowsPerPage = parseInt(rowsPerPage);
    getUserList();
  };

  const onCloseModal = () => {
    setOpenInviteModal(false);
    getUserList();
  };

  const handelSearchEvent = (event) => {
    searchValue = event;
    getUserList();
  };

  const inviteModal = () => {
    setOpenInviteModal(true);
  };

  const exportToCSV = (csvData, fileName) => {
    const dataCsv = [
      [
        "Company/Corporate name	",
        "Country of registration",
        "Company URL",
        "Company Tax ID",
      ],
    ];
    csvData.filter((val, index) => {
      dataCsv.push([
        val.company_info.corporate_name,
        val.company_info.address.country,
        val.website,
        val.company_info.tax_id,
      ]);
    });
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onCloseApproveModal = () => {
    setOpenCApproveModal(false);
  };

  const onOpenApproveModal = (row) => {
    setSelectedRow(row);
    setOpenCApproveModal(true);
  };

  return (
    <>
      <div className="sme-list">
        <Box sx={{ width: "100%", height: "100%" }}>
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Typography variant="h6" gutterBottom component="div">
                Platform list
              </Typography>
              {rows.length > 0 && (
                <TextField
                  label="Search"
                  id="outlined-size-small"
                  size="small"
                  className="ml-3"
                  onChange={(event) => handelSearchEvent(event.target.value)}
                />
              )}
            </div>

            <div>
              <Tooltip title="Invite Member">
                <Button variant="contained" onClick={inviteModal}>
                  Invite
                </Button>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton onClick={() => exportToCSV(rows, "platform")}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* {rows.length > 0 ? (
          <UserTableCustomComponent
            headCells={headCells}
            rows={rows}
            title={"User List"}
            approve={(data) => approveUSer(data)}
          />
        ) : (
          ""
        )} */}

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              {/* <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Company/Corporate name</TableCell>
                  <TableCell align="center">Country of registration</TableCell>
                  <TableCell align="center">Company Tax ID</TableCell>
                  <TableCell align="center">Platform type</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {rows.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    onOpenApproveModal={onOpenApproveModal}
                    rejectUser={rejectUser}
                    disableUser={disableUser}
                    enableUser={enableUser}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}

          {/* <UserTableCustomComponent
              headCells={headCells}
              rows={rows}
              title={"User List"}
            /> */}

          {/* <Modal
          open={openInviteModal}
          onClose={onCloseModal}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openInviteModal}>
            <Box sx={style} id="style-1">
              <InvitePlatform onCloseModal={onCloseModal} />
            </Box>
          </Fade>
        </Modal> */}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openApproveModal}
            onClose={onCloseApproveModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openApproveModal}>
              <Box sx={style} id="style-1">
                <ApproveRoleModal
                  onCloseModal={onCloseApproveModal}
                  selectedRow={selectedRow}
                  approveUSer={approveUSer}
                />
              </Box>
              {/* <CatalogAddModal onCloseModal={onCloseModal} /> */}
            </Fade>
          </Modal>
        </Box>
      </div>

      <Modal
        open={openInviteModal}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInviteModal}>
          <Box sx={style} id="style-1">
            <InvitePlatform onCloseModal={onCloseModal} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
